import styled, { css } from 'styled-components'

export const StyledInputQuestionContainer = {
    GrammarTestTextContainer: styled.div`
      margin-bottom: 10%;
    `,
    GrammarTestText: styled.p`
      text-align: center;
      font: normal normal normal 14px/16px NunitoRegular;
      letter-spacing: 0px;
      color: #8CA6C5;
      opacity: 1;
      text-transform: uppercase;
    `,
    QuestionContainer: styled.div`
        margin-bottom: 15px;
    `,
    QuestionText: styled.p`
      text-align: center;
      font: normal normal 800 24px/30px NunitoExtraBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,

    AnswerContainer: styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
    `,
    AnswerInput: styled.input <{ focus: boolean }>`
      ${ props => props.focus && css`
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 2px solid #49A8FB;
        border-radius: 10px;
      ` }

      ${ props => !props.focus && css`
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 2px solid #E5F1FE;
        border-radius: 10px;

      ` }
      
      height: 60px;
      width: 90%;
      opacity: 1;
    `,
    ButtonContainer: styled.div`
        margin-top: 30px;
    `
}
