import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { DYNAMIC_PAGES_MAP, getCohortFromPath } from "src/pages/Pages";

export const getPageIdFromPathName = (pathName: string): string => {
    const pageId = pathName.split("/").pop()
    if (pageId === undefined) {
        return ''
    }
    return pageId
}


export const useGetPageInfo = () => {
    const { pathname } = useLocation()

    const currentPageId = useMemo(() => getPageIdFromPathName(pathname), [pathname])

    const currentCohort = useMemo(() => getCohortFromPath(pathname), [pathname]);

    const currentVariantPageList = useMemo(() => DYNAMIC_PAGES_MAP[currentCohort], [currentCohort])

    const progressValue = useMemo(() => currentVariantPageList.findIndex(({ id }) => id === currentPageId) + 1, [currentPageId, currentVariantPageList])

    const pagesNumberWithNavigation = useMemo(() => currentVariantPageList.length, [currentVariantPageList])

    return { currentVariantPageList, currentCohort, currentPageId, progressValue, pagesNumberWithNavigation }
}
