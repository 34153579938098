import { Elements } from "@stripe/react-stripe-js";
import React, { useEffect, useMemo, useState } from "react";
import stripeService, { stripePromise } from "../../services/stripe.service";
import { configId } from "../../config";
import CheckoutEmailForm from "./CheckoutEmailForm";
import { useNextStep } from "../../hooks/useNextStep";
import { TPageProps } from "../../pages/questions/TPageProps";
import { TOptionPropsBase } from "../Option/Option";
import { PageContainer } from "../PageContainer/PageContainer";
import { captureException } from "../../services/sentry.service";
import ProgressSteps from "../Toolbar/ProgressSteps";
import { HeaderContainer } from "../Toolbar/HeaderContainer";
import { getUserId } from "../../services/user.service";
import { useGetPageInfo } from "../../hooks/useGetPageInfo";


export default function CheckoutEmailProvider(params: TPageProps) {
    const [clientSecret, setClientSecret] = useState("")
    const handleContinue = useNextStep(params)
    const { currentCohort } = useGetPageInfo()

    const props = useMemo<TOptionPropsBase>(
        () => ({
            onUpdated: (value: string) => {
                handleContinue(value)
            }
        }), [handleContinue, params.pageId]
    )

    useEffect(() => {

        getUserId().then(userId => stripeService.payment({
            configId: configId,
            customerId: window.sessionStorage.getItem("customerId"),
            priceId: window.sessionStorage.getItem("selectedPriceId"),
            subscriptionId: window.sessionStorage.getItem("subscriptionId"),
            userId: userId,
            cohortId: currentCohort,
            email: window.sessionStorage.getItem("email"),
            initialUrl: window.sessionStorage.getItem("initialUrl")
        })).then(({ user, subscription, customer }) => {
            window.sessionStorage.setItem("subscriptionId", subscription.id);
            window.sessionStorage.setItem("customerId", customer.id);
            setClientSecret(subscription.latest_invoice.payment_intent.client_secret)
        }).catch(err => {
            captureException(err);
        });
    }, []);

    const options = {
        clientSecret: clientSecret
    }

    return (
        <>
            <HeaderContainer progressHidden={ true }/>
            <ProgressSteps activeStep={ 3 }/>
            <PageContainer>
                { clientSecret && (
                    <Elements stripe={ stripePromise } options={ options } key={ clientSecret }>
                        <CheckoutEmailForm id={ "checkoutform" } { ...props } />
                    </Elements>
                ) }
            </PageContainer>
        </>
    );
}
