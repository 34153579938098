import React from 'react';
import {Styles as S} from "./SubscriptionTerms.styles";
import {useTranslation} from "react-i18next";
import 'react-toastify/dist/ReactToastify.css';

export const SubscriptionTerms = (props: any) => {
    const {i18n, t} = useTranslation();

    return (
        <S.SubscriptionTermsForm>
            <h1>SUBSCRIPTION TERMS</h1>
            <p><br/><br/>Subscription</p>
            <p>When you subscribe to our app, you agree to the following terms: You will be billed on a recurring basis, at the rate agreed upon when you sign up for the subscription. This subscription will remain in effect until you or we deactivate it. While you can change your subscription plan at any time, please note that changes will take effect at the beginning of your next billing cycle. If you cancel your subscription, you won&apos;t be charged any further fees post-cancellation, but you won&apos;t be refunded for the unused portion of your current subscription period.</p>
            <p><br/></p>
            <p>Payment Method</p>
            <p>You can pay for your subscription using various methods including credit card, PayPal, or Google Pay. Payment will be charged through our third-party payment processor. You are responsible for maintaining the accuracy of the payment method information in your account.</p>
            <p><br/></p>
            <p>Cancellation</p>
            <p>To cancel your subscription, please follow these steps:</p>
            <p>- Log in to your account.</p>
            <p>- Go to Settings&nbsp;</p>
            <p>- After redirection to the cancellation web page, confirm cancellation by pressing the &quot;Cancel&quot; Button.</p>
            <p><br/></p>
            <p>Changes</p>
            <p>We reserve the right to modify our subscription plans or adjust pricing for our service or any components thereof in any manner and at any time as we may determine in our sole and absolute discretion. We may change subscription fees at any time.</p>
            <p><br/></p>
            <p>Refunds</p>
            <p>We typically do not offer refunds for unused portions of your subscription period. However, any exceptional circumstances for refunds will be considered on a case-by-case basis.</p>
            <p>We would like to inform you that no refund will be applied for the following reasons.</p>
            <p>- Reasons such as you did not like the product and did not meet your expectations</p>
            <p>- Accidental payments</p>
            <p>- For products that start with the trial period, in cases where you think you do not expect to be paid after the trial period</p>
            <p><br/></p>
            <p>Contact &amp; Support</p>
            <p>For any queries regarding your subscription or if you encounter any issues, please reach out to us at&nbsp;support@lingu.town&nbsp;Our dedicated team is always available to assist you and aims to resolve any issues as quickly as possible.</p>
            <p><br/></p>
            <p><br/></p>
            <p><br/></p>

        </S.SubscriptionTermsForm>
    );
}

export default SubscriptionTerms;
