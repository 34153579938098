import styled from "styled-components";

export const Styles = {

    TermsOfServiceForm: styled.div`
       margin: 20px;
 word-wrap: break-word;
    `,


}
