import styled, { css } from "styled-components";
import tutoringImage from "../../assets/tutoring/img_tutoring_program@2x.png";
import welcomeMascot from "../../assets/tutoring/img_welcome_mascot.svg";

export const StyledTutoring = {
    HeaderContainer: styled.div`
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 10%;
    `,
    BoldTextContainer: styled.div`
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      width: 87%;
    `,
    BoldText: styled.p`
      text-align: center;
      font: normal normal 800 24px/30px NunitoExtraBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,
    DescriptionTextContainer: styled.div`
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      margin-top: 5%;
      margin-bottom: 10%;
      width: 70%;
    `,
    DescriptionText: styled.p`
      text-align: center;
      font: normal normal normal 16px/20px NunitoRegular;
      letter-spacing: 0px;
      color: #8CA6C5;
      opacity: 1;
    `,
    BodyContainer: styled.div`
      background: #F3FAFF 0% 0% no-repeat padding-box;
      background-size: contain;
      opacity: 1;
      width: 100%;
      height: 550px;
    `,
    ImageContainer: styled.div<{ cohort: string }>`
      width: 100%;
      height: 380px;
      margin-top: 10px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      
      opacity: 1;
      ${ props => props.cohort === 'v3' && css`
        background-size: 65% 65%;
        background-image: url(${ welcomeMascot });
      ` }

      ${ props => props.cohort !== 'v3' && css`
        background-size: contain;
        background-image: url(${ tutoringImage });
      ` }
    `,
    ButtonContainer: styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    `
}
