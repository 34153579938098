import styled from "styled-components";
import paymentFailedIcon from 'src/assets/languageLevel/ic_payment_unsuccessful.svg'


export const StyledOption = {
    PaymentFailedTextContainer: styled.div`
      text-align: center;
      margin-bottom: 25px;
    `,
    TryAgainText: styled.p`
      text-align: center;
      font: normal normal 800 20px/24px NunitoExtraBold;
      letter-spacing: 0;
      color: #3A3A3A;
      opacity: 1;
    `,
    PaymentFailedModal: styled.div`
      max-width: 393px;
      width: 90%;
      height: 300px;
      background: white;
      z-index: 10;
      border-radius: 16px;
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `,
    PaymentFailedModalHeader: styled.div`
      background-color: rgba(0, 0, 0, 0.2);
      width: 100vw;
      height: 100vh;
      z-index: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
    `,
    FailedIconContainer: styled.div`
      background: transparent url(${ paymentFailedIcon }) 50% 50% no-repeat padding-box;
      opacity: 1;
      margin-top: 30px;
      margin-bottom: 32px;
      width: 100%;
      height: 82px;
    `
}
