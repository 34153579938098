import React from 'react';
import { PaymentSuccessStyles as P } from "./Styles";
import { Button } from "./Button";


export const PaymentSuccess = (props: any) => {
    const url: string = "https://apps.apple.com/app/id1669752209";

    return (
        <P.Container>
            <P.IconContainer>

            </P.IconContainer>
            <P.DescContainer>
                Payment made successfully. You are directed to the application.
            </P.DescContainer>

            <P.ButtonContainer>
                <Button text={ "GO TO APP" } onclick={ () => window.location.href = url }/>
            </P.ButtonContainer>
        </P.Container>
    );
}

