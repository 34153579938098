import styled, { keyframes } from "styled-components";
import passiveButtonBackground from "../../assets/languageLevel/btn_cta_passive.svg";
import buttonBackground from "../../assets/languageLevel/btn_done.svg";

export const RippleAnimation = {
    RippleAnimation: keyframes`
      0% {
        transform: scale(1);
        opacity: 1;
      }
      50% {
        transform: scale(35);
        opacity: 0.375;
      }
      100% {
        transform: scale(90);
        opacity: 0;
      }
    `,
}

export const Styles = {
    Button: styled.button<{ disabled: boolean }>`
      background: transparent url(${ props => props.disabled ? passiveButtonBackground : buttonBackground }) 50% 0% no-repeat padding-box;
      opacity: 1;
      border: none;
      text-align: center;
      height: 60px;
      width: 313px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
    `,
    ButtonText: styled.p`
      font: normal normal bold 18px/24px NunitoBold;
      letter-spacing: 0px;
      color: #FFFFFF;
      text-align: center;
      text-transform: uppercase;
      opacity: 1;
      vertical-align: middle;
    `,
    RippleSpan: styled.span`
      width: 20px;
      height: 20px;
      position: absolute;
      background: #FFFFFF;
      display: block;
      content: "";
      border-radius: 9999px;
      opacity: 1;
      animation: 1.2s ease 0.6 forwards ${ RippleAnimation.RippleAnimation };
    `,
    ContentSpan: styled.span`
      position: relative;
      z-index: 2;
    `,
    LoadingContainer: styled.div`
      text-align: center;
      display: flex;
      justify-content: center;
    `,
    Loading: styled.img`
    `
}
