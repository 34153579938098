import styled, { css } from 'styled-components'
import bgGraph from 'src/assets/plans/bg_graph.png'
import bgGraphv3 from 'src/assets/plans/v3_bg_graph.png'
import ic_guarantee from 'src/assets/plans/ic_guarantee.png'
import ic_close_subscription from 'src/assets/plans/ic_close_subscription.svg'
import planUnselected from 'src/assets/plans/plan_unselected.svg'
import planSelected from 'src/assets/plans/plan_selected.svg'
import boxComment from 'src/assets/plans/box_comment.svg'
import star from 'src/assets/plans/ic_star_comment.svg'
import radioPlanUnselected from 'src/assets/plans/radio_plan_unselected.svg'
import radioPlanSelected from 'src/assets/plans/radio_plan_selected.svg'
import bestOffer from 'src/assets/plans/box_best_offer_active.svg'
import dotHow from 'src/assets/plans/dot_how.svg'

export const Styled = {
    CancelButton: styled.div`
      width: 36px;
      height: 36px;
      cursor: pointer;
      background: transparent url(${ ic_close_subscription }) 0% 0% no-repeat padding-box;
    `,
    PlansContainer: styled.div<{ cohort: string }>`
      width: 343px;
      margin: auto;
      ${ props => props.cohort !== 'v3' && css`
        height: 1125px;
      ` }

      ${ props => props.cohort === 'v3' && css`
        height: 1450px;
      ` }
    `,
    BGContainer: styled.div`
      opacity: 1;
      background: #F3FAFF;
    `,
    TitleContainer: styled.header`
      margin-bottom: 16px;
      text-align: center;
      font: normal normal bold 24px/32px NunitoBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,
    GraphicContainer: styled.div<{ cohort: string }>`
      ${ props => props.cohort !== 'v3' && css`
        height: 195px;
        width: 343px;
        background-size: contain;
        background-image: url(${ bgGraph });
        background-position: 50% 50%;
        background-repeat: no-repeat;
      ` }

      ${ props => props.cohort === 'v3' && css`
        height: 242px;
        width: 343px;
        background-size: 100% 100%;
        background-image: url(${ bgGraphv3 });
      ` }
    `,
    CommentsContainer: styled.div`
      margin-top: 20px;
    `,
    CommentContainer: styled.div`
      height: 190px;
      width: 343px;
      background: transparent url(${ boxComment }) 0% 0% no-repeat padding-box;
      border: 1px solid #DEEBF5;
      border-radius: 15px;
      opacity: 1;
    `,
    CommentHeader: styled.div`
      display: flex;
      margin: 18px auto auto auto;
      width: 70%;
    `,
    CommentTitle: styled.div`
      text-align: center;
      font: normal normal bold 16px/22px Nunito;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
      width: 70%;
    `,
    CommentTitleText: styled.p`
      text-align: left;
      font: normal normal bold 16px/22px NunitoBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,
    CommentDetailsText: styled.p`
      text-align: left;
      font: normal normal normal 11px/20px NunitoRegular;
      letter-spacing: 0px;
      color: #BCCCE0;
      opacity: 1;
    `,
    CommentDetails: styled.p`
      text-align: center;
      margin: 15px 26px 11px 25px;
      font: normal normal normal 14px/16px NunitoRegular;
      letter-spacing: 0px;
      color: #BCCCE0;
      opacity: 1;
    `,
    CommentImg: styled.div<{ img: string }>`
      width: 40px;
      height: 40px;
      background: transparent url(${ props => props.img }) 0% 0% no-repeat padding-box;
      opacity: 1;
    `,
    DescriptionContainer: styled.div`
      margin-bottom: 16px;
    `,
    StarContainer: styled.div`
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
    `,
    Star: styled.div`
      width: 13px;
      height: 13px;
      margin-left: 7px;
      background: transparent url(${ star }) 0% 0% no-repeat padding-box;
      opacity: 1;
    `,

    OffersContainer: styled.div`
    `,

    LessonDurationContainer: styled.div`

    `,

    LessonDurationTitle: styled.p`
      text-align: center;
      font: normal normal bold 24px/32px NunitoBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,

    LessonDurationDesc: styled.p`
      text-align: center;
      width: 80%;
      margin: 15px auto auto auto;
      font: normal normal normal 14px/19px NunitoRegular;
      letter-spacing: 0px;
      color: #8CA6C5;
      opacity: 1;
    `,

    LessonButtonGroup: styled.div`
      height: 40px;
      margin-top: 20px;
      display: flex;
    `,
    LessonContainer: styled.div`
      background: #F3FAFF;
      padding-bottom: 5px;
    `,

    LessonButton: styled.button<{ borderRadius: string, active: boolean }>`
      border: 1px solid #E5F1FE;
      opacity: 1;
      border-radius: ${ props => props.borderRadius };
      background: #FFFFFF 0% 0% no-repeat padding-box;
      text-align: center;
      font: normal normal bold 16px/22px NunitoBold;
      letter-spacing: 0px;
      color: #49A8FB;
      width: 33%;

      ${ props => props.active && css`
        color: #FFFFFF;
        background: #49A8FB 0% 0% no-repeat padding-box;
      ` }
    `,

    OfferContainer: styled.div<{ selected: boolean }>`
      background: transparent url(${ props => props.selected ? planSelected : planUnselected }) 50% 50% no-repeat padding-box;
      opacity: 1;
      height: 75px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    `,
    OfferTitle: styled.div`
      font: normal normal bold 18px/24px NunitoBold;
      text-align: center;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
      vertical-align: middle;
      width: 55%;
    `,
    BestOfferContainer: styled.div`
      background: transparent linear-gradient(180deg, #17CCFB 0%, #529FFE 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 3px #4688F814;
      border-radius: 5px;
      opacity: 1;
      display: flex;
      position: absolute;
      left: 30px;
      height: 18px;
      width: 100px;
    `,
    BestOfferText: styled.p`
      text-align: center;
      justify-content: center;
      margin: auto;
      font: normal normal bold 12px/16px Nunito;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
    `,
    OfferPrice: styled.div`
      text-align: right;
      font: normal normal bold 14px/19px NunitoBold;
      letter-spacing: 0px;
      color: #8CA6C5;
      opacity: 1;
      width: 35%;
    `,
    PerWeekOffer: styled.div`
      flex-direction: column;
      width: 0%;
      padding-top: 7px;
    `,

    PerWeekPrice: styled.p`
      text-align: center;
      font: normal normal bold 18px/24px NunitoBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,

    PerWeekText: styled.p`
      text-align: center;
      font: normal normal bold 12px/16px NunitoBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,

    CheckBox: styled.div<{ selected: boolean }>`
      background: transparent url(${ props => props.selected ? radioPlanSelected : radioPlanUnselected }) 50% 50% no-repeat padding-box;
      opacity: 1;
      margin: 10px;
      width: 10%;
      height: 40%;
    `,

    BestOffer: styled.div`
      background: transparent url(${ bestOffer }) 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 3px #4688F814;
      border-radius: 5px 5px 5px 5px;
      opacity: 1;
      text-align: center;
      vertical-align: middle;
      font: normal normal bold 12px/16px NunitoBold;
      letter-spacing: 0px;
      color: #FFFFFF;
    `,
    TrialContainer: styled.div`
    `
    ,
    TrialText: styled.p`
    `
    ,
    TrialBadgeContainer: styled.div`
      background: transparent linear-gradient(90deg, #8EE048 0%, #63CC5D 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 3px #4688F814;
      border-radius: 5px;
      opacity: 1;
      display: flex;
      position: absolute;
      left: 212px;
      height: 17px;
      width: 120px;
    `,
    TrialBadgeText: styled.p`
      text-align: center;
      font: normal normal bold 12px/16px Nunito;
      letter-spacing: 0px;
      color: #FFFFFF;
      text-transform: uppercase;
      opacity: 1;
      justify-content: center;
      margin: auto;
    `,
    TrialHeading: styled.p`
      text-align: center;
      margin-top: 20px;
      font: normal normal bold 24px/32px NunitoBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,
    TrialDot: styled.div`
      background: transparent url(${ dotHow }) 50% 50% no-repeat padding-box;
      opacity: 1;
      width: 20px;
      height: 20px;
    `,

    TrialBulletContainer: styled.div`
      display: flex;
      flex-direction: column;
      width: 90%;
      margin: auto;
      margin-bottom: 25px;
    `,
    TrialBulletPoint: styled.div`
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
      text-align: left;
      margin-top: 10px;
      font: normal normal normal 14px/19px NunitoRegular;
      letter-spacing: 0px;
      color: #8CA6C5;
      opacity: 1;
    `,

    TrialBulletContent: styled.div`
      width: 90%;
    `,

    LinksContainer: styled.div`
      color: #caddff;
      cursor: pointer;
    `,
    AgreementText: styled.div`
      width: 90%;
      height: 75px;
      margin-top: 15px;
      margin-left: 5%;
      text-align: center;
      font: normal normal normal 10px/14px NunitoRegular;
      letter-spacing: 0px;
      color: #8CA6C5;
      opacity: 1;
    `,
    MoneyBackContainer: styled.div`
      background: transparent url(${ boxComment }) 0% 0% no-repeat padding-box;
      border: 1px solid #DEEBF5;
      border-radius: 15px;
      opacity: 1;
    `,
    MoneyBackHeaderContainer: styled.div`
      margin-top: 15px;
    `,
    MoneyBackDescContainer: styled.div`
      margin: 13px 25px 25px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    `,
    MoneyBackHeaderText: styled.p`
      text-align: center;
      font: normal normal bold 24px/32px NunitoBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,
    MoneyBackDescTextContainer: styled.div`
      width: 80%;
      margin-right: 2px;
    `,
    MoneyBackDescText: styled.p`
      text-align: left;
      font: normal normal bold 14px/17px NunitoRegular;
      letter-spacing: 0px;
      color: #8CA6C5;
      opacity: 1;
    `,
    MoneyBackDescTextBlue: styled.span`
      text-align: left;
      font: normal normal bold 14px/17px NunitoBold;
      letter-spacing: 0px;
      color: #47A8FE;
      opacity: 1;
    `,
    MoneyBackImageContainer: styled.div`
      background-image: url(${ ic_guarantee });
      background-size: 100% 100%;
      width: 64px;
      height: 82px;
    `

}
