import styled from 'styled-components'
import bgGraph from 'src/assets/languageLevel/img_good_job.svg'

export const StyledGoodJob = {
    GoodJobContainer: styled.div`
        
    `,
    TitleContainer: styled.div`
      margin: 30px 15px 50px;
    `,
    TitleText: styled.p`
      text-align: center;
      font: normal normal 800 24px/30px NunitoExtraBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,
    DescContainer: styled.div`
    `,
    DescText: styled.p`
      text-align: center;
      font: normal normal normal 16px/20px NunitoRegular;
      letter-spacing: 0px;
      color: #8CA6C5;
      opacity: 1;
      margin-top: 8%;
      margin-bottom: 8%;
    `,
    BodyContainer: styled.div`
      background: #F3FAFF 0% 0% no-repeat padding-box;
      opacity: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 500px;
    `,
    ImageContainer: styled.div`
      background: transparent url(${bgGraph}) 0% 0% no-repeat padding-box;
      opacity: 1;
      width: 202px;
      height: 213px;
    `,
    ButtonContainer: styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
    `
}
