import React, { FormEvent, useState } from 'react';
import { CheckoutStyles as C } from "./Styles";
import Title from "./Title";
import { Styles as S } from "../../components/Checkout/CheckoutPage.styles";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { ProgressButton } from "./Button";
import icons from "./images"
import { captureObject } from "../../services/sentry.service";
import getSymbolFromCurrency from "currency-symbol-map";
import { firebaseService } from "../../services/firebase.service";
import { fbq } from "../../services/metapixel.service";
import { useTranslation } from "react-i18next";


export const Checkout = (props: any) => {
    const { i18n, t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false);
    const [paymentFormCompleted, setPaymentFormCompleted] = useState(false)

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        firebaseService.logFirebaseEvent("checkout_pay_button_clicked")
        fbq('trackCustom', 'checkout_pay_button_clicked')
        fbq('track', 'InitiateCheckout')


        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${ window.location.origin }/v4/paymentC4`,
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        captureObject(error);
        setLoading(false);
        props.onPaymentFailed && props.onPaymentFailed()
    };

    function capitalizeFirstLetter(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    let weekMap = new Map<string, number>([
        ["day", 0.7],
        ["week", 1],
        ["month", 4.3], // 4.3 = 52/12
        ["year", 52],
    ]);

    function getWeekCount(recurring: string): number {
        const weekCount = weekMap.get(recurring)
        if (weekCount) {
            return weekCount
        }
        return 1;
    }

    return (
        <C.Container>
            <C.CloseButtonWrapper>
                <C.CloseButton onClick={ () => {
                    props.onClose && props.onClose()
                } }>
                    &times;
                </C.CloseButton>
            </C.CloseButtonWrapper>
            <Title title={ "Billing Details" }></Title>
            <C.BillingContainer>
                <C.BillingTop>
                    <C.LDetailsContainer>
                        <C.Text1>
                            { capitalizeFirstLetter(props.plan.recurring.interval) }ly
                        </C.Text1>
                    </C.LDetailsContainer>
                    <C.RDetailsContainer>
                        <C.Text1>
                            { getSymbolFromCurrency(props.plan.currency) }{ Math.round(props.plan.unit_amount / 100 * 100) / 100 }
                        </C.Text1>
                        <br/>
                        <C.Text1>
                            { getSymbolFromCurrency(props.plan.currency) }{ Math.round(props.plan.unit_amount / getWeekCount(props.plan.recurring.interval) / 100 * 100) / 100 } per
                            week
                        </C.Text1>
                    </C.RDetailsContainer>
                </C.BillingTop>
            </C.BillingContainer>
            <C.PaymentContainer>
                <C.PaymentTitle>
                    <C.Text1>
                        Pay now with
                    </C.Text1>
                </C.PaymentTitle>
                <C.ElementsContainer>
                    <S.PaymentForm onSubmit={ handleSubmit }>
                        <PaymentElement id="payment-element"
                                        options={ { paymentMethodOrder: ['card', 'google_pay'], layout: "tabs" } }
                                        onChange={ (ev) => (ev.complete) ? setPaymentFormCompleted(true) : setPaymentFormCompleted(false) }/>
                    </S.PaymentForm>
                </C.ElementsContainer>
                <ProgressButton loading={ loading } disabled={ !paymentFormCompleted } text={ "CONTINUE" }
                                onclick={ handleSubmit }/>
            </C.PaymentContainer>

            <C.SecurePaymentContainer>
                <C.VerifyContainer img={ icons.verify }>
                    <C.Text3>
                        &nbsp;&nbsp; Your payment is secured
                    </C.Text3>
                </C.VerifyContainer>
                <br/>
                <C.VisaContainer img={ icons.visa }/>
                <br/>
                <div>
                    <C.TermsText>
                        The payment processed by GM Appdev Limited,
                        with registered address at
                        Nafpliou, 15, Limassol, Cyprus, 3025
                    </C.TermsText>
                </div>
            </C.SecurePaymentContainer>
        </C.Container>
    );
}

