import { StyledSelect as S } from './Select.styles'


export const Select = (props: any) => {

    return (
        <S.SelectContainer>
            <S.Select name="languages" id="languages" value={ props.selected } onChange={props.onChange} >
                {
                    props.options.map((option: any) => (
                        <option key={ option.code } value={ option.code }>&#160;{ option.language }</option>
                    ))
                }
            </S.Select>
        </S.SelectContainer>
    );
}

