import React, { useMemo } from 'react';
import { TPageProps } from "../TPageProps";
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import { QuestionTitle } from "src/components/QuestionTitle/QuestionTitle";
import { Option, TOptionPropsBase } from "src/components/Option/Option";

import textbooksAndWrittenMaterials_icon from 'src/assets/PreferredLearningMethod/ic_textbooks.svg'
import conversationsAndSpeakingPractice_icon from 'src/assets/PreferredLearningMethod/ic_conversations.svg'
import listeningExercisesAndAudioMaterials_icon from 'src/assets/PreferredLearningMethod/ic_listening.svg'
import watchingVideosAndMediaContent_icon from 'src/assets/PreferredLearningMethod/ic_watching.svg'
import onlinePlatformsAndApps_icon from 'src/assets/PreferredLearningMethod/ic_online_platforms.svg'
import { usePostDelay } from "../../../hooks/usePostDelay";
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import { QuestionContainer } from "../../../components/QuestionContainer/QuestionContainer";
import { Toolbar } from "../../../components/Toolbar/Toolbar";

export const PreferredLearningMethod: React.FC<TPageProps> = (params: TPageProps) => {
    const handleContinue = useNextStep(params)
    const { i18n, t } = useTranslation();
    const { isOptionsDisabled, handleChange, setIsOptionsDisabled } = usePostDelay()

    const props = useMemo<TOptionPropsBase>(
        () => ({
            disabled: isOptionsDisabled,
            onUpdated: (value: string) => {
                setIsOptionsDisabled(true)
                handleChange(value, handleContinue)
            }
        }), [isOptionsDisabled, setIsOptionsDisabled, handleChange, handleContinue, params.pageId]
    )

    return (
        <>
            <Toolbar progressValue={6}  pagesNumberWithNavigation={6}></Toolbar>
            <PageContainer>
                <QuestionContainer>
                    <QuestionTitle>{ t('preferredLearningMethod.title') }</QuestionTitle>
                    <Option { ...props } icon={ textbooksAndWrittenMaterials_icon }
                            title={ t('preferredLearningMethod.textbooksAndWrittenMaterials.title') }
                            id={ 'textbooksAndWrittenMaterials' }/>
                    <Option { ...props } icon={ conversationsAndSpeakingPractice_icon }
                            title={ t('preferredLearningMethod.conversationsAndSpeakingPractice.title') }
                            id={ 'conversationsAndSpeakingPractice' }/>
                    <Option { ...props } icon={ listeningExercisesAndAudioMaterials_icon }
                            title={ t('preferredLearningMethod.listeningExercisesAndAudioMaterials.title') }
                            id={ 'listeningExercisesAndAudioMaterials' }/>
                    <Option { ...props } icon={ watchingVideosAndMediaContent_icon }
                            title={ t('preferredLearningMethod.watchingVideosAndMediaContent.title') }
                            id={ 'watchingVideosAndMediaContent' }/>
                    <Option { ...props } icon={ onlinePlatformsAndApps_icon }
                            title={ t('preferredLearningMethod.onlinePlatformsAndApps.title') }
                            id={ 'onlinePlatformsAndApps' }/>

                </QuestionContainer>
            </PageContainer>
        </>
    );
}

