import styled from "styled-components";

export const Styles = {

    CancelContainer: styled.div`
      opacity: 1;
      margin-bottom: 10px;
    `,
    CancelTextContainer: styled.div`,
    `,
    CancelText: styled.p`
      text-align: left;
      font: normal normal bold 16px/22px NunitoBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,
    CancelInput: styled.input`
      border-radius: 10px;
      border: none;
      opacity: 1;
      text-align: center;
      height: 60px;
      width: 313px;
      text-indent: 10px;
      font: normal normal normal 16px/16px NunitoRegular;
    `,
    CancelForm: styled.form`
      //TODO this is not good
      height: 700px;
      text-align: center;
      background: #F3FAFF 0% 0% no-repeat padding-box;
      padding: 25px;
      opacity: 1;
    `,


}
