import { loadStripe } from "@stripe/stripe-js";
import {
    CancelSubscription,
    CreateCustomer,
    GetConfig,
    GetCustomer,
    GetPrices,
    GetSubscription,
    PreparePayment, RetrievePaymentIntent,
    UpdateCustomer
} from "../api/dto";
import { apiUrl } from "../config";
import { json } from "./util";


export const initializeStripe = async (publishableKey: string) => {
    stripePromise = loadStripe(publishableKey)
}

export let stripePromise: any


class StripeService {
    apiUrl: string

    constructor(apiUrl: string) {
        this.apiUrl = apiUrl;
    }

    async cancelSubscription(dto: CancelSubscription) {
        return fetch(`${ this.apiUrl }/stripe/subscriptions`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dto)
        }).then(res => json(res))
    }

    async getPrices(dto: GetPrices) {
        return fetch(`${ this.apiUrl }/stripe/prices/${ dto.configId }?cohortId=${ dto.cohortId }`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then(res => json(res))
    }

    async createCustomer(dto: CreateCustomer) {
        return fetch(`${ this.apiUrl }/stripe/customers`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dto)
        }).then(res => json(res))
    }


    async updateCustomer(dto: UpdateCustomer) {
        return fetch(`${ this.apiUrl }/stripe/customers`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dto)
        }).then(res => json(res))
    }

    async getCustomer(dto: GetCustomer) {
        return fetch(`${ this.apiUrl }/stripe/customers/${ dto.configId }/${ dto.customerId }`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then(res => json(res))
    }

    async getConfig(dto: GetConfig) {
        return fetch(`${ this.apiUrl }/configs/${ dto.configId }`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then(res => json(res))
    }

    async getSubscription(dto: GetSubscription) {
        return fetch(`${ this.apiUrl }/stripe/${ dto.configId }/subscriptions/${ dto.subscriptionId }`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then(res => json(res))
    }

    async retrievePaymentIntent(dto: RetrievePaymentIntent) {
        return fetch(`${ this.apiUrl }/stripe/${ dto.configId }/paymentIntent/${ dto.id }`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).then(res => json(res))
    }

    async payment(dto: PreparePayment) {
        return fetch(`${ this.apiUrl }/payment`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dto)
        }).then(res => json(res))
    }
}

const stripeService = new StripeService(apiUrl)

export default stripeService
