import styled from 'styled-components'
import discountBG from 'src/assets/plans/ic_discount.png'
import dotHow from 'src/assets/plans/dot_how.svg'

export const Styles = {
    Container: styled.div`
      width: 393px;
      max-width: 393px;
      margin: auto;
      height: 700px;
    `,
    Header: styled.div`
    `,
    HeaderText: styled.p`
      text-align: center;
      font: normal normal bold 24px/30px NunitoExtraBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,
    Body: styled.div`
      opacity: 1;
      width: 393px;
      height: 700px;
    `,
    Graph: styled.div`
      margin-top: 30px;
      background-image: url(${ discountBG });
      background-position: 50% 0%;
      background-size: 343px 280px;
      background-repeat: no-repeat;
      width: 100%;
      height: 310px;

    `,
    DescContainer: styled.div`
      margin-top: 25px;
      margin-right: 45px;
      margin-left: 45px;
    `,
    DescText: styled.p`
      font: normal normal normal 14px/19px NunitoRegular;
      letter-spacing: 0px;
      color: #8CA6C5;
      opacity: 1;
    `,
    AgreementText: styled.div`
      width: 90%;
      height: 75px;
      margin-top: 15px;
      margin-left: 5%;
      text-align: center;
      font: normal normal normal 10px/14px NunitoRegular;
      letter-spacing: 0px;
      color: #8CA6C5;
      opacity: 1;
    `,
    WhatNextContainer: styled.div`
      margin: 10px;
      border: 1px solid #DEEBF5;
      border-radius: 15px;
      opacity: 1;
    `,
    WhatNextHeading: styled.p`
      text-align: center;
      margin-top: 20px;
      font: normal normal bold 24px/32px NunitoBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,
    WhatNextBulletContainer: styled.div`
      display: flex;
      flex-direction: column;
      width: 90%;
      margin: auto;
      margin-bottom: 25px;
    `,
    WhatNextBulletPoint: styled.div`
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
      text-align: left;
      margin-top: 10px;
      font: normal normal normal 14px/19px NunitoRegular;
      letter-spacing: 0px;
      color: #8CA6C5;
      opacity: 1;
    `,
    Dot: styled.div`
      background: transparent url(${ dotHow }) 50% 50% no-repeat padding-box;
      opacity: 1;
      width: 20px;
      height: 20px;
    `,
    BulletContent: styled.div`
      width: 90%;
    `,
    ButtonContainer: styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    `

}
