import React from 'react';
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import icons from "./images"

import userService, { getUserId } from "../../services/user.service";
import { HeaderStyles as H, PageStyles as P, } from "./Styles";
import Header from "./Header";
import Options from "./Options";

export const TeachingLanguage: any = (params: any) => {
    const handleContinue = useNextStep(params)
    const { i18n, t } = useTranslation();

    const onClick = (value: string) => {
        getUserId().then(userId => userService.pushAnswer(userId, {
            question: params.pageId,
            answer: value
        }))

        handleContinue(value)
    }

    const langArray = [
        {
            "icon": icons.englishIcon,
            "text": "English",
            "popular": true,
        },
        {
            "icon": icons.arabicIcon,
            "text": "Arabic"
        },
        {
            "icon": icons.chineseIcon,
            "text": "Chinese"
        },
        {
            "icon": icons.persianIcon,
            "text": "Persian"
        },
        {
            "icon": icons.frenchIcon,
            "text": "French"
        },
        {
            "icon": icons.germanIcon,
            "text": "German"
        },
        {
            "icon": icons.hindiIcon,
            "text": "Hindi"
        },
        {
            "icon": icons.italianIcon,
            "text": "Italian"
        },
        {
            "icon": icons.koreanIcon,
            "text": "Korean"
        },
        {
            "icon": icons.polishIcon,
            "text": "Polish"
        },
        {
            "icon": icons.portugueseIcon,
            "text": "Portuguese"
        },
        {
            "icon": icons.spanishIcon,
            "text": "Spanish"
        },
        {
            "icon": icons.turkishIcon,
            "text": "Turkish"
        },
        {
            "icon": icons.greekIcon,
            "text": "Greek"
        },
        {
            "icon": icons.japaneseIcon,
            "text": "Japanese"
        },
        {
            "icon": icons.pakistaniIcon,
            "text": "Pakistani"
        },
        {
            "icon": icons.vietnameseIcon,
            "text": "Vietnamese"
        },
        {
            "icon": icons.russianIcon,
            "text": "Russian"
        },
        {
            "icon": icons.indonesianIcon,
            "text": "Indonesian"
        },
        {
            "icon": icons.malaysianIcon,
            "text": "Malaysian"
        },
        {
            "icon": icons.dutchIcon,
            "text": "Dutch"
        },
        {
            "icon": icons.hebrewIcon,
            "text": "Hebrew"
        },
        {
            "icon": icons.thaiIcon,
            "text": "Thai"
        },
        {
            "icon": icons.croatianIcon,
            "text": "Croatian"
        },
        {
            "icon": icons.tamilIcon,
            "text": "Tamil"
        },
        {
            "icon": icons.urduIcon,
            "text": "Urdu"
        },
        {
            "icon": icons.marathiIcon,
            "text": "Marathi"
        },
        {
            "icon": icons.gujaratiIcon,
            "text": "Gujarati"
        },
        {
            "icon": icons.georgianIcon,
            "text": "Georgian"
        },
        {
            "icon": icons.uzbekIcon,
            "text": "Uzbek"
        },
        {
            "icon": icons.azerbaijaniIcon,
            "text": "Azerbaijani"
        },
        {
            "icon": icons.serbianIcon,
            "text": "Serbian"
        },
        {
            "icon": icons.cambodianIcon,
            "text": "Cambodian"
        },
        {
            "icon": icons.bengaliIcon,
            "text": "Bengali"
        },
        {
            "icon": icons.romanianIcon,
            "text": "Romanian"
        }

    ]

    return (
        <P.PageContainer>
            <P.Background/>
            <H.HeaderContainer>
                <H.IconContainer icon={ icons.logo }/>
            </H.HeaderContainer>
            <Header title={ "Teaching will be in..." } toolbarPercentage={ "28%" } toolbarText={ "2/7" }/>
            <P.QuestionContainer>
                <Options options={ langArray } onClick={ onClick } maxHeight={ 600 }/>
            </P.QuestionContainer>
        </P.PageContainer>
    );
}

