import styled from 'styled-components'

export const ActivityLevelStyles = {
    QuestionText: styled.p`
      text-align: center;
      font: normal normal 800 24px/30px NunitoExtraBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,
    AnswerContainer: styled.div`
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border: 2px solid #E5F1FE;
      border-radius: 10px;
      opacity: 1;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      height: 60px;
      margin: 15px 0px;
    `,
    AnswerText: styled.p`
      text-align: center;
      font: normal normal bold 18px/24px NunitoBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
      width: 90%;
    `,
    ButtonContainer: styled.div`
      margin-top: 30px;
    `,
    NewbieProContainer: styled.div`
      margin-top: 5px;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
    `,
    NewbieContainer: styled.div`
      width: 90%;
    `,
    ProContainer: styled.div`
      width: 10%;
    `,
    NewbieText: styled.p`
      text-align: left;
      font: normal normal normal 14px/19px NunitoBold;
      letter-spacing: 0px;
      color: #49A8FB;
      opacity: 1;
      
    `,
    ProText: styled.p`
      text-align: right;
      font: normal normal normal 14px/19px NunitoBold;
      letter-spacing: 0px;
      color: #49A8FB;
      opacity: 1;
    `


}
