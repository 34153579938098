import React, { useState } from 'react';
import { QuestionContainer } from "./QuestionContainer";
import { useTranslation } from "react-i18next";
import { StyledInputQuestionContainer as S } from "./InputQuestionContainer.styles";
import { CommonStyles } from "../../styles/common.styles";
import { useWindowSize } from "../../hooks/useWindowSize";
import RippleButton from "../Button/RippleButton";


export interface InputQuestionContainer {
    question: string;
    onClick?: (value: any) => void;
    children?: any
}

export const InputQuestionContainer = (props: InputQuestionContainer) => {
    const [answer, setAnswer] = useState("");
    const [focus, setFocus] = useState(false);
    const { i18n, t } = useTranslation();
    const [width, height] = useWindowSize();

    return (
        <>
            <QuestionContainer>
                <S.QuestionContainer>
                    <S.QuestionText>
                        { props.question }
                    </S.QuestionText>
                </S.QuestionContainer>
                <S.AnswerContainer>
                    <S.AnswerInput focus={ focus } onBlur={ () => setFocus(false) }
                                   onFocus={ () => setFocus(true) }
                                   value={ answer }
                                   onChange={ (e: any) => setAnswer(e.target.value) }>

                    </S.AnswerInput>
                </S.AnswerContainer>

                <S.ButtonContainer>
                    <CommonStyles.DynamicButtonContainer height={ height } maxHeight={ 700 }>
                        <RippleButton onClick={ () => {
                            props.onClick && props.onClick(answer);
                        } } disabled={ !answer }>
                            { t('common.actions.continue') }
                        </RippleButton>
                    </CommonStyles.DynamicButtonContainer>
                </S.ButtonContainer>

            </QuestionContainer>
        </>

    );
}

