import React from "react";
import { ToolbarStyles as To } from "./Styles";
import icons from "./images";
import Title from "./Title";
import { useNavigate } from "react-router-dom";


export interface HeaderProps {
    toolbarPercentage: string,
    toolbarText: string
    title: string,
}

export default function Header(props: HeaderProps) {
    const navigate = useNavigate();

    return (
        <To.ToolbarTitleContainer>
            <To.ToolbarContainer>
                <To.ToolbarButton icon={ icons.backArrow } onClick={ () => navigate(-1) }/>
                <To.ProgressBarContainer>
                    <To.ProgressBarFiller completed={ props.toolbarPercentage }>
                        <To.ProgressText>
                            { props.toolbarText }
                        </To.ProgressText>
                    </To.ProgressBarFiller>
                </To.ProgressBarContainer>
                <To.ToolbarButton icon={ icons.closeIcon } onClick={ () => navigate("/v4/") }/>
            </To.ToolbarContainer>
            <Title title={ props.title }></Title>
        </To.ToolbarTitleContainer>
    )
}
