import { TPageProps } from "../../pages/questions/TPageProps";
import { HeaderContainer } from "../Toolbar/HeaderContainer";
import { PageContainer } from "../PageContainer/PageContainer";
import { StyledTutoring as S } from "./TutoringProgram.styles";
import RippleButton from "../Button/RippleButton";
import React from "react";
import { useNextStep } from "../../hooks/useNextStep";
import { CommonStyles } from "../../styles/common.styles";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useGetPageInfo } from "../../hooks/useGetPageInfo";
import { useTranslation } from "react-i18next";

export default function TutoringProgram(params: TPageProps) {
    const handleContinue = useNextStep(params);
    const [width, height] = useWindowSize();
    const { currentCohort } = useGetPageInfo()
    const { i18n, t } = useTranslation();

    return (
        <>
            <HeaderContainer progressHidden={ true } backArrowHidden={ true }/>
            <PageContainer>
                <S.HeaderContainer>
                    <S.BoldTextContainer>
                        <S.BoldText>
                            { t('tutoring.boldtext') }
                        </S.BoldText>
                    </S.BoldTextContainer>
                    <S.DescriptionTextContainer>
                        <S.DescriptionText>
                            { t('tutoring.desctext') }
                        </S.DescriptionText>
                    </S.DescriptionTextContainer>
                    <S.BodyContainer>
                        { currentCohort === 'v3' &&
                            <S.ImageContainer cohort={ currentCohort }>
                            </S.ImageContainer>
                        }

                        { currentCohort !== 'v3' &&
                            <S.ImageContainer cohort={ currentCohort }>
                            </S.ImageContainer>
                        }

                        <S.ButtonContainer>
                            <CommonStyles.DynamicButtonContainer height={ height } maxHeight={ 720 }>
                                <RippleButton onClick={ () => {
                                    handleContinue("ok")
                                } } disabled={ false }>
                                    { t('tutoring.continue') }
                                </RippleButton>
                            </CommonStyles.DynamicButtonContainer>
                        </S.ButtonContainer>
                    </S.BodyContainer>
                </S.HeaderContainer>
            </PageContainer>
        </>
    )
}
