import React, { useEffect } from "react";
import { Styles as S } from "./ProgressButton.styles"
import oval from "../../assets/checkout/oval.svg";

export default function ProgressButton(props: any) {

    const [coords, setCoords] = React.useState({ x: -1, y: -1 });
    const [isRippling, setIsRippling] = React.useState(false);

    useEffect(() => {
        if (coords.x !== -1 && coords.y !== -1) {
            setIsRippling(true);
            setTimeout(() => setIsRippling(false), 400);
        } else setIsRippling(false);
    }, [coords]);

    useEffect(() => {
        if (!isRippling) setCoords({ x: -1, y: -1 });
    }, [isRippling]);

    return (
        <S.Button { ...props } onClick={ (e: any) => {
            const rect = e.target.getBoundingClientRect();
            setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top });
            props.onClick && props.onClick(e);
        } }>
            { isRippling ? (<S.RippleSpan style={ { left: coords.x, top: coords.y } }/>) : ('') }
            { props.loading === 1 &&
                <S.LoadingContainer>
                    <S.Loading src={ oval }/>
                </S.LoadingContainer>
            }

            { props.loading === 0 &&
                <S.ButtonText>
                    { props.children }
                </S.ButtonText>
            }

            <S.ContentSpan/>
        </S.Button>

    )
}
