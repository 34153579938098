import styled from "styled-components";
import headerBG from 'src/assets/checkout/bg_top_tutoring.svg'
import radioUnselected from 'src/assets/checkout/btn_radio_unselected.svg'
import radioSelected from 'src/assets/checkout/btn_radio_selected.svg'
import lockBackground from "../../assets/checkout/ic_lock.svg"

export const Styles = {
    HeaderContainer: styled.div`
      padding-bottom: 10%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-size: contain;
      background-image: url(${ headerBG });
      opacity: 1;
    `,
    HeaderTextBlack: styled.p`
      width: 80%;
      text-align: center;
      font: normal normal 800 24px/30px NunitoExtraBold;
      letter-spacing: 0px;
      color: #3A3A3A;
    `,
    HeaderTextBlue: styled.p`
      width: 80%;
      text-align: center;
      font: normal normal 800 24px/30px NunitoExtraBold;
      letter-spacing: 0px;
      color: #49A8FB;
    `,
    BlueBackground: styled.div`
      background: #F3FAFF 0% 0% no-repeat padding-box;
      padding: 25px;
    `,
    EmailContainer: styled.div`
      opacity: 1;
      margin-bottom: 10px;
    `,
    EmailTextContainer: styled.div`,
    `,
    EmailText: styled.p`
      text-align: left;
      font: normal normal bold 16px/22px NunitoBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,
    EmailInput: styled.input`
      border-radius: 10px;
      border: none;
      opacity: 1;
      height: 40px;
      width: 100%;
      text-indent: 10px;
      font: normal normal normal 16px/16px NunitoRegular;
    `,
    EmailForm: styled.form`
      //TODO this is not good
      height: 700px;
      opacity: 1;
    `,
    PromotionWrapper: styled.div`
      display: flex;
      margin-top: 10%;
      width: 100%;
    `,
    PromotionCheckBox: styled.div<{ selected: boolean }>`
      background: transparent url(${ props => props.selected ? radioSelected : radioUnselected }) 0% 0% no-repeat padding-box;
      opacity: 1;
      margin: 3%;
      width: 15%;
      height: 20px;
    `,
    PromotionText: styled.p`
      text-align: center;
      font: normal normal normal 14px/19px Nunito;
      letter-spacing: 0px;
      color: #8CA6C5;
      opacity: 1;
    `,
    PrivacyContainer: styled.div`
      padding-top: 5%;
      display: flex;
    `,
    PrivacyText: styled.p`
      text-align: center;
      font: normal normal normal 14px/19px Nunito;
      letter-spacing: 0px;
      color: #8CA6C5;
      opacity: 1;
    `,
    PrivacyIcon: styled.div`
      background: transparent url(${ lockBackground }) 0% 0% no-repeat padding-box;
      opacity: 1;
      width: 20%;
    `


}
