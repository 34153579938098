import styled from "styled-components";
import headerBG from 'src/assets/checkout/bg_top_tutoring.svg'
import cardBG from 'src/assets/checkout/card_access_plan.svg'
import icon from 'src/assets/checkout/appicon_access_plan.jpg'

export const Styles = {
    DownloadPageContainer: styled.div`
      background: #F3FAFF 0% 0% no-repeat padding-box;
      opacity: 1;
      min-height: 100%;
    `,
    HeaderContainer: styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-size: contain;
      background-image: url(${ headerBG });
      padding-bottom: 10%;
      padding-top: 5%;
      opacity: 1;
    `,
    HeaderContainerText: styled.p`
      width: 80%;
      text-align: center;
      font: normal normal 800 24px/30px NunitoExtraBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,
    DownloadContainer: styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `,
    CardContainer: styled.div`
      margin-top: 80px;
      background: transparent url(${ cardBG }) 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 0px #0000000F;
      opacity: 1;
      height: 253px;
      width: 273px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `,
    CardIcon: styled.div`
      background: transparent url(${ icon }) 0% 0% no-repeat padding-box;
      border-radius: 15px;
      opacity: 1;
      width: 80px;
      height: 80px;
    `,
    FirstCardTextContainer: styled.div`
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 15px;
    `,
    SecondCardTextContainer: styled.div`
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 3px;
    `,
    CardTextSemiBold: styled.p`
      text-align: left;
      font: normal normal 600 28px/38px NunitoSemiBold;
      letter-spacing: 0px;
      color: #3A3A3A;
    `,
    CardTextExtraBold: styled.p`
      text-align: left;
      font: normal normal 800 28px/38px NunitoExtraBold;
      letter-spacing: 0px;
      color: #3A3A3A;
    `,
    CardTextRegular: styled.p`
      margin-top: 3px;
      text-align: left;
      font: normal normal normal 15px/20px Nunito;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `
}
