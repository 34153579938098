import React from 'react';
import { PaymentFailedStyles as P } from "./Styles";
import { Button } from "./Button";


export const PaymentFailed = (props: any) => {

    return (
        <P.Container>
            <P.IconContainer>

            </P.IconContainer>
            <P.DescContainer>
                An error occurred during payment. Click the button to try again
            </P.DescContainer>

            <P.ButtonContainer>
                <Button text={ "TRY AGAIN" } onclick={ () => {
                    props.onTryAgain && props.onTryAgain();
                } }/>
            </P.ButtonContainer>
        </P.Container>
    );
}

