import configData from "./config.json";

interface Config {
    "configId": string
    "apiUrl": string
}

const config = configData as Config

export const apiUrl = config.apiUrl
export const configId = config.configId

export const isTest = () => {
    return configId === "test"
}


export const isNotTest = () => {
    return !isTest();
}
