import React from 'react';
import { ForecastStyles as F } from "./Styles";
import icons from "./images"


export const Forecast = (props: any) => {

    return (
        <F.Container>
            <F.TitleContainer>
                <F.Title>
                    Forecast based on your answers
                </F.Title>
            </F.TitleContainer>
            <F.ImageContainer img={ icons.forecast }/>
        </F.Container>
    );
}

