import React from 'react';
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import icons from "./images"
import { InformativeStyles as I, PageStyles as P } from "./Styles";

import userService, { getUserId } from "../../services/user.service";
import { Informative, InformativeProps } from "./Informative";

export const NoWasteTime: any = (params: any) => {
    const handleContinue = useNextStep(params)
    const { i18n, t } = useTranslation();

    const onClick = (value: string) => {
        getUserId().then(userId => userService.pushAnswer(userId, {
            question: params.pageId,
            answer: value
        }))

        handleContinue(value)
    }

    const targetLanguage = window.sessionStorage.getItem("targetLanguage")

    const informativeProps: InformativeProps = {
        title: "No waste of time",
        icon: icons.hourGlass,
        desc:
            <I.InformativeDescText>
                You need just a few minutes a day to improve your { targetLanguage }
            </I.InformativeDescText>
        ,
        background: "#C8DF87",
        buttonText: "NEXT",
        onClick: () => {
            onClick("NoWasteTime")
        },
        maxHeight: 690
    }

    return (
        <>
            <P.Background/>
            <Informative { ...informativeProps } />
        </>
    );
}

