import React, { useMemo } from 'react';
import { TPageProps } from "../TPageProps";
import { useNextStep } from "src/hooks/useNextStep";
import { QuestionTitle } from "src/components/QuestionTitle/QuestionTitle";
import { Option, TOptionPropsBase } from "src/components/Option/Option";
import { useTranslation } from "react-i18next";
import advanced_icon from 'src/assets/languageLevel/ic_advanced.svg'
import ic_proficient from 'src/assets/languageLevel/ic_proficient.svg'
import { usePostDelay } from "src/hooks/usePostDelay";
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import { QuestionContainer } from "../../../components/QuestionContainer/QuestionContainer";
import userService, { getUserId } from "../../../services/user.service";
import { Toolbar } from "../../../components/Toolbar/Toolbar";
import { DividedToolbar } from "../../../components/Toolbar/DividedToolbar";
import { useGetPageInfo } from "../../../hooks/useGetPageInfo";

export const Goal: React.FC<TPageProps> = (params: TPageProps) => {
    const handleContinue = useNextStep(params)
    const { i18n, t } = useTranslation();
    const { isOptionsDisabled, handleChange, setIsOptionsDisabled } = usePostDelay()
    const { currentCohort } = useGetPageInfo()

    const props = useMemo<TOptionPropsBase>(
        () => ({
            disabled: isOptionsDisabled,
            onUpdated: (value: string) => {
                setIsOptionsDisabled(true)
                getUserId().then(userId => userService.pushAnswer(userId, {
                    question: params.pageId,
                    answer: value
                }))

                handleChange(value, handleContinue)
            }
        }), [isOptionsDisabled, setIsOptionsDisabled, handleChange, handleContinue, params.pageId]
    )

    return (
        <>
            { currentCohort !== 'v3' && <Toolbar progressValue={ 2 } pagesNumberWithNavigation={ 6 }></Toolbar> }
            { currentCohort === 'v3' &&
                <DividedToolbar index={ 0 } progressValue={ 2 } pagesNumberWithNavigation={ 9 }></DividedToolbar>
            }

            <PageContainer>
                <QuestionContainer>
                    <QuestionTitle>{ t('goal.title') }</QuestionTitle>
                    <Option { ...props } icon={ advanced_icon }
                            title={ t('goal.advanced.title') }
                            id={ 'novice' }
                            desc={ t('goal.advanced.desc') }/>
                    <Option { ...props } icon={ ic_proficient }
                            title={ t('goal.proficient.title') }
                            id={ 'elementary' }
                            desc={ t('goal.proficient.desc') }/>
                </QuestionContainer>
            </PageContainer>
        </>
    );
}

