import { Answer, User } from "../api/dto";
import { apiUrl, configId } from "../config";
import { json } from "./util";

export class UserService {
    apiUrl: string

    constructor(apiUrl: string) {
        this.apiUrl = apiUrl;
    }

    async createUser(dto: User): Promise<User> {
        return fetch(`${ this.apiUrl }/users`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dto)
        }).then(res => json(res))
    }

    async updateUser(dto: User): Promise<User> {
        return fetch(`${ this.apiUrl }/users/${ dto._id }`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dto)
        }).then(res => json(res))
    }

    async pushAnswer(userId: string, dto: Answer): Promise<any> {
        return fetch(`${ this.apiUrl }/users/${ userId }/answers`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(dto)
        }).then(res => json(res))
    }

}

const userService = new UserService(apiUrl)

export const initializeUser = async () => {
    return userService.createUser({
        configId: configId
    }).then(user => user._id && window.sessionStorage.setItem('userId', user._id))
}

export const getUserId = async () => {
    const userId = window.sessionStorage.getItem('userId')

    if (userId) {
        return userId
    }

    return initializeUser().then((_) => window.sessionStorage.getItem('userId'))
        .then(userId => {
            if (!userId) {
                return ''
            }

            return userId
        })
}

export default userService
