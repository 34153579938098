import { Cohort, DEFAULT_COHORT } from "src/constants/rootConstants";
import { CurrentLevel } from "./questions/CurrentLevel/CurrentLevel";
import { PreferredLearningMethod } from "./questions/PreferredLearningMethod/PreferredLearningMethod";
import React from "react";
import { TPageProps } from "./questions/TPageProps";
import { ExperienceSoFar } from "./questions/ExperienceSoFar/ExperienceSoFar";
import { InterestedInAspects } from "./questions/InterestedInAspects/InterestedInAspects";
import { FocusTopics } from "./questions/FocusTopics/FocusTopics";
import { InterestedInReason } from "./questions/InterestedInReason/InterestedInReason";
import CheckoutEmailProvider from "../components/Checkout/CheckoutEmailProvider";
import RegisterForm from "../components/Register/RegisterForm";
import Plans from "./questions/Plans/Plans";
import { Analysis } from "./questions/Analysis/Analysis";
import DownloadPage from "../components/Download/DownloadPage";
import TutoringProgram from "../components/TutoringProgram/TutoringProgram";
import { LongCurrentLevel } from "./questions/CurrentLevel/LongCurrentLevel";
import { Goal } from "./questions/Goal/Goal";
import { NativeLanguage } from "./questions/NativeLanguage/NativeLanguage";
import { GoodJob } from "./questions/GoodJob/GoodJob";
import { GrammarTest1 } from "./questions/GrammerTest/GrammarTest1";
import { GrammarTest2 } from "./questions/GrammerTest/GrammarTest2";
import { WellDone } from "./questions/WellDone/WellDone";
import { WhatIsYourName } from "./questions/GrammerTest/WhatIsYourName";
import { HowOldAreYou } from "./questions/GrammerTest/HowOldAreYou";
import { SuitableTime } from "./questions/SuitableTime/SuitableTime";
import { HowOften } from "./questions/GrammerTest/HowOften";
import { HowMuchTimeDaily } from "./questions/GrammerTest/HowMuchTimeDaily";
import { InterestingTopics } from "./questions/GrammerTest/InterestingTopics";
import EmailForm from "../components/Register/EmailForm";
import CheckoutProvider from "../components/Checkout/CheckoutProvider";
import { NoteTaking } from "./questions/NoteTaking/NoteTaking";
import { SpeakingEnvironment } from "./questions/SpeakingEnvironment/SpeakingEnvironment";
import {
    MultiSelectPreferredLearningMethod
} from "./questions/PreferredLearningMethod/MultipleSelectPreferredLearningMethod";
import { ActivityLevel } from "./questions/ActivityLevel/ActivityLevel";
import { AnalysisV2 } from "./questions/Analysis/Analysisv2";
import { AnalysisV3 } from "./questions/Analysis/Analysisv3";
import { HowHard } from "./questions/GrammerTest/HowHard";
import { Statistics } from "./questions/Statistics/Statistics";
import { WordQuestions1 } from "./questions/GrammerTest/WordQuestions1";
import { WordQuestions2 } from "./questions/GrammerTest/WordQuestions2";
import { WordQuestions3 } from "./questions/GrammerTest/WordQuestions3";
import { TargetLanguage } from "./Cohort4/TargetLanguage";
import { TeachingLanguage } from "./Cohort4/TeachingLanguage";
import { Motivation } from "./Cohort4/Motivation";
import { BrainHealth } from "./Cohort4/BrainHealth";
import { CurrentLevelC4 } from "./Cohort4/CurrentLevelC4";
import { AllLevelLessons } from "./Cohort4/AllLevelLessons";
import { WhatAspects } from "./Cohort4/WhatAspects";
import { DailyTime } from "./Cohort4/DailyTime";
import { NoWasteTime } from "./Cohort4/NoWasteTime";
import { HowOftenC4 } from "./Cohort4/HowOftenC4";
import { WellDoneC4 } from "./Cohort4/WellDoneC4";
import { WhatIsYourNameC4 } from "./Cohort4/WhatIsYourNameC4";
import { SignUp } from "./Cohort4/SignUp";
import { AccountReady } from "./Cohort4/AccountReady";
import { PaymentC4 } from "./Cohort4/PaymentC4";


export const enum PageId {
    CURRENTLEVEL = 'currentLevel',
    INTERESTEDINREASON = 'interestedInReason',
    INTERESTEDINASPECTS = 'interestedInAspects',
    EXPERIENCESOFAR = 'experienceSoFar',
    FOCUSTOPICS = 'focusTopics',
    PREFERREDLEARNINGMETHOD = 'preferredLearningMethod',
    REGISTER = 'register',
    CHECKOUTPROVIDER = 'checkout',
    CHECKOUTWITHEMAILPROVIDER = 'checkoutWithEmail',
    PLANS = 'plans',
    ANALYSIS = 'analysis',
    DOWNLOADPAGE = 'downloadPage',
    TUTORINGPROGRAM = 'tutoringProgram',
    LONGCURRENTLEVEL = 'longCurrentLevel',
    GOAL = 'goal',
    NATIVELANGUAGE = 'nativeLanguage',
    GOODJOB = 'goodJob',
    GRAMMARTEST1 = 'grammarTest1',
    GRAMMARTEST2 = 'grammarTest2',
    WELLDONE = 'wellDone',
    WHATISYOURNAME = 'whatIsYourName',
    HOWOLDAREYOU = 'howOldAreYou',
    SUITABLETIME = 'suitableTime',
    HOWOFTEN = 'howOften',
    HOWMUCHTIMEDAILY = 'howMuchTimeDaily',
    INTERESTINGTOPICS = 'interestingTopics',
    EMAILFORM = 'emailForm',
    NOTETAKING = 'noteTaking',
    SPEAKINGENVIRONMENT = 'speakingEnvironment',
    MULTISELECTPREFERREDLEARNINGMETHOD = 'multiSelectPreferredLearningMethod',
    ACTIVITYLEVEL = 'activityLevel',
    ANALYSISV2 = 'analysisv2',
    ANALYSISV3 = 'analysisv3',
    HOWHARD = 'howHard',
    WORDQUESTIONS1 = 'wordQuestions1',
    WORDQUESTIONS2 = 'wordQuestions2',
    WORDQUESTIONS3 = 'wordQuestions3',
    STATISTICS = 'statistics',

    TARGETLANGUAGE = 'targetLanguage',
    TEACHINGLANGUAGE = 'teachingLanguage',
    MOTIVATION = 'motivation',
    BRAINHEALTH = 'brainHealth',
    CURRENTLEVELC4 = 'currentLevelC4',
    ALLLEVELLESSONS = 'allLevelLessons',
    WHATASPECTS = 'whatAspects',
    DAILTYTIME = 'dailyTime',
    NOWASTETIME = 'noWasteTime',
    HOWOFTENC4 = 'howOftenC4',
    WELLDONEC4 = 'wellDoneC4',
    WHATISYOURNAMEC4 = 'whatIsYourNameC4',
    SIGNUP = 'signUp',
    ACCOUNTREADY = 'accountReady',
    PAYMENTC4 = 'paymentC4',
}


const _ID_TO_PAGE_MAP = {
    currentLevel: CurrentLevel,
    interestedInReason: InterestedInReason,
    interestedInAspects: InterestedInAspects,
    experienceSoFar: ExperienceSoFar,
    focusTopics: FocusTopics,
    preferredLearningMethod: PreferredLearningMethod,
    register: RegisterForm,
    checkout: CheckoutProvider,
    checkoutWithEmail: CheckoutEmailProvider,
    plans: Plans,
    analysis: Analysis,
    downloadPage: DownloadPage,
    tutoringProgram: TutoringProgram,
    longCurrentLevel: LongCurrentLevel,
    goal: Goal,
    nativeLanguage: NativeLanguage,
    goodJob: GoodJob,
    grammarTest1: GrammarTest1,
    grammarTest2: GrammarTest2,
    wellDone: WellDone,
    whatIsYourName: WhatIsYourName,
    howOldAreYou: HowOldAreYou,
    suitableTime: SuitableTime,
    howOften: HowOften,
    howMuchTimeDaily: HowMuchTimeDaily,
    interestingTopics: InterestingTopics,
    emailForm: EmailForm,
    noteTaking: NoteTaking,
    speakingEnvironment: SpeakingEnvironment,
    multiSelectPreferredLearningMethod: MultiSelectPreferredLearningMethod,
    activityLevel: ActivityLevel,
    analysisv2: AnalysisV2,
    analysisv3: AnalysisV3,
    howHard: HowHard,
    wordQuestions1: WordQuestions1,
    wordQuestions2: WordQuestions2,
    wordQuestions3: WordQuestions3,
    statistics: Statistics,

    targetLanguage: TargetLanguage,
    teachingLanguage: TeachingLanguage,
    motivation: Motivation,
    brainHealth: BrainHealth,
    currentLevelC4: CurrentLevelC4,
    allLevelLessons: AllLevelLessons,
    whatAspects: WhatAspects,
    dailyTime: DailyTime,
    noWasteTime: NoWasteTime,
    howOftenC4: HowOftenC4,
    wellDoneC4: WellDoneC4,
    whatIsYourNameC4: WhatIsYourNameC4,
    signUp: SignUp,
    accountReady: AccountReady,
    paymentC4: PaymentC4
}

export const ID_TO_PAGE_MAP = (pageId: PageId): React.FC<TPageProps> => _ID_TO_PAGE_MAP[pageId]


export const LGTOWN_SHORT_TUTORING_SET_ONE: { id: PageId } [] = [
    { id: PageId.TUTORINGPROGRAM },
    { id: PageId.CURRENTLEVEL },
    { id: PageId.EXPERIENCESOFAR },
    { id: PageId.INTERESTEDINASPECTS },
    { id: PageId.INTERESTEDINREASON },
    { id: PageId.FOCUSTOPICS },
    { id: PageId.PREFERREDLEARNINGMETHOD },
    { id: PageId.ANALYSIS },
    { id: PageId.PLANS },
    { id: PageId.CHECKOUTWITHEMAILPROVIDER },
    { id: PageId.REGISTER },
    { id: PageId.DOWNLOADPAGE },
]

export const LGTOWN_SHORT_TUTORING_SET_TWO: { id: PageId } [] = [
    { id: PageId.CURRENTLEVEL },
    { id: PageId.PLANS },
    { id: PageId.CHECKOUTPROVIDER },
    { id: PageId.REGISTER },
    { id: PageId.DOWNLOADPAGE },
]

export const LGTOWN_LONG_TUTORING_SET_ONE: { id: PageId } [] = [
    { id: PageId.TUTORINGPROGRAM },
    { id: PageId.LONGCURRENTLEVEL },
    { id: PageId.GOAL },
    { id: PageId.NATIVELANGUAGE },
    { id: PageId.INTERESTEDINREASON },
    { id: PageId.FOCUSTOPICS },
    { id: PageId.PREFERREDLEARNINGMETHOD },
    { id: PageId.GOODJOB },
    { id: PageId.GRAMMARTEST1 },
    { id: PageId.GRAMMARTEST2 },
    { id: PageId.WELLDONE },
    { id: PageId.WHATISYOURNAME },
    { id: PageId.HOWOLDAREYOU },
    { id: PageId.SUITABLETIME },
    { id: PageId.HOWOFTEN },
    { id: PageId.HOWMUCHTIMEDAILY },
    { id: PageId.INTERESTINGTOPICS },
    { id: PageId.ANALYSIS },
    { id: PageId.PLANS },
    { id: PageId.CHECKOUTWITHEMAILPROVIDER },
    { id: PageId.REGISTER },
    { id: PageId.DOWNLOADPAGE },
]

export const LGTOWN_LONG_THIRD: { id: PageId } [] = [
    { id: PageId.TUTORINGPROGRAM },
    { id: PageId.NATIVELANGUAGE },
    { id: PageId.INTERESTEDINREASON },
    { id: PageId.INTERESTEDINASPECTS },
    { id: PageId.NOTETAKING },
    { id: PageId.SPEAKINGENVIRONMENT },
    { id: PageId.MULTISELECTPREFERREDLEARNINGMETHOD },
    { id: PageId.GOODJOB },
    { id: PageId.WORDQUESTIONS1 },
    { id: PageId.WORDQUESTIONS2 },
    { id: PageId.WORDQUESTIONS3 },
    { id: PageId.WELLDONE },
    { id: PageId.WHATISYOURNAME },
    { id: PageId.HOWOLDAREYOU },
    { id: PageId.SUITABLETIME },
    { id: PageId.HOWOFTEN },
    { id: PageId.HOWMUCHTIMEDAILY },
    { id: PageId.INTERESTINGTOPICS },
    { id: PageId.ACTIVITYLEVEL },
    { id: PageId.ANALYSISV2 },
    { id: PageId.HOWHARD },
    { id: PageId.ANALYSISV3 },
    { id: PageId.EMAILFORM },
    { id: PageId.PLANS },
    { id: PageId.CHECKOUTPROVIDER },
    { id: PageId.REGISTER },
    { id: PageId.DOWNLOADPAGE },
]

export const LGTOWN_LONG_FOURTH_v1: { id: PageId } [] = [
    { id: PageId.TARGETLANGUAGE },
    { id: PageId.TEACHINGLANGUAGE },
    { id: PageId.MOTIVATION },
    { id: PageId.BRAINHEALTH },
    { id: PageId.CURRENTLEVELC4 },
    { id: PageId.ALLLEVELLESSONS },
    { id: PageId.WHATASPECTS },
    { id: PageId.DAILTYTIME },
    { id: PageId.NOWASTETIME },
    { id: PageId.HOWOFTENC4 },
    { id: PageId.WELLDONEC4 },
    { id: PageId.WHATISYOURNAMEC4 },
    { id: PageId.SIGNUP },
    { id: PageId.ACCOUNTREADY },
    { id: PageId.PAYMENTC4 },
    { id: PageId.DOWNLOADPAGE },
]


export const DYNAMIC_PAGES_MAP = {
    [Cohort.LGTOWN_SHORT_ONE]: LGTOWN_SHORT_TUTORING_SET_ONE,
    [Cohort.LGTOWN_SHORT_TWO]: LGTOWN_SHORT_TUTORING_SET_TWO,
    [Cohort.LGTOWN_LONG_ONE]: LGTOWN_LONG_TUTORING_SET_ONE,
    [Cohort.LGTOWN_LONG_THIRD]: LGTOWN_LONG_THIRD,
    [Cohort.LGTOWN_LONG_FOURTH_v1]: LGTOWN_LONG_FOURTH_v1
}

export const getCohortFromPath = (pathName: string): Cohort => {
    const pathSplit = pathName.split("/");
    let path = pathSplit[1];

    if (!(Object.values(Cohort) as string[]).includes(path)) {
        return DEFAULT_COHORT
    }

    return path as Cohort
}
