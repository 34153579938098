import React, { useEffect, useState } from 'react';
import './App.css';
import { createBrowserHistory } from 'history'
import { initializeFirebase } from "./services/firebase.service";
import stripeService, { initializeStripe } from "./services/stripe.service";
import { CustomRouter } from "./CustomRouter";
import { configId } from "./config";
import RouteList from "./RouteList";
import { getUserId } from "./services/user.service";
import { initializeSentry } from "./services/sentry.service";
import { initializeMetaPixel } from "./services/metapixel.service";

export const history = createBrowserHistory();

export default function App() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.sessionStorage.setItem("initialUrl", window.location.href)
        stripeService.getConfig({
            configId: configId,
        }).then(config => Promise.all([initializeFirebase(config.firebaseConfig), initializeStripe(config.stripePublishableKey), getUserId(), initializeSentry(config.sentryDsn), initializeMetaPixel(config.facebookPixelId)]))
            .then((_) => setLoading(false))
    }, []);


    return (
        <>
            { !loading &&
                <CustomRouter history={ history }>
                    <RouteList/>
                </CustomRouter> }
        </>
    );
}
