import styled from "styled-components";

export const Styles = {

    PrivacyPolicyForm: styled.div`
       margin: 20px;
 word-wrap: break-word;
    `,

}
