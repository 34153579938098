import styled, { css } from 'styled-components'
import checkmark from 'src/assets/analysis/checkmark.svg'


export const Styled = {
    AnalysisContainer: styled.div`
    `,
    ProgressBarContainer: styled.div`
      width: 238px;
      height: 238px;
      display: block;
      margin: auto;
      margin-top: 20px;
      font: normal normal bold 75px/102px NunitoBold;
      text-align: center;
    `,
    ListContainer: styled.div`
      margin-top: 60px;
      flex-direction: row;
    `,
    Li: styled.li`
      list-style: none;
      flex-direction: row;
      margin-top: 20px;
      display: flex;
    `,
    Icon: styled.div<{ visible: boolean }>`
      height: 20px;
      width: 20px;
      ${ props => props.visible && css`
        visibility: visible;
      ` }
      ${ props => !props.visible && css`
        visibility: hidden;
      ` }
      background: transparent url(${ checkmark }) 50% 50% no-repeat padding-box;
    `,
    Desc: styled.p<{ finished: boolean }>`
      margin-left: 15px;
      flex-direction: row;
      text-align: left;
      letter-spacing: 0px;
      ${ props => props.finished && css`
        font: normal normal normal 16px/20px NunitoRegular;
        color: #8CA6C5;
      ` }
      ${ props => !props.finished && css`
        font: normal normal bold 16px/20px NunitoBold;
        color: #3A3A3A;
      ` }
    `


}
