import styled from 'styled-components'

export const StyledOption = {
    OptionContainer: styled.div<{ selected: boolean }>`
      cursor: pointer;
      background-color: ${ props => props.selected ? 'transparent' : '#EBF7FF' };
      border: 2px solid ${ props => props.selected ? '#49A8FB' : 'transparent' };
      border-radius: 15px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-top: 15px;
      height: 85px;
      width: 100%;
    `,
    ContentContainer: styled.div`
      display: flex;
      flex-direction: column;
      padding-left: 15px;
      width: 285px;
    `,
    Title: styled.div`
      text-align: left;
      font: normal normal bold 16px/22px NunitoBold;
      letter-spacing: 0px;
      //height: 22px;
      opacity: 1;
      color: #3A3A3A;
    `,
    Desc: styled.div`
      text-align: left;
      font: normal normal normal 13px/16px NunitoRegular;
      color: #8CA6C5;
      letter-spacing: 0px;
      height: 36px;
      opacity: 1;
    `,
    IconContainer: styled.div<{ img: string | undefined }>`
      width: 50px;
      height: 50px;
      background: transparent url(${ props => props.img }) 50% 50% no-repeat padding-box;
      opacity: 1;
    `,
}
