import React from 'react';
import {Styles as S} from "./PrivacyPolicy.styles";
import {useTranslation} from "react-i18next";
import 'react-toastify/dist/ReactToastify.css';

export const PrivacyPolicy = (props: any) => {
    const {i18n, t} = useTranslation();

    return (
        <S.PrivacyPolicyForm>
            <p><strong>Privacy Policy</strong></p>
            <ol>
                <li><strong>Introduction</strong></li>
            </ol>
            <p>This notice explains how we process personal data, as a data controller, relating to:</p>
            <ul>
                <li>visitors (&ldquo;<strong>Website Users</strong>&rdquo;) to our website: https://www.oceanfloatmobile.com/&nbsp;&nbsp;(&ldquo;<strong>Website</strong>&rdquo;); and</li>
                <li>users (&ldquo;<strong>Mobile App Users</strong>&rdquo;) of our mobile apps (&ldquo;<strong>mobile</strong> <strong>apps</strong>&rdquo;), which are accessible via certain online stores, including:&nbsp;<ul>
                    <li>the App Store, here: <a href="https://apps.apple.com/us/developer/ocean-float-mobile/id1669752211">https://apps.apple.com/us/developer/ocean-float-mobile/id1669752211</a>&nbsp;</li>
                    <li>the Google Play Store, here: <a href="https://play.google.com/store/apps/dev?id=7199647554506214412">https://play.google.com/store/apps/dev?id=7199647554506214412</a>&nbsp;</li>
                </ul>
                </li>
            </ul>
            <p>and together the Website Users and Mobile App Users shall be the &ldquo;Users&rdquo; (and &ldquo;you&rdquo; and &ldquo;your&rdquo; shall refer to any User).&nbsp;</p>
            <p>We update this notice from time to time, so Users should check back in on a regular basis.</p>
            <p>Please note that other websites or services mentioned on the Website or our Apps are not operated by us, and you should refer to the privacy policies on those websites to understand how their personal data is being used.</p>
            <p>We are committed to safeguarding the privacy of our website and mobile apps visitors and users. This policy applies where we are acting as a data controller with respect to the personal data of such persons; in other words, where we determine the purposes and means of the processing of that personal data.&nbsp;</p>
            <p>In this policy, &quot;we&quot;, &quot;us&quot; and &quot;our&quot; refer to <strong>Ocean Float Mobile</strong> <strong>Limited</strong>. For more information about us, see Our Details.</p>
            <ol>
                <li><strong>Credit</strong>
                    <ol>
                        <li>This document was created using a template from Docular (<a href="https://seqlegal.com/free-legal-documents/privacy-policy">https://seqlegal.com/free-legal-documents/privacy-policy</a>).</li>
                    </ol>
                </li>
                <li><strong>The personal data that we collect</strong>
                    <ol>
                        <li>In this Section 3 we have set out the general categories of personal data that we process and, in the case of personal data that we did not obtain directly from you, information about the source and specific categories of that data.</li>
                        <li>We may process data enabling us to get in touch with you (&quot;<strong>contact data</strong>&quot;). The contact data may include your name, email address, telephone number, postal address and/or social media account identifiers. The source of the contact data is you. If you log into our website and mobile apps using a social media account, we will obtain elements of the contact data from the relevant social media account provider.&nbsp;</li>
                        <li>We may process your website and mobile apps user account data (&quot;<strong>account data</strong>&quot;). The account data may include your account identifier, name, email address, business name, account creation and modification dates, website and mobile apps settings and marketing preferences. The primary source of the account data is you although some elements of the account data may be generated by our website and mobile apps. If you log into our website and mobile apps using a social media account, we will obtain elements of the account data from the relevant social media account provider.</li>
                        <li>We may process information relating to transactions, including purchases of goods and/or services, that you enter into with us and/or through our website and mobile apps (&quot;<strong>transaction data</strong>&quot;). The transaction data may include your name, your contact details, your payment card details (or other payment details) and the transaction details. The source of the transaction data is you and/or our payment services provider.</li>
                        <li>We may process information contained in or relating to any communication that you send to us or that we send to you (&quot;<strong>communication data</strong>&quot;). The communication data may include the communication content and metadata associated with the communication. Our website and mobile apps will generate the metadata associated with communications made using the website and mobile apps contact forms.</li>
                        <li>We may process data about your use of our website and mobile apps and services (&quot;<strong>usage data</strong>&quot;). The usage data may include your IP address, geographical location, browser type and version, operating system, referral source, length of visit, page views and website and mobile apps navigation paths, as well as information about the timing, frequency and pattern of your service use. The source of the usage data is our analytics tracking system.</li>
                    </ol>
                </li>
                <li><strong>Purposes of processing and legal bases</strong>
                    <ol>
                        <li>In this Section 4, we have set out the purposes for which we may process personal data and the legal basis of the processing.</li>
                        <ul>
                            <li><strong>Operations&nbsp;</strong>- We may process your personal data for the purposes of operating our website and mobile apps, the processing and fulfilment of orders, providing our services, generating invoices, bills and other payment-related documentation. The legal basis for this processing is our legitimate interests and the performance of a contract between you and us.&nbsp;</li>
                            <li><strong>Relationships and communications</strong> - We may process contact data, account data, transaction data and/or communication data for the purposes of managing our relationships, communicating with you (excluding communicating for the purposes of direct marketing) by the means of communication you provided providing support services and complaint handling. The legal basis for this processing is our legitimate interests, namely communications with our website and mobile apps visitors, service users, individual customers and customer personnel, the maintenance of relationships, and the proper administration of our website and mobile apps.</li>
                            <li><strong>Direct marketing&nbsp;</strong>- We may process contact data, account data and/or transaction data for the purposes of creating, targeting and sending direct marketing communications by the means of communication you provided. The legal basis for this processing is consent and/or our legitimate interests.</li>
                            <li><strong>Research and analysis&nbsp;</strong>- We may process usage data and/or transaction data for the purposes of researching and analysing the use of our website and mobile apps and services, as well as researching and analysing other interactions with our business. The legal basis for this processing is consent and/or our legitimate interests.</li>
                            <li><strong>Record keeping&nbsp;</strong>- We may process your personal data for the purposes of creating and maintaining our databases, back-up copies of our databases and our business records generally . The legal basis for this processing is our legitimate interests.&nbsp;</li>
                            <li><strong>Security&nbsp;</strong>- We may process your personal data for the purposes of security and the prevention of fraud and other criminal activity . The legal basis for this processing is our legitimate interests.</li>
                            <li><strong>Legal compliance and vital interests</strong> - We may also process your personal data where such processing is necessary for compliance with a legal obligation to which we are subject or in order to protect your vital interests or the vital interests of another natural person.</li>
                        </ul>
                    </ol>
                    <ol></ol>
                </li>
                <li><strong>Providing your personal data to others</strong>
                    <ol>
                        <li>Your personal data held in our website and mobile apps database will be stored on the servers of our hosting services providers.&nbsp;</li>
                        <li>Financial transactions relating to our website and mobile apps and services may be handled by our payment services providers, primarily Stripe Payments. We will share transaction data with our payment services providers only to the extent necessary for the purposes of processing your payments, refunding such payments and dealing with complaints and queries relating to such payments and refunds.&nbsp;&nbsp;We will not store or collect your payment card details. Any card details you give will go straight to our third-party payment processor Stripe who use your personal information according to their privacy policy. You can find information about the payment service provider&rsquo;s privacy policy and practices at <a href="https://stripe.com/gb/privacy">https://stripe.com/gb/privacy</a>.</li>
                        <li>Some of partners on our website and apps may use cookies and web beacons. And also, they may collect some other data and personal information. Our partners are listed below. You can find information about their privacy policies via below links:&nbsp;</li>
                        <ul>
                            <li>Apple: <a href="https://www.apple.com/legal/privacy/data/en/app-store">https://www.apple.com/legal/privacy/data/en/app-store</a>&nbsp;</li>
                            <li>Google: <a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a>&nbsp;</li>
                            <li>Google DoubleClick DART Cookie: <a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a></li>
                            <li>Adjust&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;<a href="https://www.adjust.com/terms/privacy-policy/">https://www.adjust.com/terms/privacy-policy/</a></li>
                            <li>Facebook &nbsp;&nbsp;https://www.facebook.com/privacy/policy/</li>
                        </ul>
                    </ol>
                    <ol>
                        <li>In addition to the specific disclosures of personal data set out in this Section 5, we may disclose your personal data where such disclosure is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person. We may also disclose your personal data where such disclosure is necessary for the establishment, exercise, or defense of legal claims, whether in court proceedings or in an administrative or out-of-court procedure.</li>
                    </ol>
                    <ol></ol>
                </li>
                <li><strong>Retaining and deleting personal data</strong>
                    <ol>
                        <li>This Section 6 sets out our data retention policies and procedures, which are designed to help ensure that we comply with our legal obligations in relation to the retention and deletion of personal data.</li>
                        <li>Personal data that we process for any purpose or purposes shall not be kept for longer than is necessary for that purpose or those purposes.</li>
                        <li>Notwithstanding the other provisions of this Section 6, we may retain your personal data where such retention is necessary for compliance with a legal obligation to which we are subject, or in order to protect your vital interests or the vital interests of another natural person.</li>
                    </ol>
                </li>
                <li><strong>Your rights</strong>
                    <ol>
                        <li>In this Section 7, we have listed the rights that you have under data protection law.</li>
                        <li>Your principal rights under data protection law are:</li>
                    </ol>
                </li>
                <li><strong>the right to access</strong> - you can ask for copies of your personal data;</li>
                <li><strong>the right to rectification</strong> - you can ask us to rectify inaccurate personal data and to complete incomplete personal data;</li>
                <li><strong>the right to erasure</strong> - you can ask us to erase your personal data;</li>
                <li><strong>the right to restrict processing</strong> - you can ask us to restrict the processing of your personal data;</li>
                <li><strong>the right to object to processing</strong> - you can object to the processing of your personal data;</li>
                <li><strong>the right to data portability</strong> - you can ask that we transfer your personal data to another organisation or to you;</li>
                <li><strong>the right to complain to a supervisory authority</strong> - you can complain about our processing of your personal data; and</li>
                <li><strong>the right to withdraw consent</strong> - to the extent that the legal basis of our processing of your personal data is consent, you can withdraw that consent.<ol>
                    <li>These rights are subject to certain limitations and exceptions. You can learn more about the rights of data subjects by visiting&nbsp;&nbsp;<a href="https://edpb.europa.eu/our-work-tools/general-guidance/gdpr-guidelines-recommendations-best-practices_en">https://edpb.europa.eu/our-work-tools/general-guidance/gdpr-guidelines-recommendations-best-practices_en</a> and <a href="https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/">https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/</a> .</li>
                    <li>You may exercise any of your rights in relation to your personal data by written notice to us, using the contact details set out below.</li>
                </ol>
                </li>
                <li><strong>Amendments</strong>
                    <ol>
                        <li>We may update this policy from time to time by publishing a new version on our website and mobile apps.</li>
                        <li>You should check this page occasionally to ensure you are happy with any changes to this policy.</li>
                        <li>We may notify you of significant changes to this policy by notification or email (if provided).</li>
                    </ol>
                </li>
                <li><strong>Our details</strong>
                    <ol>
                        <li>This website and mobile apps is owned and operated by <em>Ocean Float Mobile Limited</em>.</li>
                        <li>We are registered in Cyprus under registration number <em>HE 425158</em>, and our registered office is at <em>Sofouli, 2, CHANTECLAIR BUILDING, Floor 8, Flat 802 1096, Nicosia, Cyprus</em>.</li>
                        <li>You can contact us:</li>
                    </ol>
                </li>
                <li>by post, to the postal address given above;</li>
            </ol>
            <p>(b) &nbsp; &nbsp;by email, using the email address published on our website and mobile apps or <a href="mailto:beintouch@oceanfloatmobile.com">beintouch@oceanfloatmobile.com</a> .</p>
        </S.PrivacyPolicyForm>
    );
}

export default PrivacyPolicy;
