import React, { useMemo, useState } from "react";
import { useNextStep } from "../../../hooks/useNextStep";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { useTranslation } from "react-i18next";
import userService, { getUserId } from "../../../services/user.service";
import { QuestionContainer } from "../../../components/QuestionContainer/QuestionContainer";
import { StyledGrammarTest as S } from "./GrammarTest.styles";
import { CommonStyles } from "../../../styles/common.styles";
import RippleButton from "../../../components/Button/RippleButton";
import { DividedToolbar } from "../../../components/Toolbar/DividedToolbar";

export const WordQuestions = (params: any) => {
    const handleContinue = useNextStep(params);
    const [width, height] = useWindowSize();
    const { i18n, t } = useTranslation();
    const [answer, setAnswer] = useState<string[]>([]);

    const props = useMemo<any>(
        () => ({
            onClick: (value: any) => {
                setTimeout(() => {
                    getUserId().then(userId => userService.pushAnswer(userId, {
                        question: params.pageId,
                        answer: value
                    }))
                    window.sessionStorage.setItem(params.pageId, Math.round(value.length / params.words.length * 100).toString())
                    handleContinue(params.pageId);
                }, 150)
            },
        }), [handleContinue, params.pageId]
    )

    const onClick = (word: string) => {
        if (answer.includes(word)) {
            setAnswer(answer.filter(item => item !== word))
        } else {
            setAnswer([...answer, word])
        }
    }

    return (
        <>
            <DividedToolbar index={ 1 } progressValue={ params.progressValue }
                            pagesNumberWithNavigation={ 3 }></DividedToolbar>
            <S.WordQuestionsContainer>
                <QuestionContainer>
                    <S.QuestionContainer>
                        <S.QuestionText>
                            { t('wordQuestions.question') }
                        </S.QuestionText>
                    </S.QuestionContainer>
                    <S.DescContainer>
                        <S.DescText>
                            { t('wordQuestions.desc') }
                        </S.DescText>
                    </S.DescContainer>
                </QuestionContainer>
                <S.AnswersContainer>
                    { params.words.map(function (word: any, i: any) {
                        return <>
                            <S.AnswerContainer selected={ answer.includes(word) } onClick={ () => onClick(word) }>
                                <S.AnswerText>{ word }</S.AnswerText>
                            </S.AnswerContainer>
                        </>
                    }) }
                </S.AnswersContainer>

                <S.ButtonContainer>
                    <CommonStyles.DynamicButtonContainer height={ height } maxHeight={ 850 }>
                        <RippleButton onClick={ () => {
                            props.onClick && props.onClick(answer);
                        } } disabled={ !answer }>
                            { t('common.actions.continue') }
                        </RippleButton>
                    </CommonStyles.DynamicButtonContainer>
                </S.ButtonContainer>
            </S.WordQuestionsContainer>
        </>

    );
}
