import React, { useMemo, useState } from 'react';
import { TPageProps } from "../TPageProps";
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import { Toolbar } from "../../../components/Toolbar/Toolbar";
import { useWindowSize } from "../../../hooks/useWindowSize";
import userService, { getUserId } from "../../../services/user.service";
import { QuestionContainer } from "../../../components/QuestionContainer/QuestionContainer";
import { CommonStyles } from "../../../styles/common.styles";
import { ActivityLevelStyles as S } from "./ActivityLevel.styles";
import RippleButton from "../../../components/Button/RippleButton";
import styled from "styled-components";
import ReactSlider from "react-slider";
import novice_icon1 from "../../../assets/languageLevel/indicator_suitable.svg";
import { DividedToolbar } from "../../../components/Toolbar/DividedToolbar";
import { useGetPageInfo } from "../../../hooks/useGetPageInfo";

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 6px;
`;

const StyledIndicator = styled.div`
  height: 16px;
  width: 16px;
  left: -5px;
  top: -5px;
  position: absolute;
  background: transparent url(${ novice_icon1 }) 0% 0% no-repeat padding-box;
  outline: 0;
  outline: none;
`;

const IndicatorContainer = styled.div`
`;

const Thumb = (props: any, state: any) => {

    return (
        <>
            <IndicatorContainer { ...props } index={ state.index }>
                <StyledIndicator></StyledIndicator>
            </IndicatorContainer>

        </>
    )
};

const StyledTrackEllipse = styled.div<{ index: number }>`
  top: 0px;
  bottom: 0;
  background: ${ props => (props.index === 1 ? '#E9F3FA' : '#49A8FB') };
  border-radius: 6px;
  opacity: 1;
`;

const Track = (props: any, state: any) => {

    return (
        <StyledTrackEllipse { ...props } index={ state.index }/>
    )
};

export const ActivityLevel: React.FC<TPageProps> = (params: TPageProps) => {
    const handleContinue = useNextStep(params);
    const [width, height] = useWindowSize();
    const { i18n, t } = useTranslation();
    const [from, setFrom] = useState(50);
    const [answer, setAnswer] = useState(t('activityLevel.a3'));
    const { currentCohort } = useGetPageInfo()

    const onChange = (e: any) => {
        setFrom(e[0]);
        if (e < 25) {
            setAnswer(t('activityLevel.a1'))
        } else if (25 < e && e < 50) {
            setAnswer(t('activityLevel.a2'))
        } else if (50 < e && e < 75) {
            setAnswer(t('activityLevel.a3'))
        } else if (75 < e && e < 100) {
            setAnswer(t('activityLevel.a4'))
        }
    }

    const props = useMemo<any>(
        () => ({
            onClick: (value: any) => {
                setTimeout(() => {
                    getUserId().then(userId => userService.pushAnswer(userId, {
                        question: params.pageId,
                        answer: value
                    }))
                    handleContinue(params.pageId);
                }, 100)
            },
        }), [handleContinue, params.pageId]
    )

    return (
        <>
            { currentCohort !== 'v3' && <Toolbar progressValue={ 7 } pagesNumberWithNavigation={ 7 }></Toolbar> }
            { currentCohort === 'v3' &&
                <DividedToolbar index={ 2 } progressValue={ 7 } pagesNumberWithNavigation={ 7 }></DividedToolbar>
            }
            <PageContainer>
                <QuestionContainer>
                    <S.QuestionText>
                        { t('activityLevel.question') }
                    </S.QuestionText>
                    <S.AnswerContainer>
                        <S.AnswerText>{ answer }</S.AnswerText>
                    </S.AnswerContainer>
                    <StyledSlider min={ 0 } max={ 100 }
                                  defaultValue={ [from] }
                                  renderTrack={ Track }
                                  renderThumb={ Thumb } onChange={ onChange }/>
                    <S.NewbieProContainer>
                        <S.NewbieContainer>
                            <S.NewbieText>{ t('activityLevel.newbie') }</S.NewbieText>
                        </S.NewbieContainer>
                        <S.ProContainer>
                            <S.NewbieText>{ t('activityLevel.pro') }</S.NewbieText>
                        </S.ProContainer>
                    </S.NewbieProContainer>
                    <S.ButtonContainer>
                        <CommonStyles.DynamicButtonContainer height={ height } maxHeight={ 700 }>
                            <RippleButton onClick={ () => {
                                props.onClick && props.onClick(`${ from }`);
                            } }>
                                { t('common.actions.continue') }
                            </RippleButton>
                        </CommonStyles.DynamicButtonContainer>
                    </S.ButtonContainer>
                </QuestionContainer>
            </PageContainer>
        </>

    );
}

