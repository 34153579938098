import React, { useState } from "react";
import { OptionStyles as O } from "./Styles";
import icons from "./images"
import { DynamicButton } from "./Button";
import { useWindowSize } from "../../hooks/useWindowSize";

export interface Option {
    icon: string,
    text: string
    popular?: boolean
}

export interface OptionsProps {
    options: Option[],
    onClick: any,
    maxHeight: number
}

export default function MultiSelectOptions(props: OptionsProps) {
    const [width, height] = useWindowSize();

    const [state, setState] = useState<{ answers: string[] }>({
        answers: []
    });

    const onClick = (answer: string) => {
        if (state.answers.includes(answer)) {
            setState({
                answers: state.answers.filter(function (a) {
                    return a !== answer
                })
            });
        } else {
            setState(prevState => ({
                answers: [...prevState.answers, answer]
            }))
        }
    }

    return (
        <O.OptionsContainer height={ height }>
            { props.options.map((prop) => (
                <O.OptionContainer selected={ state.answers.includes(prop.text) }
                                   onClick={ () => onClick(prop.text) }>
                    <O.IconContainer icon={ prop.icon }/>
                    <O.TextContainer>
                        { prop.text }
                    </O.TextContainer>

                    { prop.popular &&
                        <O.PopularContainer>
                            <O.PopularText>
                                POPULAR
                            </O.PopularText>
                        </O.PopularContainer>
                    }

                    { state.answers.includes(prop.text as never) &&
                        <O.SelectedContainer icon={ icons.selectedIcon }/>
                    }
                </O.OptionContainer>
            ))
            }

            <br/>
            <DynamicButton text={ "NEXT " } onclick={ () => props.onClick(state.answers) }
                           disabled={ state.answers.length < 1 } maxHeight={ props.maxHeight } height={ height }
                           width={ "427px" } maxWidth={ "95vw" }/>
        </O.OptionsContainer>
    )
}
