import React, { useMemo } from 'react';
import { useNextStep } from "src/hooks/useNextStep";
import { TOptionPropsBase } from "src/components/Option/Option";
import { useTranslation } from "react-i18next";
import { usePostDelay } from "src/hooks/usePostDelay";
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import userService, { getUserId } from "../../../services/user.service";
import { useGetPageInfo } from "../../../hooks/useGetPageInfo";
import { Styles as S } from './Statistics.styles'
import { CommonStyles } from "../../../styles/common.styles";
import { useWindowSize } from "../../../hooks/useWindowSize";
import RippleButton from "../../../components/Button/RippleButton";
import { useNavigate } from "react-router-dom";

export const Statistics: React.FC<any> = (params: any) => {
    const handleContinue = useNextStep(params)
    const { i18n, t } = useTranslation();
    const { isOptionsDisabled, handleChange, setIsOptionsDisabled } = usePostDelay()
    const { currentCohort } = useGetPageInfo()
    const [width, height] = useWindowSize();
    const navigate = useNavigate();

    const props = useMemo<TOptionPropsBase>(
        () => ({
            disabled: isOptionsDisabled,
            onUpdated: (value: string) => {
                setIsOptionsDisabled(true)
                getUserId().then(userId => userService.pushAnswer(userId, {
                    question: params.pageId,
                    answer: value
                }))

                handleChange(value, handleContinue)
            }
        }), [isOptionsDisabled, setIsOptionsDisabled, handleChange, handleContinue, params.pageId]
    )

    return (
        <>

            <PageContainer>
                <S.Container>
                    <S.MascotContainer/>
                    <S.Header>
                        <S.HeaderText>
                            { t('statistics.title') }
                        </S.HeaderText>
                    </S.Header>
                    <S.Body>
                        <S.InnerBody>
                            <S.Graph></S.Graph>
                            <S.DescContainer>
                                <S.DescText>
                                    { t('statistics.desc') }
                                </S.DescText>
                            </S.DescContainer>
                        </S.InnerBody>

                        <CommonStyles.DynamicButtonContainer height={ height } maxHeight={ 850 }>
                            <RippleButton onClick={ () => navigate("/v3/discount") }>
                                { t('statistics.gotit') }
                            </RippleButton>
                        </CommonStyles.DynamicButtonContainer>
                    </S.Body>
                </S.Container>
            </PageContainer>
        </>
    );
}

