import React, { FormEvent, useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import { configId } from "../../config";
import { Styles as S } from "./Cancel.styles";
import { CommonStyles } from "../../styles/common.styles";
import ProgressButton from "../../components/Button/ProgressButton";
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import stripeService from "../../services/stripe.service";
import { firebaseService } from "../../services/firebase.service";
import { captureException } from "../../services/sentry.service";

export const Cancel = (props: any) => {
    const { i18n, t } = useTranslation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isDirect, setIsDirect] = useState(false);
    const [canceled, setCancelled] = useState(false);
    const [done, setDone] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {

        const token = new URLSearchParams(window.location.search).get("token");
        const subscriptionId = new URLSearchParams(window.location.search).get("subscriptionId");

        if (token && subscriptionId) {
            setIsDirect(true);
            stripeService.cancelSubscription({
                configId: configId,
                idToken: token,
                subscriptionId: subscriptionId
            }).then(_ => setCancelled(true))
                .catch(err => setIsDirect(false))
        }
    }, []);


    const handleSubmit = async (e: FormEvent) => {
        setProcessing(true);
        e.preventDefault();

        firebaseService.signInWithEmailAndPassword({ email: email, password: password })
            .then(user => Promise.all([user.user.getIdToken(), firebaseService.getUser({ uid: user.user.uid })]))
            .then(([idToken, dbUser]) => stripeService.cancelSubscription({
                configId: configId,
                idToken: idToken,
                subscriptionId: dbUser.subscriptionId
            })).then(plans => {
            if (plans.error) {
                toast(plans.error)
            } else {
                if (plans == true) {
                    setDone(true)
                    toast("Cancelled")
                } else {
                    toast("Error")
                }
            }
            setProcessing(false)
        }).catch(err => captureException(err));

    }
    useEffect(() => {
        setEmail(searchParams.get("email") || "")
    }, []);

    if (canceled) {
        return <> Successfully Cancelled</>
    }

    return (
        <>
            { !isDirect &&
                <S.CancelForm onSubmit={ handleSubmit }>
                    <ToastContainer/>

                    <S.CancelInput type="email" placeholder="example@gmail.com" value={ email }
                                   onChange={ ((e) => setEmail(e.target.value)) }>
                    </S.CancelInput>

                    <S.CancelInput type="password" placeholder="password" value={ password }
                                   onChange={ ((e) => setPassword(e.target.value)) }>
                    </S.CancelInput>

                    <CommonStyles.ButtonContainer>
                        <CommonStyles.Separator/>
                        <ProgressButton disabled={ !email || !password || done } type="submit" onSubmit={ handleSubmit }
                                        loading={ processing ? 1 : 0 }>
                            { t('common.actions.cancel') }
                        </ProgressButton>
                    </CommonStyles.ButtonContainer>

                </S.CancelForm>
            }
        </>

    );
}

export default Cancel;
