import styled, { css } from "styled-components";

export const CommonStyles = {
    ButtonContainer: styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `,
    FixedButtonContainer: styled.div`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: fixed;
      bottom: 15px;
      width: 100%;
      z-index: 3000;
      right: 0;
      left: 0;
    `,
    DynamicButtonContainer: styled.div<{ height: number, maxHeight: number }>`
      ${ props => props.height > props.maxHeight && css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      ` }

      ${ props => props.height < props.maxHeight + 1 && css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: fixed;
        bottom: 15px;
        width: 100%;
        z-index: 3000;
        right: 0;
        left: 0;
      ` }
    `,
    Separator: styled.div`
      height: 20px;
      width: 100%;
    `
}
