import React from "react";
import { TPageProps } from "../TPageProps";
import { PageId } from "../../Pages";
import { WordQuestions } from "./WordQuestions";

export const WordQuestions1: React.FC<TPageProps> = (params: TPageProps) => {
    const words = [
        "Go", " Wallet", " Happy", " Eat",
        " Laptop", " Tired", " Sleep", " Coffee",
        " Beautiful", " Work", " Taxi", " Busy",
        " Study", " Sunglasses", " Cold", " Cook",
        " Salad", " Hot", " Watch", " Book",
        " Sad", " Listen", " Ticket", " Excited",
        " Talk", " Hospital", " Funny", " Read"
    ]
    return (
        <WordQuestions pageId={ PageId.WORDQUESTIONS1 } nextPagePath={ "v3/wordQuestions2" } progressValue={ 1 }
                       words={ words }/>
    );
}
