import styled from "styled-components";
import buttonBackground from 'src/assets/languageLevel/btn_done.svg'
import paymentSuccessIcon from "../../../assets/languageLevel/ic_payment_successful.svg";


export const StyledOption = {
    ProceedToPlan: styled.div`
      background: transparent url(${ buttonBackground }) 50% 50% no-repeat padding-box;
      opacity: 1;
      width: 100%;
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
    `,
    ProceedToPlanText: styled.p`
      text-align: center;
      font: normal normal bold 18px/24px Nunito;
      letter-spacing: 0;
      color: #FFFFFF;
      text-transform: uppercase;
      opacity: 1;
    `,
    PaymentSuccessTextContainer: styled.div`
      text-align: center;
      margin-bottom: 25px;
    `,
    PaymentSuccessText: styled.p`
      text-align: center;
      font: normal normal 800 20px/24px NunitoExtraBold;
      letter-spacing: 0;
      color: #3A3A3A;
      opacity: 1;
    `,
    PaymentSuccessModal: styled.div`
      text-align: center;
      max-width: 393px;
      background: white;
      width: 90%;
      height: 300px;
      color: white;
      z-index: 10;
      border-radius: 16px;
      box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `,
    PaymentSuccessModalHeader: styled.div`
      background-color: rgba(0, 0, 0, 0.2);
      width: 100vw;
      height: 100vh;
      z-index: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
    `,
    PaymentSuccessIconContainer: styled.div`
      background: transparent url(${ paymentSuccessIcon }) 50% 50% no-repeat padding-box;
      opacity: 1;
      margin-top: 30px;
      margin-bottom: 32px;
      width: 100%;
      height: 82px;
    `
}
