import React from "react";
import { StyledOption as S } from "./PaymentSuccessModal.styles";
import { useTranslation } from "react-i18next";

export const PaymentSuccessModal = (props: any) => {
    const { i18n, t } = useTranslation();
    return (
        <>
            <S.PaymentSuccessModalHeader onClick={ () => props.setIsOpen(false) }></S.PaymentSuccessModalHeader>
            <S.PaymentSuccessModal>
                <S.PaymentSuccessIconContainer/>
                <S.PaymentSuccessTextContainer>
                    <S.PaymentSuccessText>
                        { t('payment.success.text') }<br/>
                        { t('payment.success.successful') }
                    </S.PaymentSuccessText>
                </S.PaymentSuccessTextContainer>
                <S.ProceedToPlan onClick={ props.onSuccess }>
                    <S.ProceedToPlanText>
                        { t('payment.success.proceed') }
                    </S.ProceedToPlanText>
                </S.ProceedToPlan>
            </S.PaymentSuccessModal>
        </>
    );
};
