import React from 'react';
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import icons from "./images"

import userService, { getUserId } from "../../services/user.service";
import { HeaderStyles as H, PageStyles as P, } from "./Styles";
import Header from "./Header";
import Options from "./Options";

export const DailyTime: any = (params: any) => {
    const handleContinue = useNextStep(params)
    const { i18n, t } = useTranslation();


    const onClick = (value: string) => {
        getUserId().then(userId => userService.pushAnswer(userId, {
            question: params.pageId,
            answer: value
        }))

        handleContinue(value)
    }

    const langArray = [
        {
            "icon": icons.min1015Icon,
            "text": "10-15 min"
        },
        {
            "icon": icons.min2040Icon,
            "text": "20-40 min"
        },
        {
            "icon": icons.hour1Icon,
            "text": "1 hour"
        },
        {
            "icon": icons.hourMoreThan1Icon,
            "text": ">1 hour"
        }
    ]
    const targetLanguage = window.sessionStorage.getItem("targetLanguage")

    return (
        <P.PageContainer>
            <P.Background/>
            <H.HeaderContainer>
                <H.IconContainer icon={ icons.logo }/>
            </H.HeaderContainer>
            <Header title={ `How much time are you ready to spend daily on studying ${ targetLanguage }?` }
                    toolbarPercentage={ "85%" } toolbarText={ "6/7" }/>
            <P.QuestionContainer>
                <Options options={ langArray } onClick={ onClick } maxHeight={ 600 }/>
            </P.QuestionContainer>
        </P.PageContainer>
    );
}

