import React, { useMemo } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { getPathFromPageId } from "src/helpers/getPathFromPageId";
import { getCohortFromPath } from "../../pages/Pages";

export function ProtectedRoute({ isAvailable, children }: { isAvailable: boolean, children: any }) {
    const { pathname } = useLocation()

    const pathToRedirect = useMemo(
        () =>
            getPathFromPageId({
                cohort: getCohortFromPath(pathname),
                pageId: pathname
            }),
        [pathname],
    )

    return isAvailable ? children : <Navigate to={ `/${ pathToRedirect }` }/>
}
