import React from 'react';
import { StyledToolbar as S } from './Toolbar.styles'
import ProgressBar from "../ProgressBar/ProgressBar";
import { HeaderContainer } from "./HeaderContainer";

export const Toolbar = (params: any) => {

    return (
        <>
            <HeaderContainer { ...params }></HeaderContainer>
            <S.ProgressContainer hidden={ params.progressBarHidden }>
                <ProgressBar completed={ (params.progressValue / params.pagesNumberWithNavigation) * 100 }/>
            </S.ProgressContainer>
        </>
    );
}

