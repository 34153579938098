import React, { useState } from 'react';
import { TPageProps } from "../TPageProps";
import { useNextStep } from "src/hooks/useNextStep";
import { QuestionTitle } from "src/components/QuestionTitle/QuestionTitle";
import { useTranslation } from "react-i18next";
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import { QuestionContainer } from "../../../components/QuestionContainer/QuestionContainer";
import userService, { getUserId } from "../../../services/user.service";
import { Toolbar } from "../../../components/Toolbar/Toolbar";
import { Select } from "../../../components/Select/Select";
import { StyledInputQuestionContainer as S } from "../../../components/QuestionContainer/InputQuestionContainer.styles";
import { CommonStyles } from "../../../styles/common.styles";
import RippleButton from "../../../components/Button/RippleButton";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { DividedToolbar } from "../../../components/Toolbar/DividedToolbar";
import { useGetPageInfo } from "../../../hooks/useGetPageInfo";

export const NativeLanguage: React.FC<TPageProps> = (params: TPageProps) => {
    const handleContinue = useNextStep(params);
    const [selected, setSelected] = useState(navigator.language);
    const { currentCohort } = useGetPageInfo()

    const { i18n, t } = useTranslation();
    const [width, height] = useWindowSize();

    const options = [{ "code": "af", "language": "Afrikaans" },
        { "code": "sq", "language": "Albanian - shqip" },
        { "code": "am", "language": "Amharic - አማርኛ" },
        { "code": "ar", "language": "Arabic - العربية" },
        { "code": "an", "language": "Aragonese - aragonés" },
        { "code": "hy", "language": "Armenian - հայերեն" },
        { "code": "ast", "language": "Asturian - asturianu" },
        { "code": "az", "language": "Azerbaijani - azərbaycan dili" },
        { "code": "eu", "language": "Basque - euskara" },
        { "code": "be", "language": "Belarusian - беларуская" },
        { "code": "bn", "language": "Bengali - বাংলা" },
        { "code": "bs", "language": "Bosnian - bosanski" },
        { "code": "br", "language": "Breton - brezhoneg" },
        { "code": "bg", "language": "Bulgarian - български" },
        { "code": "ca", "language": "Catalan - català" },
        { "code": "ckb", "language": "Central Kurdish - کوردی (دەستنوسی عەرەبی)" },
        { "code": "zh", "language": "Chinese - 中文" },
        { "code": "zh-HK", "language": "Chinese (Hong Kong) - 中文（香港）" },
        { "code": "zh-CN", "language": "Chinese (Simplified) - 中文（简体）" },
        { "code": "zh-TW", "language": "Chinese (Traditional) - 中文（繁體）" },
        { "code": "co", "language": "Corsican" },
        { "code": "hr", "language": "Croatian - hrvatski" },
        { "code": "cs", "language": "Czech - čeština" },
        { "code": "da", "language": "Danish - dansk" },
        { "code": "nl", "language": "Dutch - Nederlands" },
        { "code": "en", "language": "English" },
        { "code": "en-AU", "language": "English (Australia)" },
        { "code": "en-CA", "language": "English (Canada)" },
        { "code": "en-IN", "language": "English (India)" },
        { "code": "en-NZ", "language": "English (New Zealand)" },
        { "code": "en-ZA", "language": "English (South Africa)" },
        { "code": "en-GB", "language": "English (United Kingdom)" },
        { "code": "en-US", "language": "English (United States)" },
        { "code": "eo", "language": "Esperanto - esperanto" },
        { "code": "et", "language": "Estonian - eesti" },
        { "code": "fo", "language": "Faroese - føroyskt" },
        { "code": "fil", "language": "Filipino" },
        { "code": "fi", "language": "Finnish - suomi" },
        { "code": "fr", "language": "French - français" },
        { "code": "fr-CA", "language": "French (Canada) - français (Canada)" },
        { "code": "fr-FR", "language": "French (France) - français (France)" },
        { "code": "fr-CH", "language": "French (Switzerland) - français (Suisse)" },
        { "code": "gl", "language": "Galician - galego" },
        { "code": "ka", "language": "Georgian - ქართული" },
        { "code": "de", "language": "German - Deutsch" },
        { "code": "de-AT", "language": "German (Austria) - Deutsch (Österreich)" },
        { "code": "de-DE", "language": "German (Germany) - Deutsch (Deutschland)" },
        { "code": "de-LI", "language": "German (Liechtenstein) - Deutsch (Liechtenstein)" },
        { "code": "de-CH", "language": "German (Switzerland) - Deutsch (Schweiz)" },
        { "code": "el", "language": "Greek - Ελληνικά" },
        { "code": "gn", "language": "Guarani" },
        { "code": "gu", "language": "Gujarati - ગુજરાતી" },
        { "code": "ha", "language": "Hausa" },
        { "code": "haw", "language": "Hawaiian - ʻŌlelo Hawaiʻi" },
        { "code": "he", "language": "Hebrew - עברית" },
        { "code": "hi", "language": "Hindi - हिन्दी" },
        { "code": "hu", "language": "Hungarian - magyar" },
        { "code": "is", "language": "Icelandic - íslenska" },
        { "code": "id", "language": "Indonesian - Indonesia" },
        { "code": "ia", "language": "Interlingua" },
        { "code": "ga", "language": "Irish - Gaeilge" },
        { "code": "it", "language": "Italian - italiano" },
        { "code": "it-IT", "language": "Italian (Italy) - italiano (Italia)" },
        { "code": "it-CH", "language": "Italian (Switzerland) - italiano (Svizzera)" },
        { "code": "ja", "language": "Japanese - 日本語" },
        { "code": "kn", "language": "Kannada - ಕನ್ನಡ" },
        { "code": "kk", "language": "Kazakh - қазақ тілі" },
        { "code": "km", "language": "Khmer - ខ្មែរ" },
        { "code": "ko", "language": "Korean - 한국어" },
        { "code": "ku", "language": "Kurdish - Kurdî" },
        { "code": "ky", "language": "Kyrgyz - кыргызча" },
        { "code": "lo", "language": "Lao - ລາວ" },
        { "code": "la", "language": "Latin" },
        { "code": "lv", "language": "Latvian - latviešu" },
        { "code": "ln", "language": "Lingala - lingála" },
        { "code": "lt", "language": "Lithuanian - lietuvių" },
        { "code": "mk", "language": "Macedonian - македонски" },
        { "code": "ms", "language": "Malay - Bahasa Melayu" },
        { "code": "ml", "language": "Malayalam - മലയാളം" },
        { "code": "mt", "language": "Maltese - Malti" },
        { "code": "mr", "language": "Marathi - मराठी" },
        { "code": "mn", "language": "Mongolian - монгол" },
        { "code": "ne", "language": "Nepali - नेपाली" },
        { "code": "no", "language": "Norwegian - norsk" },
        { "code": "nb", "language": "Norwegian Bokmål - norsk bokmål" },
        { "code": "nn", "language": "Norwegian Nynorsk - nynorsk" },
        { "code": "oc", "language": "Occitan" },
        { "code": "or", "language": "Oriya - ଓଡ଼ିଆ" },
        { "code": "om", "language": "Oromo - Oromoo" },
        { "code": "ps", "language": "Pashto - پښتو" },
        { "code": "fa", "language": "Persian - فارسی" },
        { "code": "pl", "language": "Polish - polski" },
        { "code": "pt", "language": "Portuguese - português" },
        { "code": "pt-BR", "language": "Portuguese (Brazil) - português (Brasil)" },
        { "code": "pt-PT", "language": "Portuguese (Portugal) - português (Portugal)" },
        { "code": "pa", "language": "Punjabi - ਪੰਜਾਬੀ" },
        { "code": "qu", "language": "Quechua" },
        { "code": "ro", "language": "Romanian - română" },
        { "code": "mo", "language": "Romanian (Moldova) - română (Moldova)" },
        { "code": "rm", "language": "Romansh - rumantsch" },
        { "code": "ru", "language": "Russian - русский" },
        { "code": "gd", "language": "Scottish Gaelic" },
        { "code": "sr", "language": "Serbian - српски" },
        { "code": "sh", "language": "Serbo-Croatian - Srpskohrvatski" },
        { "code": "sn", "language": "Shona - chiShona" },
        { "code": "sd", "language": "Sindhi" },
        { "code": "si", "language": "Sinhala - සිංහල" },
        { "code": "sk", "language": "Slovak - slovenčina" },
        { "code": "sl", "language": "Slovenian - slovenščina" },
        { "code": "so", "language": "Somali - Soomaali" },
        { "code": "st", "language": "Southern Sotho" },
        { "code": "es", "language": "Spanish - español" },
        { "code": "es-AR", "language": "Spanish (Argentina) - español (Argentina)" },
        { "code": "es-419", "language": "Spanish (Latin America) - español (Latinoamérica)" },
        { "code": "es-MX", "language": "Spanish (Mexico) - español (México)" },
        { "code": "es-ES", "language": "Spanish (Spain) - español (España)" },
        { "code": "es-US", "language": "Spanish (United States) - español (Estados Unidos)" },
        { "code": "su", "language": "Sundanese" },
        { "code": "sw", "language": "Swahili - Kiswahili" },
        { "code": "sv", "language": "Swedish - svenska" },
        { "code": "tg", "language": "Tajik - тоҷикӣ" },
        { "code": "ta", "language": "Tamil - தமிழ்" },
        { "code": "tt", "language": "Tatar" },
        { "code": "te", "language": "Telugu - తెలుగు" },
        { "code": "th", "language": "Thai - ไทย" },
        { "code": "ti", "language": "Tigrinya - ትግርኛ" },
        { "code": "to", "language": "Tongan - lea fakatonga" },
        { "code": "tr", "language": "Turkish - Türkçe" },
        { "code": "tk", "language": "Turkmen" },
        { "code": "tw", "language": "Twi" },
        { "code": "uk", "language": "Ukrainian - українська" },
        { "code": "ur", "language": "Urdu - اردو" },
        { "code": "ug", "language": "Uyghur" },
        { "code": "uz", "language": "Uzbek - o‘zbek" },
        { "code": "vi", "language": "Vietnamese - Tiếng Việt" },
        { "code": "wa", "language": "Walloon - wa" },
        { "code": "cy", "language": "Welsh - Cymraeg" },
        { "code": "fy", "language": "Western Frisian" },
        { "code": "xh", "language": "Xhosa" },
        { "code": "yi", "language": "Yiddish" },
        { "code": "yo", "language": "Yoruba - Èdè Yorùbá" },
        { "code": "zu", "language": "Zulu - isiZulu" }
    ]

    const onClick = (e: any) => {
        getUserId().then(userId => userService.pushAnswer(userId, {
            question: params.pageId,
            answer: selected
        }))

        handleContinue(selected);
    }

    const onSelected = (e: any) => {
        setSelected(e.target.value)
    }


    return (
        <>
            { currentCohort !== 'v3' && <Toolbar progressValue={ 3 } pagesNumberWithNavigation={ 6 }></Toolbar> }
            { currentCohort === 'v3' &&
                <DividedToolbar index={ 0 } progressValue={ 1 } pagesNumberWithNavigation={ 6 }></DividedToolbar>
            }

            <PageContainer>
                <QuestionContainer>
                    <QuestionTitle>{ t('nativeLanguage.title') }</QuestionTitle>
                    <Select options={ options }
                            selected={ selected }
                            onChange={ onSelected }
                    ></Select>
                    <S.ButtonContainer>
                        <CommonStyles.DynamicButtonContainer height={ height } maxHeight={ 700 }>
                            <RippleButton onClick={ onClick }>
                                { t('common.actions.continue') }
                            </RippleButton>
                        </CommonStyles.DynamicButtonContainer>
                    </S.ButtonContainer>
                </QuestionContainer>
            </PageContainer>
        </>
    );
}

