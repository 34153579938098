import React from 'react';
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import icons from "./images"
import { InformativeStyles as I, PageStyles as P } from "./Styles";

import userService, { getUserId } from "../../services/user.service";
import { Informative, InformativeProps } from "./Informative";

export const BrainHealth: any = (params: any) => {
    const handleContinue = useNextStep(params)
    const { i18n, t } = useTranslation();

    const onClick = (value: string) => {
        getUserId().then(userId => userService.pushAnswer(userId, {
            question: params.pageId,
            answer: value
        }))

        handleContinue(value)
    }

    const informativeProps: InformativeProps = {
        title: "Language learning gives your brain a longer life!",
        icon: icons.bookBrain,
        desc:
            <I.InformativeDescText>
                If you’re a beginner, you can even
                <I.InformativeDescTextBold>
                    &nbsp;double your income&nbsp;
                </I.InformativeDescTextBold>
                by learning
            </I.InformativeDescText>
        ,
        background: "#BEC8FF",
        buttonText: "NEXT",
        onClick: () => onClick("brainHealth"),
        maxHeight: 800
    }

    return (
        <>
            <P.Background/>
            <Informative { ...informativeProps } />
        </>
    );
}

