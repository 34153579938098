import React, { useEffect, useState } from 'react';
import { TPageProps } from "../TPageProps";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useNextStep } from "../../../hooks/useNextStep";
import "./styles.css";
import { Styled as S } from './Analysis.styles'
import { useTranslation } from "react-i18next";
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import { HeaderContainer } from "../../../components/Toolbar/HeaderContainer";


export const AnalysisV2: React.FC<TPageProps> = (params: TPageProps) => {
    const { i18n, t } = useTranslation();

    const handleContinue = useNextStep(params)

    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            if (percentage < 100) {
                setPercentage(percentage + 1);
            }

            if (percentage >= 100) {
                handleContinue(params.pageId);
            }
        }, 100);
    }, [percentage]);


    return (
        <>
            <HeaderContainer progressHidden={ true }/>
            <PageContainer>
                <React.Fragment>
                    <S.AnalysisContainer>
                        <S.ProgressBarContainer>
                            <svg style={ { height: 0, width: 0 } }>
                                <defs>
                                    <linearGradient
                                        id="progress"
                                        gradientTransform='rotate(90)'>
                                        <stop offset="25%" stopColor="#77FF7D"/>
                                        <stop offset="100%" stopColor="#66E18D"/>
                                    </linearGradient>
                                </defs>
                            </svg>

                            <CircularProgressbar value={ percentage } text={ `${ percentage }%` } styles={ buildStyles({
                                // Rotation of path and trail, in number of turns (0-1)
                                rotation: 0.50,

                                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                                strokeLinecap: 'round',

                                // Text size
                                textColor: '#3A3A3A',
                                textSize: '25px',

                                // How long animation takes to go from one percentage to another, in seconds
                                pathTransitionDuration: 0.5,

                                // Can specify path transition in more detail, or remove it entirely
                                // pathTransition: 'none',

                                // Colors

                                trailColor: '#e5f1fe',
                                backgroundColor: '#fff',
                            }) }/>
                        </S.ProgressBarContainer>
                        <S.ListContainer>
                            <ul>
                                <S.Li>
                                    <S.Icon visible={ percentage > 15 }/>
                                    <S.Desc finished={ percentage > 15 }>
                                        { t('analysisv2.title1') }
                                    </S.Desc>
                                </S.Li>
                                <S.Li>
                                    <S.Icon visible={ percentage > 30 }/>
                                    <S.Desc finished={ percentage > 30 }>
                                        { t('analysisv2.title2') }
                                    </S.Desc>
                                </S.Li>
                                <S.Li>
                                    <S.Icon visible={ percentage > 45 }/>
                                    <S.Desc finished={ percentage > 45 }>
                                        { t('analysisv2.title3') }
                                    </S.Desc>
                                </S.Li>
                                <S.Li>
                                    <S.Icon visible={ percentage > 60 }/>
                                    <S.Desc finished={ percentage > 60 }>
                                        { t('analysisv2.title4') }
                                    </S.Desc>
                                </S.Li>
                                <S.Li>
                                    <S.Icon visible={ percentage > 75 }/>
                                    <S.Desc finished={ percentage > 75 }>
                                        { t('analysisv2.title5') }
                                    </S.Desc>
                                </S.Li>
                                <S.Li>
                                    <S.Icon visible={ percentage > 90 }/>
                                    <S.Desc finished={ percentage > 90 }>
                                        { t('analysisv2.title6') }
                                    </S.Desc>
                                </S.Li>
                            </ul>
                        </S.ListContainer>
                    </S.AnalysisContainer>
                </React.Fragment>
            </PageContainer>
        </>
    );
}

