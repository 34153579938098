import styled from 'styled-components'
import mascotStatistics from 'src/assets/plans/mascot_statistic.svg'
import statisticsDesc from 'src/assets/plans/statistic_desc.png'

export const Styles = {
    Container: styled.div`
      width: 393px;
      max-width: 393px;
      margin: auto;
    `,
    MascotContainer: styled.div`
      background-image: url(${ mascotStatistics });
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: 150px 200px;
      height: 200px;
      width: 100%;
    `,
    Header: styled.div`
    `,
    HeaderText: styled.p`
      text-align: center;
      font: normal normal bold 24px/30px NunitoExtraBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,
    Body: styled.div`
      background: #F3FAFF 0% 0% no-repeat padding-box;
      opacity: 1;
      width: 393px;
      height: 700px;
    `,
    InnerBody: styled.div`
      width: 100%;
      height: 420px;
    `,
    Graph: styled.div`
      margin-top: 30px;
      background-image: url(${ statisticsDesc });
      background-position: 50% 50%;
      background-size: 343px 200px;
      background-repeat: no-repeat;
      width: 100%;
      height: 230px;

    `,
    DescContainer: styled.div`
      margin-top: 25px;
      margin-right: 45px;
      margin-left: 45px;
    `,
    DescText: styled.p`
      font: normal normal normal 14px/19px NunitoRegular;
      letter-spacing: 0px;
      color: #8CA6C5;
      opacity: 1;
    `

}
