const ProgressBar = (props: any) => {
    const { completed } = props;

    const containerStyles = {
        width: '90%',
        backgroundColor: "#E9F3FA",
        borderRadius: 10,
        marginBottom: 20,
        height: "6px",
        maxWidth: '393px',
        opacity: 1,
        background: "#E9F3FA 0% 0% no-repeat padding-box"
    }

    const fillerStyles = {
        height: '100%',
        width: `${completed}%`,
        borderRadius: '4px',
        opacity: 1,
        background: "transparent linear-gradient(90deg, #20CAFA 0%, #2CF0FE 100%) 0% 0% no-repeat padding-box"
    }

    const labelStyles = {
    }

    return (
        <div style={containerStyles}>
            <div style={fillerStyles}>
                <span style={labelStyles}></span>
            </div>
        </div>
    );
};

export default ProgressBar;
