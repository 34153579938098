import styled, { css } from 'styled-components'
import bgGraph from 'src/assets/languageLevel/img_well_done.svg'
import bgGraphV3 from 'src/assets/languageLevel/img_well_done_v3.svg'

export const StyledWellDone = {
    WellDoneContainer: styled.div`
        
    `,
    TitleContainer: styled.div`
      margin: 30px 15px 50px;
    `,
    TitleText: styled.p`
      text-align: center;
      font: normal normal 800 24px/30px NunitoExtraBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,
    DescContainer: styled.div`
    `,
    DescText: styled.p`
      text-align: center;
      font: normal normal normal 16px/20px NunitoRegular;
      letter-spacing: 0px;
      color: #8CA6C5;
      opacity: 1;
      margin-top: 8%;
      margin-bottom: 8%;
    `,
    BodyContainer: styled.div`
      background: #F3FAFF 0% 0% no-repeat padding-box;
      opacity: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 500px;
    `,
    ImageContainer: styled.div<{ cohort: string }>`
      ${ props => props.cohort === 'v3' && css`
        margin-top: 20px;
        background: transparent url(${ bgGraphV3 }) 0% 0% no-repeat padding-box;
        width: 270px;
        height: 300px;
      ` }

      ${ props => props.cohort !== 'v3' && css`
        background: transparent url(${ bgGraph }) 0% 0% no-repeat padding-box;
        width: 202px;
        height: 213px;
      ` }

      opacity: 1;
    `,
    ButtonContainer: styled.div<{ cohort: string }>`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      ${ props => props.cohort === 'v3' && css`
        margin-top: 20px;
      ` }

      ${ props => props.cohort !== 'v3' && css`
        margin-top: 50px;
      ` }
    `,
    PercentageContainer: styled.div`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -105px;
      width: 250px;
      height: 30px;
      font: normal normal normal 16px/22px NunitoBold;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;

    `
}
