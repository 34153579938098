import { isNotTest } from "../config";

export const initializeMetaPixel = (facebookPixelId: string) => {
    if (!window.fbq || !facebookPixelId) return
    fbq('init', facebookPixelId)
    fbq('track', 'PageView')
}


export const fbq = (...params: any) => {
    isNotTest() && window.fbq(params);
}
