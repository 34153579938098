import React, { FormEvent, useContext, useState } from "react";
import { CreateUserDto, errorMessage, firebaseService } from "../../services/firebase.service";
import { TPageProps } from "../../pages/questions/TPageProps";
import { useNextStep } from "../../hooks/useNextStep";
import { configId } from "../../config";
import { Styles as S } from "./RegisterForm.styles";
import { useTranslation } from "react-i18next";
import { PageContainer } from "../PageContainer/PageContainer";
import { AppContext } from "../../context/answers";
import ProgressButton from "../Button/ProgressButton";
import { CommonStyles } from "../../styles/common.styles";
import { useWindowSize } from "../../hooks/useWindowSize";
import userService, { getUserId } from "../../services/user.service";
import { fbq } from "../../services/metapixel.service";
import { captureException } from "../../services/sentry.service";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { HeaderContainer } from "../Toolbar/HeaderContainer";
import ProgressSteps from "../Toolbar/ProgressSteps";
import { useGetPageInfo } from "../../hooks/useGetPageInfo";

export default function RegisterForm(params: TPageProps) {
    const { i18n, t } = useTranslation();
    let e = window.sessionStorage.getItem("email")
    if (!e) {
        e = "";
    }
    const [email, setEmail] = useState(e);
    const [password, setPassword] = useState("");
    const [completed, setCompleted] = useState(false);
    const handleContinue = useNextStep(params)
    const { state, dispatch } = useContext(AppContext);
    const [loading, setLoading] = useState(false)
    const [width, height] = useWindowSize();
    const { currentCohort } = useGetPageInfo()


    const handleSubmit = async (e: FormEvent) => {
        setLoading(true)

        firebaseService.logFirebaseEvent("register_save_button_clicked")
        fbq('trackCustom', 'register_save_button_clicked')

        e.preventDefault();

        const request: CreateUserDto = {
            email: email,
            password: password,
            subscriptionId: window.sessionStorage.getItem("subscriptionId"),
            customerId: window.sessionStorage.getItem("customerId"),
            answers: state.answers
        }

        firebaseService.createUserWithEmailAndPassword(request)
            .then(_ => getUserId())
            .then(userId => userService.updateUser({
                _id: userId,
                configId: configId,
                email: email
            }))
            .then(_ => handleContinue(email))
            .catch(err => {
                toast(errorMessage(err.code));
                captureException(err);
                setLoading(false);
            });
    }

    const onPasswordChange = (e: any) => {
        setPassword(e.target.value);
        password.length >= 7 ? setCompleted(true) : setCompleted(false);
    }

    return (
        <PageContainer>
            <ToastContainer/>
            <HeaderContainer progressHidden={ true }/>
            <ProgressSteps activeStep={ 4 }/>
            <S.RegisterHeaderContainer>
                <S.RegisterHeaderText>
                    { t('register.title') }
                </S.RegisterHeaderText>
            </S.RegisterHeaderContainer>
            <S.RegisterContainer>
                <S.RegisterForm onSubmit={ handleSubmit }>
                    <S.EmailContainer>
                        <S.EmailText>
                            { t('register.email') }
                        </S.EmailText>
                    </S.EmailContainer>
                    <S.EmailInputContainer>
                        <S.EmailInput type="email" className="form-control mt-1" placeholder="john_doe@gmail.com"
                                      value={ email } disabled={ true }>
                        </S.EmailInput>
                    </S.EmailInputContainer>
                    <S.PasswordTextContainer>
                        <S.PasswordText>
                            { t('register.password') }
                        </S.PasswordText>
                    </S.PasswordTextContainer>
                    <S.PasswordInputContainer>
                        <S.PasswordInput type="password"
                                         className="form-control mt-1"
                                         placeholder="password" value={ password }
                                         onChange={ onPasswordChange }>

                        </S.PasswordInput>
                    </S.PasswordInputContainer>
                    <S.PrivacyPolicyContainer>
                        <S.PrivacyPolicyText>
                            { t('register.policy') }
                        </S.PrivacyPolicyText>
                    </S.PrivacyPolicyContainer>
                    <CommonStyles.DynamicButtonContainer height={ height } maxHeight={ 650 }>
                        <ProgressButton disabled={ !completed } type="submit" loading={ loading ? 1 : 0 }>
                            { t('common.actions.continue') }
                        </ProgressButton>
                    </CommonStyles.DynamicButtonContainer>
                </S.RegisterForm>
            </S.RegisterContainer>
        </PageContainer>

    )
}
