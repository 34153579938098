import React, { useState } from 'react';
import { QuestionContainer } from "./QuestionContainer";
import { useTranslation } from "react-i18next";
import { StyledInputQuestionContainer as S } from "./InputQuestionContainer.styles";
import { CommonStyles } from "../../styles/common.styles";
import { useWindowSize } from "../../hooks/useWindowSize";
import RippleButton from "../Button/RippleButton";
import ReactSlider from "react-slider";
import styled from "styled-components";
import novice_icon from 'src/assets/languageLevel/box_hour.svg'
import novice_icon1 from 'src/assets/languageLevel/indicator_suitable.svg'

export interface SliderQuestionContainerProps {
    question: string;
    onClick?: (value: any) => void;
    children?: any
}

const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 6px;
`;

const StyledBoxHour = styled.div`
  height: 60px;
  width: 56px;
  left: -25px;
  top: -32px;
  position: absolute;
  background: transparent url(${ novice_icon }) 0% 0% no-repeat padding-box;
  outline: 0;
  outline: none;
`;

const TimeText = styled.p`
  text-align: center;
  font: normal normal bold 9px/20px Nunito;
  letter-spacing: 0px;
  color: #49A8FB;
  opacity: 1;
`;

const StyledIndicator = styled.div`
  height: 16px;
  width: 16px;
  left: -5px;
  top: -5px;
  position: absolute;
  background: transparent url(${ novice_icon1 }) 0% 0% no-repeat padding-box;
  outline: 0;
  outline: none;
`;

const IndicatorContainer = styled.div`
`;


const convertToTimeString = (minutes: number) => {
    let h: number | string = Math.floor(minutes / 60);
    let m: number | string = minutes % 60;
    h = h < 10 ? '0' + h : h;
    m = m < 10 ? '0' + m : m;
    return h + ':' + m;
}


const Thumb = (props: any, state: any) => {

    return (
        <>
            <IndicatorContainer { ...props } index={ state.index }>
                <StyledBoxHour>
                    <TimeText>{ convertToTimeString(props["aria-valuenow"]) }</TimeText>
                </StyledBoxHour>
                <StyledIndicator></StyledIndicator>
            </IndicatorContainer>

        </>
    )
};

const StyledTrackEllipse = styled.div<{ index: number }>`
  top: 0px;
  bottom: 0;
  background: ${ props => (props.index === 2 ? '#E9F3FA' : props.index === 1 ? '#49A8FB' : '#E9F3FA') };
  border-radius: 6px;
  opacity: 1;
`;

const Track = (props: any, state: any) => {

    return (
        <StyledTrackEllipse { ...props } index={ state.index }/>
    )
};



export const SliderQuestionContainer = (props: SliderQuestionContainerProps) => {
    const [from, setFrom] = useState(420);
    const [to, setTo] = useState(1140);
    const { i18n, t } = useTranslation();
    const [width, height] = useWindowSize();

    const onChange = (e: any) => {
        setFrom(e[0])
        setTo(e[1])
    }


    return (
        <>
            <QuestionContainer>
                <S.QuestionContainer>
                    <S.QuestionText>
                        { props.question }
                    </S.QuestionText>
                </S.QuestionContainer>
                <S.AnswerContainer>
                    <StyledSlider min={ 0 } max={ 1440 }
                                  defaultValue={ [420, 1140] }
                                  renderTrack={ Track }
                                  renderThumb={ Thumb } onChange={onChange}/>
                </S.AnswerContainer>

                <S.ButtonContainer>
                    <CommonStyles.DynamicButtonContainer height={ height } maxHeight={ 700 }>
                        <RippleButton onClick={ () => {
                            props.onClick && props.onClick(`${from} - ${to}`);
                        } }>
                            { t('common.actions.continue') }
                        </RippleButton>
                    </CommonStyles.DynamicButtonContainer>
                </S.ButtonContainer>

            </QuestionContainer>
        </>
    );
}

