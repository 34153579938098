import styled from 'styled-components'

export const StyledQuestionDesc = {
    DescContainer: styled.div`
      font-size: 14px;
      margin: 7px;
      text-align: center;
      color: #8CA6C5;
    `,
}
