import React from 'react';
import { StyledOption as S } from './PaymentFailedModal.styles'
import { useTranslation } from "react-i18next";
import RippleButton from "../../Button/RippleButton";
import { CommonStyles } from "../../../styles/common.styles";

export const PaymentFailedModal = (props: any) => {
    const { i18n, t } = useTranslation();

    return (
        <>
            <S.PaymentFailedModalHeader onClick={ () => props.setIsOpen(false) }></S.PaymentFailedModalHeader>
            <S.PaymentFailedModal>
                <S.FailedIconContainer/>
                <S.PaymentFailedTextContainer>
                    <S.TryAgainText>
                        { t('payment.fail.text') }<br/>
                        { t('payment.fail.unsuccessful') }
                    </S.TryAgainText>
                </S.PaymentFailedTextContainer>
                <CommonStyles.ButtonContainer>
                    <RippleButton onClick={ props.onFail }>
                        { t('payment.fail.tryAgain') }
                    </RippleButton>
                </CommonStyles.ButtonContainer>
            </S.PaymentFailedModal>
        </>
    );
}
