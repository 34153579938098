import React from "react";
import { TPageProps } from "../TPageProps";
import { PageId } from "../../Pages";
import { WordQuestions } from "./WordQuestions";

export const WordQuestions3: React.FC<TPageProps> = (params: TPageProps) => {
    const words = [
        " Responsible", " Sing", " Jacket", " Flexible",
        " Travel", " Passport", " Sophisticated", " Learn",
        " Credit card", " Knowledgeable", " Help", " Shopping",
        " Exquisite", " Use", " Meeting", " Proficient",
        " Create", " Conversation", " Ingenious", " Start",
        " Cinema", " Diligent", " Finish", " Vacation",
        " Resilient", " Change", " Traffic", " Eloquent",
        " Plan", " Money", " Astute", " Organize",
        " Friend", " Discerning"
    ]
    return (
        <WordQuestions pageId={ PageId.WORDQUESTIONS3} nextPagePath={ "v3/wellDone" } progressValue={ 3 } words={ words }/>

    );
}
