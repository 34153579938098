import React from 'react';
import icons from "./images"
import { HeaderStyles as H, InformativeStyles as I, PageStyles as P } from "./Styles";
import { DynamicButton } from "./Button";
import { useWindowSize } from "../../hooks/useWindowSize";

export interface InformativeProps {
    title: string,
    icon: string,
    desc: JSX.Element,
    buttonText: string,
    onClick: any,
    background: string,
    maxHeight: number,
}

export const Informative = (props: InformativeProps) => {
    const [width, height] = useWindowSize();

    return (
        <P.DynamicContainer height={ height }>
            <H.HeaderContainer>
                <H.IconContainer icon={ icons.logo }/>
            </H.HeaderContainer>
            <I.InformativeContainer background={ props.background }>
                <I.InformativeTitleContainer>
                    <I.InformativeTitle>
                        { props.title }
                    </I.InformativeTitle>
                </I.InformativeTitleContainer>
                <I.InformativeImage img={ props.icon }/>
                <I.InformativeDescContainer>
                    { props.desc }
                </I.InformativeDescContainer>
            </I.InformativeContainer>
            <DynamicButton onclick={ props.onClick } text={ props.buttonText } height={ height }
                           maxHeight={ props.maxHeight } width={ "447px" } maxWidth={ "100vw" }/>
        </P.DynamicContainer>
    );
}

