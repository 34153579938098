import React, { useEffect, useState } from 'react';
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import icons from "./images"
import { HeaderStyles as H, PageStyles as P, ToolbarStyles as To, WhatIsYourNameStyles as S } from "./Styles";
import { SignInTitle } from "./Title";
import { DynamicProgressButton } from "./Button";
import { firebaseService } from "../../services/firebase.service";
import { captureException } from "../../services/sentry.service";
import { useWindowSize } from "../../hooks/useWindowSize";
import { useNavigate } from "react-router-dom";

export const SignIn: any = (params: any) => {
    const handleContinue = useNextStep(params)
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [completed, setCompleted] = useState(false);
    const navigate = useNavigate();
    const [emailCompleted, setEmailCompleted] = useState(false);
    const [passwordToggle, setPasswordToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [passwordInputType, setPasswordInputType] = useState("password");
    const [errorMessage, setErrorMessage] = useState("");
    const [width, height] = useWindowSize();
    const { i18n, t } = useTranslation();


    const submitForm = () => {
        setLoading(true);

        firebaseService.signInWithEmailAndPassword({ email: email, password: password })
            .then(user => Promise.all([user.user.getIdToken(), firebaseService.getUser({ uid: user.user.uid })]))
            .then(([idToken, dbUser]) => {
                window.sessionStorage.setItem("email", email);
                setLoading(false);
                navigate("/v4/paymentC4");
            })
            .catch(err => {
                setLoading(false);
                setErrorMessage("Sign In Failed")
                captureException(err)
            });
    }

    useEffect(() => {
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email) ? setEmailCompleted(true) : setEmailCompleted(false)
    }, [email]);

    useEffect(() => {
        password.length > 7 ? setCompleted(true) : setCompleted(false);
    }, [password]);

    return (
        <P.PageContainer>
            <P.Background/>
            <H.HeaderContainer>
                <H.IconContainer icon={ icons.logo }/>
            </H.HeaderContainer>
            <To.ToolbarTitleContainer>
                <SignInTitle title={ "Sign In" }/>
            </To.ToolbarTitleContainer>
            <S.AnswerContainer>
                <S.AnswerLabelContainer>
                    <S.AnswerLabel>
                        E-mail
                    </S.AnswerLabel>
                </S.AnswerLabelContainer>
                <S.AnswerInput type="email" placeholder={ "Email" } value={ email }
                               onChange={ (e) => setEmail(e.target.value) }>

                </S.AnswerInput>
                { !emailCompleted && !!email &&
                    <>
                        <S.ErrorContainer>
                            <S.Warning src={ icons.warningIcon }/>
                            <S.ErrorMessage>Please enter a valid email address</S.ErrorMessage>
                        </S.ErrorContainer>
                    </>
                }

                <S.AnswerLabelContainer>
                    <S.AnswerLabel>
                        Password
                    </S.AnswerLabel>
                </S.AnswerLabelContainer>
                <S.AnswerInput type={ passwordInputType } placeholder={ "Password" } value={ password }
                               onChange={ (e) => setPassword(e.target.value) }>
                </S.AnswerInput>

                <S.EyeWrapper>
                    <S.Eye onClick={ () => {
                        passwordInputType === "text" ? setPasswordInputType("password") : setPasswordInputType("text")
                        setPasswordToggle(!passwordToggle)
                    } } toggle={ passwordToggle }></S.Eye>
                </S.EyeWrapper>

                { !completed && !!password &&
                    <>
                        <S.ErrorContainer>
                            <S.Warning src={ icons.warningIcon }/>
                            <S.ErrorMessage>Minimum 8 characters</S.ErrorMessage>
                        </S.ErrorContainer>
                    </>
                }

            </S.AnswerContainer>
            <DynamicProgressButton onclick={ submitForm } text={ "Sign In" }
                                   disabled={ !completed || !emailCompleted }
                                   loading={ loading } height={ height } maxHeight={ 600 } width={ "427px" }
                                   maxWidth={ "95vw" }/>

            { errorMessage &&
                <>
                    <S.ErrorContainer>
                        <S.Warning src={ icons.warningIcon }/>
                        <S.ErrorMessage>{ errorMessage }</S.ErrorMessage>
                    </S.ErrorContainer>
                </>
            }
        </P.PageContainer>
    );
}

