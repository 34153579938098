import React, { useCallback, useMemo, useState } from 'react';
import { TPageProps } from "../TPageProps";
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import { QuestionTitle } from "src/components/QuestionTitle/QuestionTitle";
import { Option, TOptionPropsBase } from "src/components/Option/Option";

import geographyAndTravel_icon from 'src/assets/FocusTopics/ic_geography.svg'
import entertainmentAndPopCulture_icon from 'src/assets/FocusTopics/ic_entertainment.svg'
import scienceAndTechnology_icon from 'src/assets/FocusTopics/ic_science.svg'
import academicOrProfessionalVocabulary_icon from 'src/assets/FocusTopics/ic_academic_professional.svg'
import socialOrCulturalIssues_icon from 'src/assets/FocusTopics/ic_social_cultural.svg'
import foodAndCooking_icon from 'src/assets/FocusTopics/ic_food.svg'
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import RippleButton from "../../../components/Button/RippleButton";
import { CommonStyles } from "../../../styles/common.styles";
import { QuestionContainer } from "../../../components/QuestionContainer/QuestionContainer";
import { useWindowSize } from "../../../hooks/useWindowSize";
import userService, { getUserId } from "../../../services/user.service";
import { Toolbar } from "../../../components/Toolbar/Toolbar";
import { useGetPageInfo } from "../../../hooks/useGetPageInfo";
import { DividedToolbar } from "../../../components/Toolbar/DividedToolbar";

export const FocusTopics: React.FC<TPageProps> = (params: TPageProps) => {
    const handleContinue = useNextStep(params)
    const { i18n, t } = useTranslation();
    const [width, height] = useWindowSize();
    const [answers, setAnswers] = useState<string[]>([])
    const { currentCohort } = useGetPageInfo()

    const props = useMemo<TOptionPropsBase>(
        () => ({
            onUpdated: (value: string) => {
                if (!answers.some(item => item === value)) {
                    answers.push(value)
                    setAnswers([...answers])
                } else {
                    setAnswers(answers.filter((item) => item !== value))
                }
            }
        }), [answers, params.pageId]
    )
    const handleNextClick = useCallback(() => {
        if (answers.length === 0) {
            return
        }

        const uniqueAnswers = answers.map((answer) => answer)
        getUserId().then(userId => userService.pushAnswer(userId, {
            question: params.pageId,
            answer: uniqueAnswers
        }))
        handleContinue(uniqueAnswers)
    }, [answers, handleContinue])

    return (
        <>
            { currentCohort !== 'v3' && <Toolbar progressValue={ 5 } pagesNumberWithNavigation={ 6 }></Toolbar> }
            { currentCohort === 'v3' &&
                <DividedToolbar index={ 0 } progressValue={ 9 } pagesNumberWithNavigation={ 9 }></DividedToolbar>
            }

            <PageContainer>
                <QuestionContainer>
                    <QuestionTitle>{ t('focusTopics.title') }</QuestionTitle>
                    <Option { ...props } icon={ geographyAndTravel_icon }
                            title={ t('focusTopics.geographyAndTravel.title') }
                            id={ 'geographyAndTravel' }
                    />
                    <Option { ...props } icon={ entertainmentAndPopCulture_icon }
                            title={ t('focusTopics.entertainmentAndPopCulture.title') }
                            id={ 'entertainmentAndPopCulture' }
                    />
                    <Option { ...props } icon={ scienceAndTechnology_icon }
                            title={ t('focusTopics.scienceAndTechnology.title') }
                            id={ 'scienceAndTechnology' }
                    />
                    <Option { ...props } icon={ academicOrProfessionalVocabulary_icon }
                            title={ t('focusTopics.academicOrProfessionalVocabulary.title') }
                            id={ 'academicOrProfessionalVocabulary' }
                    />
                    <Option { ...props } icon={ socialOrCulturalIssues_icon }
                            title={ t('focusTopics.socialOrCulturalIssues.title') }
                            id={ 'socialOrCulturalIssues' }
                    />
                    <Option { ...props } icon={ foodAndCooking_icon }
                            title={ t('focusTopics.foodAndCooking.title') }
                            id={ 'foodAndCooking' }
                    />
                </QuestionContainer>
                <CommonStyles.DynamicButtonContainer height={ height } maxHeight={ 850 }>
                    <RippleButton disabled={ answers.length === 0 } onClick={ handleNextClick }>
                        { t('common.actions.next') }
                    </RippleButton>
                </CommonStyles.DynamicButtonContainer>
            </PageContainer>
        </>
    );
}

