import { useCallback, useContext } from 'react'
import { TPageProps } from "src/pages/questions/TPageProps";
import { useNavigate } from "react-router-dom";
import { DELAY } from "../constants/rootConstants";
import { AppContext, setAnswer } from "../context/answers";

export const useNextStep = (params: TPageProps): ((answer: string | string[] | null) => void) => {
    const navigate = useNavigate();
    const { state, dispatch } = useContext(AppContext);

    return useCallback(
        (answer: string | string[] | null) => {
            dispatch(setAnswer(params.pageId, answer));
            navigate('/' + params.nextPagePath)
        },
        [params.pageId, params.nextPagePath],
    )
}
