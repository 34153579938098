import React from "react";
import { TPageProps } from "../TPageProps";
import { PageId } from "../../Pages";
import { WordQuestions } from "./WordQuestions";

export const WordQuestions2: React.FC<TPageProps> = (params: TPageProps) => {
    const words = [
        " Hotel", " Fast", " Write", " Park",
        " Confident", " Drive", " Beach", " Generous",
        " Shop", " Music", " Patient", " Exercise",
        " Bicycle", " Reliable", " Clean", " Camera",
        " Independent", " Relax", " Newspaper", " Ambitious",
        " Meet", " Restaurant", " Creative", " Play",
        " Gym", " Determined", " Dance", " Train"
    ]
    return (
        <WordQuestions pageId={ PageId.WORDQUESTIONS2 } nextPagePath={ "v3/wordQuestions3" } progressValue={ 2 } words={ words }/>
    );
}
