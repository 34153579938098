import React, { useMemo } from 'react';
import { TPageProps } from "../TPageProps";
import { useNextStep } from "src/hooks/useNextStep";
import { QuestionTitle } from "src/components/QuestionTitle/QuestionTitle";
import { Option, TOptionPropsBase } from "src/components/Option/Option";
import { useTranslation } from "react-i18next";

import novice_icon from 'src/assets/languageLevel/ic_novice.svg'
import elementary_icon from 'src/assets/languageLevel/ic_elementary.svg'
import preIntermediate_icon from 'src/assets/languageLevel/ic_pre_intermediate.svg'
import intermediate_icon from 'src/assets/languageLevel/ic_intermediate.svg'
import upperIntermediate_icon from 'src/assets/languageLevel/ic_upper_intermediate.svg'
import advanced_icon from 'src/assets/languageLevel/ic_advanced.svg'
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import { QuestionContainer } from "../../../components/QuestionContainer/QuestionContainer";
import userService, { getUserId } from "../../../services/user.service";
import { Toolbar } from "../../../components/Toolbar/Toolbar";

export const CurrentLevel: React.FC<TPageProps> = (params: TPageProps) => {
    const handleContinue = useNextStep(params)
    const { i18n, t } = useTranslation();

    const props = useMemo<TOptionPropsBase>(
        () => ({
            onUpdated: (value: string) => {
                getUserId().then(userId => userService.pushAnswer(userId, {
                    question: params.pageId,
                    answer: value
                }))

                handleContinue(value)
            }
        }), [handleContinue, params.pageId]
    )

    return (
        <>
            <Toolbar progressValue={ 1 } pagesNumberWithNavigation={ 6 }></Toolbar>
            <PageContainer>
                <QuestionContainer>
                    <QuestionTitle>{ t('currentLevel.title') }</QuestionTitle>
                    <Option { ...props } icon={ novice_icon }
                            title={ t('currentLevel.novice.title') }
                            id={ 'novice' }
                            desc={ t('currentLevel.novice.desc') }/>
                    <Option { ...props } icon={ elementary_icon }
                            title={ t('currentLevel.elementary.title') }
                            id={ 'elementary' }
                            desc={ t('currentLevel.elementary.desc') }/>
                    <Option { ...props } icon={ preIntermediate_icon }
                            title={ t('currentLevel.preIntermediate.title') }
                            id={ 'preIntermediate' }
                            desc={ t('currentLevel.preIntermediate.desc') }/>
                    <Option { ...props } icon={ intermediate_icon }
                            title={ t('currentLevel.intermediate.title') }
                            id={ 'intermediate' }
                            desc={ t('currentLevel.intermediate.desc') }/>
                    <Option { ...props } icon={ upperIntermediate_icon }
                            title={ t('currentLevel.upperIntermediate.title') }
                            id={ 'upperIntermediate' }
                            desc={ t('currentLevel.upperIntermediate.desc') }/>
                    <Option { ...props } icon={ advanced_icon }
                            title={ t('currentLevel.advanced.title') }
                            id={ 'advanced' }
                            desc={ t('currentLevel.advanced.desc') }/>
                </QuestionContainer>
            </PageContainer>
        </>
    );
}

