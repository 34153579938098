import styled from 'styled-components'
import ic_arrow from 'src/assets/languageLevel/chevron_down.svg'

export const StyledSelect = {
    SelectContainer: styled.div`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    `,
    Select: styled.select`
      margin-top: 10%;
      width: 90%;
      height: 60px;
      border: 2px solid #E5F1FE;
      border-radius: 10px;
      opacity: 1;
      font: normal normal bold 18px/24px NunitoBold;
      padding: 10px 30px 10px 10px;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-image: url(${ ic_arrow });
      background-repeat: no-repeat, repeat;
      background-position: right .7em top 50%, 0 0;
    `
}
