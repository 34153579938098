import React, { useMemo } from 'react';
import { TPageProps } from "../TPageProps";
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import { Toolbar } from "../../../components/Toolbar/Toolbar";
import userService, { getUserId } from "../../../services/user.service";
import {
    MultiSelectQuestionContainer,
    MultiSelectQuestionContainerProps
} from "../../../components/QuestionContainer/MultiSelectQuestionContainer";
import {
    StyledMultiSelectQuestion as S
} from "../../../components/QuestionContainer/MultiSelectQuestionContainer.styles";
import geographyAndTravel_icon from 'src/assets/FocusTopics/ic_geography.svg'
import entertainmentAndPopCulture_icon from 'src/assets/FocusTopics/ic_entertainment.svg'
import scienceAndTechnology_icon from 'src/assets/FocusTopics/ic_science.svg'
import academicOrProfessionalVocabulary_icon from 'src/assets/FocusTopics/ic_academic_professional.svg'
import socialOrCulturalIssues_icon from 'src/assets/FocusTopics/ic_social_cultural.svg'
import foodAndCooking_icon from 'src/assets/FocusTopics/ic_food.svg'
import { useGetPageInfo } from "../../../hooks/useGetPageInfo";
import { DividedToolbar } from "../../../components/Toolbar/DividedToolbar";


export const InterestingTopics: React.FC<TPageProps> = (params: TPageProps) => {
    const handleContinue = useNextStep(params);
    const { i18n, t } = useTranslation();
    const { currentCohort } = useGetPageInfo()


    const props = useMemo<MultiSelectQuestionContainerProps>(
        () => ({
            question: "Select the topics that you find interesting",
            answers: ["Travelling", "Technology", "Daily life & pop culture", "Business", "Cultural issues", "Food and cooking"],
            icons: [geographyAndTravel_icon, scienceAndTechnology_icon, entertainmentAndPopCulture_icon, academicOrProfessionalVocabulary_icon, socialOrCulturalIssues_icon, foodAndCooking_icon],
            onClick: (value: any) => {
                setTimeout(() => {
                    getUserId().then(userId => userService.pushAnswer(userId, {
                        question: params.pageId,
                        answer: value
                    }))
                    handleContinue(params.pageId);
                }, 100)
            },
        }), [handleContinue, params.pageId]
    )

    return (
        <>
            { currentCohort !== 'v3' && <Toolbar progressValue={ 6 } pagesNumberWithNavigation={ 6 }></Toolbar>}
            { currentCohort === 'v3' &&
                <DividedToolbar index={ 2 } progressValue={ 6 } pagesNumberWithNavigation={ 7 }></DividedToolbar>
            }
            <PageContainer>
                <MultiSelectQuestionContainer { ...props }>

                </MultiSelectQuestionContainer>
            </PageContainer>
        </>

    );
}

