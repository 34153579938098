import React from 'react';
import { StyledQuestionContainer as S } from './QuestionContainer.styles'
import { useWindowSize } from "../../hooks/useWindowSize";

export interface TPageContainerProps {
    children?: any

}


export const QuestionContainer: React.FC<TPageContainerProps> = (params: TPageContainerProps) => {
    const [width, height] = useWindowSize();

    return (

        <S.DynamicContainer height={ height }>
            { params.children }
        </S.DynamicContainer>
    );
}

