import styled from "styled-components";

export const Styles = {

    SubscriptionTermsForm: styled.div`
         margin: 20px;
 word-wrap: break-word;
    `,


}
