import React, { useState } from 'react';
import { StyledMultiSelectQuestion as S } from './MultiSelectQuestionContainer.styles'
import { QuestionContainer } from "./QuestionContainer";
import { useTranslation } from "react-i18next";

export interface MultiSelectQuestionContainerProps {
    question: string | null;
    answers: string[];
    icons?: string[];
    onClick?: (value: any) => void;
    children?: any
}

export const MultiSelectQuestionContainer = (props: MultiSelectQuestionContainerProps) => {
    const [selectedAnswer, setSelectedAnswer] = useState("");
    const { i18n, t } = useTranslation();


    const getElFromArray = (arr: string[] | undefined, index: number) => {
        if (arr === undefined) {
            return undefined;
        }

        if (index > arr.length) {
            return undefined;
        }

        return arr[index];
    }

    return (
        <>
            <QuestionContainer>
                <S.QuestionContainer>
                    <S.QuestionText>
                        { props.question }
                    </S.QuestionText>
                </S.QuestionContainer>
                <S.AnswersContainer>
                    { props.answers && props.answers.map((item: string, index: number) => {
                        return (
                            <S.AnswerContainer key={ item } selected={ item === selectedAnswer } onClick={ (e: any) => {
                                setSelectedAnswer(item);
                                props.onClick && props.onClick(item);
                            } }>
                                <S.IconContainer img={ getElFromArray(props.icons, index) }
                                                 hidden={ getElFromArray(props.icons, index) === undefined }></S.IconContainer>
                                <S.AnswerText>{ item }</S.AnswerText>
                                <S.Empty hidden={ getElFromArray(props.icons, index) === undefined }/>
                            </S.AnswerContainer>
                        )
                    })
                    }
                </S.AnswersContainer>
            </QuestionContainer>
        </>

    );
}

