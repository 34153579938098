import React, { useCallback, useMemo, useState } from 'react';
import { TPageProps } from "../TPageProps";
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import { QuestionTitle } from "src/components/QuestionTitle/QuestionTitle";
import { Option, TOptionPropsBase } from "src/components/Option/Option";

import textbooksAndWrittenMaterials_icon from 'src/assets/PreferredLearningMethod/ic_textbooks.svg'
import conversationsAndSpeakingPractice_icon from 'src/assets/PreferredLearningMethod/ic_conversations.svg'
import listeningExercisesAndAudioMaterials_icon from 'src/assets/PreferredLearningMethod/ic_listening.svg'
import watchingVideosAndMediaContent_icon from 'src/assets/PreferredLearningMethod/ic_watching.svg'
import onlinePlatformsAndApps_icon from 'src/assets/PreferredLearningMethod/ic_online_platforms.svg'
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import { QuestionContainer } from "../../../components/QuestionContainer/QuestionContainer";
import { Toolbar } from "../../../components/Toolbar/Toolbar";
import RippleButton from "../../../components/Button/RippleButton";
import { CommonStyles } from "../../../styles/common.styles";
import userService, { getUserId } from "../../../services/user.service";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { useGetPageInfo } from "../../../hooks/useGetPageInfo";
import { DividedToolbar } from "../../../components/Toolbar/DividedToolbar";

export const MultiSelectPreferredLearningMethod: React.FC<TPageProps> = (params: TPageProps) => {
    const handleContinue = useNextStep(params)
    const [width, height] = useWindowSize();
    const { i18n, t } = useTranslation();
    const [answers, setAnswers] = useState<string[]>([])
    const { currentCohort } = useGetPageInfo()

    const props = useMemo<TOptionPropsBase>(
        () => ({
            onUpdated: (value: string) => {
                if (!answers.some(item => item === value)) {
                    answers.push(value)
                    setAnswers([...answers])
                } else {
                    setAnswers(answers.filter((item) => item !== value))
                }
            },
        }), [answers, params.pageId]
    )
    const handleNextClick = useCallback(() => {
        if (answers.length === 0) {
            return
        }

        const uniqueAnswers = answers.map((answer) => answer)
        getUserId().then(userId => userService.pushAnswer(userId, {
            question: params.pageId,
            answer: uniqueAnswers
        }))
        handleContinue(uniqueAnswers)
    }, [answers, handleContinue])

    return (
        <>
            { currentCohort !== 'v3' && <Toolbar progressValue={ 6 } pagesNumberWithNavigation={ 6 }></Toolbar> }
            { currentCohort === 'v3' &&
                <DividedToolbar index={ 0 } progressValue={ 6 } pagesNumberWithNavigation={ 6 }></DividedToolbar>
            }

            <PageContainer>
                <QuestionContainer>
                    <QuestionTitle>{ t('preferredLearningMethod.title') }</QuestionTitle>
                    <Option { ...props } icon={ textbooksAndWrittenMaterials_icon }
                            title={ t('preferredLearningMethod.textbooksAndWrittenMaterials.title') }
                            id={ 'textbooksAndWrittenMaterials' }/>
                    <Option { ...props } icon={ conversationsAndSpeakingPractice_icon }
                            title={ t('preferredLearningMethod.conversationsAndSpeakingPractice.title') }
                            id={ 'conversationsAndSpeakingPractice' }/>
                    <Option { ...props } icon={ listeningExercisesAndAudioMaterials_icon }
                            title={ t('preferredLearningMethod.listeningExercisesAndAudioMaterials.title') }
                            id={ 'listeningExercisesAndAudioMaterials' }/>
                    <Option { ...props } icon={ watchingVideosAndMediaContent_icon }
                            title={ t('preferredLearningMethod.watchingVideosAndMediaContent.title') }
                            id={ 'watchingVideosAndMediaContent' }/>
                    <Option { ...props } icon={ onlinePlatformsAndApps_icon }
                            title={ t('preferredLearningMethod.onlinePlatformsAndApps.title') }
                            id={ 'onlinePlatformsAndApps' }/>

                </QuestionContainer>

                <CommonStyles.DynamicButtonContainer height={ height } maxHeight={ 850 }>
                    <RippleButton disabled={ answers.length === 0 } onClick={ handleNextClick }>
                        { t('common.actions.next') }
                    </RippleButton>
                </CommonStyles.DynamicButtonContainer>
            </PageContainer>
        </>
    );
}

