import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripePaymentElementChangeEvent, StripePaymentElementOptions } from "@stripe/stripe-js";
import React, { FormEvent, useEffect, useState } from "react";
import { TOptionProps } from "../Option/Option";
import { PaymentSuccessModal } from "../Payment/PaymentSuccess/PaymentSuccessModal";
import { PaymentFailedModal } from "../Payment/PaymentFailed/PaymentFailedModal";
import { Styles as S } from "./CheckoutPage.styles";
import { useTranslation } from "react-i18next";
import { PageContainer } from "../PageContainer/PageContainer";
import { firebaseService } from "../../services/firebase.service";
import ProgressButton from "../Button/ProgressButton";
import { CommonStyles } from "../../styles/common.styles";
import { useWindowSize } from "../../hooks/useWindowSize";
import { fbq } from "../../services/metapixel.service";
import { captureException, captureObject } from "../../services/sentry.service";
import { useGetPageInfo } from "../../hooks/useGetPageInfo";
import stripeService from "../../services/stripe.service";
import { configId } from "../../config";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export default function CheckoutEmailForm(params: TOptionProps) {
    const { i18n, t } = useTranslation();
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailed, setIsFailed] = useState(false);
    const [width, height] = useWindowSize();
    const [paymentFormCompleted, setPaymentFormCompleted] = useState(false)
    const [emailCompleted, setEmailCompleted] = useState(false)
    const { currentCohort } = useGetPageInfo()
    const [email, setEmail] = useState("")
    const [promotion, setPromotion] = useState(false)


    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        const email = new URLSearchParams(window.location.search).get(
            "email"
        );

        if (!clientSecret) {
            return;
        }

        if (!email) {
            return;
        }

        window.sessionStorage.setItem("email", email)
        stripeService.updateCustomer({
            configId: configId,
            customerId: window.sessionStorage.getItem("customerId"),
            email: email
        }).catch(err => {
            captureException(err);
        });

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            if (!paymentIntent) {
                return
            }

            switch (paymentIntent.status) {
                case "succeeded":
                    firebaseService.logFirebaseEvent("checkout_payment_succeeded")
                    fbq('trackCustom', 'checkout_payment_succeeded')
                    fbq('track', 'Purchase', {
                        content_ids: [paymentIntent.id],
                        // eventref: 'fb_oea',
                        currency: paymentIntent.currency,
                        num_items: 1,
                        value: paymentIntent.amount / 100.0,
                    })

                    setIsSuccess(true);
                    break;
                case "processing":
                    const msg = t("payments.alerts.processing")
                    setMessage(msg);
                    break;
                case "requires_payment_method":
                    setIsFailed(true);
                    const msg2 = t("payments.alerts.unsuccessful")
                    setMessage(msg2);
                    break;
                default:
                    setIsFailed(true);
                    const msg3 = t("payments.alerts.failed")
                    setMessage(msg3);
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        setIsLoading(true);
        firebaseService.logFirebaseEvent("checkout_pay_button_clicked")
        fbq('trackCustom', 'checkout_pay_button_clicked')
        fbq('track', 'InitiateCheckout')


        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${ window.location.origin }/${ currentCohort }/checkoutWithEmail?email=${ email }`,
            },
        });

        captureObject(error);

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            // @ts-ignore
            setMessage(error.message);
            setIsFailed(true);
        } else {
            const msg = t("payments.alerts.error")
            setMessage(msg);
            setIsFailed(true);
        }

        setIsLoading(false);

    };

    const paymentElementOptions: StripePaymentElementOptions = {
        paymentMethodOrder: ['card', 'google_pay'],
        layout: "tabs"
    }

    if (isSuccess) {
        return <PaymentSuccessModal setIsOpen={ setIsSuccess } onSuccess={ () => params.onUpdated(params.id) }/>
    }

    if (isFailed) {
        return <PaymentFailedModal setIsOpen={ setIsSuccess } onFail={ () => {
            setIsFailed(false);
            setMessage("")
        } }/>
    }

    const onChange = (ev: StripePaymentElementChangeEvent) => {
        if ((ev.value.type === 'apple_pay' || ev.value.type === 'paypal') && !emailCompleted) {
            toast('Please enter your email.');
        }

        (ev.complete) ? setPaymentFormCompleted(true) : setPaymentFormCompleted(false);
    }

    const emailOnChange = (ev: any) => {
        setEmail(ev.target.value);
        /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(ev.target.value) ? setEmailCompleted(true) : setEmailCompleted(false)
    }

    const disabledOnClick = (e: any) => {
        if (!emailCompleted) {
            toast('Please enter your email.');
        }
    }

    return (

        <PageContainer>
            <ToastContainer autoClose={3000}/>
            <S.PaymentInformationContainer>
                <S.PaymentInformationText>
                    { t('payment.title1') }<br/>
                    { t('payment.title2') }
                </S.PaymentInformationText>
                <S.SecureContainer>
                    <S.SecureIcon/>
                    <S.SecureText>
                        { t('payment.secure') }
                    </S.SecureText>
                </S.SecureContainer>
            </S.PaymentInformationContainer>
            <S.PaymentContainer>
                <S.PaymentFormContainer>
                    <S.PaymentForm onSubmit={ handleSubmit }>
                        <S.EmailContainer>
                            <S.EmailInput type="email" placeholder="E-mail" value={ email } onChange={ emailOnChange }/>
                        </S.EmailContainer>
                        <S.PromotionWrapper>
                            <S.PromotionCheckBox selected={ promotion } onClick={ () => setPromotion(!promotion) }/>
                            <S.PromotionText onClick={ () => setPromotion(!promotion) }>
                                { t('email.promotional') }
                            </S.PromotionText>
                        </S.PromotionWrapper>

                        <PaymentElement id="payment-element" options={ paymentElementOptions } onChange={ onChange }/>
                        <CommonStyles.DynamicButtonContainer height={ height } maxHeight={ 700 } onClick={ disabledOnClick }>
                            <CommonStyles.Separator/>
                            <ProgressButton id={ "submit" } loading={ isLoading ? 1 : 0 }
                                            disabled={ !(emailCompleted && paymentFormCompleted) }>
                                { t('common.actions.continue') }
                            </ProgressButton>
                        </CommonStyles.DynamicButtonContainer>
                    </S.PaymentForm>
                </S.PaymentFormContainer>
            </S.PaymentContainer>
        </PageContainer>
    );
}
