import { TPageProps } from "../../pages/questions/TPageProps";
import React from "react";
import { Styles as S } from "./DownloadPage.styles";
import { useTranslation } from "react-i18next";
import { PageContainer } from "../PageContainer/PageContainer";
import RippleButton from "../Button/RippleButton";
import { CommonStyles } from "../../styles/common.styles";
import { HeaderContainer } from "../Toolbar/HeaderContainer";
import ProgressSteps from "../Toolbar/ProgressSteps";

export default function DownloadPage(params: TPageProps) {
    const { i18n, t } = useTranslation();
    const url: string = "https://apps.apple.com/app/id1669752209";

    return (
        <PageContainer>
            <HeaderContainer progressHidden={ true }/>
            <ProgressSteps activeStep={ 5 }/>
            <S.DownloadPageContainer>
                <S.HeaderContainer>
                    <S.HeaderContainerText>
                        { t('download.title') }
                    </S.HeaderContainerText>
                </S.HeaderContainer>
                <S.DownloadContainer>
                    <S.CardContainer>
                        <S.CardIcon/>
                        <S.FirstCardTextContainer>
                            <S.CardTextSemiBold>
                                Lingu
                            </S.CardTextSemiBold>
                            <S.CardTextExtraBold>
                                Town
                            </S.CardTextExtraBold>
                        </S.FirstCardTextContainer>
                        <S.SecondCardTextContainer>
                            <S.CardTextRegular>
                                { t('download.card') }
                            </S.CardTextRegular>
                        </S.SecondCardTextContainer>
                    </S.CardContainer>
                </S.DownloadContainer>
                <CommonStyles.ButtonContainer>
                    <CommonStyles.Separator/>
                    <CommonStyles.Separator/>
                    <RippleButton onClick={ () => window.location.href = url }>
                        { t('common.actions.download') }
                    </RippleButton>
                </CommonStyles.ButtonContainer>
                <CommonStyles.Separator/>
            </S.DownloadPageContainer>
        </PageContainer>
    )
}
