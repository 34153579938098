import React from 'react';
import {StyledQuestionDesc as S} from './QuestionDesc.styles'
import {TComponentProps} from "src/models/TComponentProps";

export const QuestionDesc: React.FC<TComponentProps> = (params: TComponentProps) => {
    return (
        <S.DescContainer>
            {params.children}
        </S.DescContainer>
    );
}

