import styled, { css } from 'styled-components'

export const StyledMultiSelectQuestion = {
    GrammarTestTextContainer: styled.div`
      margin-bottom: 10%;
    `,
    GrammarTestText: styled.p`
      text-align: center;
      font: normal normal normal 14px/16px NunitoRegular;
      letter-spacing: 0px;
      color: #8CA6C5;
      opacity: 1;
      text-transform: uppercase;
    `,
    QuestionContainer: styled.div`
    `,
    QuestionText: styled.p`
      text-align: center;
      font: normal normal 800 24px/30px NunitoExtraBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,
    AnswersContainer: styled.div`
      display: flex;
      flex-direction: column;
      margin: 15px 10px 0px;
    `,
    AnswerContainer: styled.div<{ selected: boolean }>`
      ${ props => props.selected && css`
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 2px solid #49A8FB;
        border-radius: 10px;
      ` }

      ${ props => !props.selected && css`
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 2px solid #E5F1FE;
        border-radius: 10px;
        
      ` }
      opacity: 1;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      height: 60px;
      margin: 15px 0px;
    `,
    AnswerText: styled.p`
      text-align: center;
      font: normal normal bold 18px/24px NunitoBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
      width: 70%;
    `,
    IconContainer: styled.div<{ img: string | undefined }>`
      width: 15%;
      height: 50px;
      margin: auto;
      background: transparent url(${ props => props.img }) 50% 0% no-repeat padding-box;
      opacity: 1;
    `,
    Empty: styled.div`
      width: 15%;
      height: 50px;
    `
}
