import React from "react";
import { useNavigate } from "react-router-dom";
import { StyledToolbar as S } from "./Toolbar.styles";
import ic_arrow_back from "../../assets/common/ic_arrow_back.jpg";
import icon_splash from "../../assets/common/icon_splash@2x.png";
import { useTranslation } from "react-i18next";

export const HeaderContainer = (params: any) => {
    const navigate = useNavigate();
    const { i18n, t } = useTranslation();

    return (
        <S.HeaderContainer>
            <S.BackArrow hidden={ params.backArrowHidden } onClick={ () => {
                navigate(-1)
            } }>
                <img src={ ic_arrow_back }/>
            </S.BackArrow>
            <S.CancelButton hidden={ !params.cancelEnabled } onClick={ params.onClick }>

            </S.CancelButton>
            <S.NameContainer hidden={ params.discount }>
                <S.Icon>
                    <img src={ icon_splash }/>
                </S.Icon>
                <S.Title.Normal>Lingu</S.Title.Normal>
                <S.Title.Bold>Town</S.Title.Bold>

            </S.NameContainer>
            <S.ProgressText
                hidden={ params.progressHidden }>{ params.progressValue }/{ params.pagesNumberWithNavigation }</S.ProgressText>

            <S.DiscountContainer hidden={ !params.discount }>
                <S.DiscountText>
                    { t('discount.discountedPrice') }:
                </S.DiscountText>
                <S.DiscountPriceText>
                    { params.discount }
                </S.DiscountPriceText>
            </S.DiscountContainer>

        </S.HeaderContainer>
    );
}
