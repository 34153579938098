import React from 'react';
import { StyledPageContainer as S } from './PageContainer.styles'

export interface TPageContainerProps {
    children?: any

}

export const PageContainer: React.FC<TPageContainerProps> = (params: TPageContainerProps) => {

    return (
        <S.Container>
            { params.children }
        </S.Container>
    );
}

