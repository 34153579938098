import styled from "styled-components";
import headerBG from 'src/assets/checkout/bg_top_tutoring.svg'

export const Styles = {
    RegisterHeaderContainer: styled.div`
      padding-top: 1%;
      height: 100px;
      background-size: contain;
      background-image: url(${ headerBG });
      opacity: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      vertical-align: middle;
    `,
    RegisterHeaderText: styled.p`
      text-align: center;
      width: 80%;
      font: normal normal 800 24px/30px NunitoExtraBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,
    RegisterContainer: styled.div`
      background: #F3FAFF 0% 0% no-repeat padding-box;
      height: 100vh;
      padding: 25px;
    `,
    RegisterForm: styled.form`
    `,
    EmailContainer: styled.div`
      opacity: 1;
    `,
    EmailText: styled.p`
      width: 100%;
      padding-bottom: 10px;
      text-align: left;
      font: normal normal bold 16px/22px NunitoBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,
    EmailInputContainer: styled.div`
      width: 100%;
      margin-bottom: 20px;
    `,
    EmailInput: styled.input`
      border-radius: 10px;
      text-align: left;
      width: 100%;
      height: 40px;
      font: normal normal normal 16px/16px NunitoRegular;
      letter-spacing: 0px;
      opacity: 1;
      text-indent: 10px;
      border: none;
    `,
    PasswordTextContainer: styled.div`
      width: 100%;
      padding-bottom: 10px;
    `,
    PasswordText: styled.p`
      text-align: left;
      font: normal normal bold 16px/22px NunitoBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,
    PasswordInputContainer: styled.div`
      width: 100%;
      margin-bottom: 20px;
    `,
    PasswordInput: styled.input`
      border-radius: 10px;
      text-align: left;
      width: 100%;
      height: 40px;
      font: normal normal normal 16px/16px NunitoRegular;
      letter-spacing: 0px;
      opacity: 1;
      text-indent: 10px;
      border: none;
    `,
    SignUpOptionsContainer: styled.div`
      width: 100%;
      padding-bottom: 10px;
    `,
    SignUpOptionsText: styled.p`
      text-align: center;
      font: normal normal 600 16px/22px Nunito;
      letter-spacing: 0px;
      color: #49A8FB;
    `,
    PrivacyPolicyContainer: styled.div`
      width: 100%;
      padding-top: 30%;
      margin-bottom: 20px;
    `,
    PrivacyPolicyText: styled.p`
      text-align: center;
      font: normal normal normal 12px/16px Nunito;
      letter-spacing: 0px;
      opacity: 1;
      color: #8CA6C5;
    `,
}
