import React from 'react';
import { useTranslation } from "react-i18next";
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import { Styles as S } from './Discount.styles'
import RippleButton from "../../../components/Button/RippleButton";
import { useNavigate } from "react-router-dom";
import { HeaderContainer } from "../../../components/Toolbar/HeaderContainer";
import { GetPrices } from "../../../api/dto";
import { configId } from "../../../config";
import stripeService from "../../../services/stripe.service";
import { useGetPageInfo } from "../../../hooks/useGetPageInfo";
import userService, { getUserId } from "../../../services/user.service";


type StripeRecurring = {
    aggregate_usage: string
    interval: string
    interval_count: number
    trial_period_days: number
    usage_type: string
}

type StripePrice = {
    id: string
    active: boolean
    currency: string
    unit_amount: number
    unit_amount_decimal: string
    recurring: StripeRecurring
};

export const Discount: React.FC<any> = (params: any) => {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();
    const { currentCohort } = useGetPageInfo();

    const getSpecialPlan = () => {
        const request: GetPrices = {
            configId: configId,
            cohortId: currentCohort + "_special"
        }

        let selectedPlan: StripePrice;

        stripeService.getPrices(request).then(plans => plans[0])
            .then(plan => selectedPlan = plan)
            .then(_ => getUserId())
            .then(userId => userService.updateUser({
                _id: userId,
                configId: configId,
                plan: selectedPlan
            })).then((_) => {
            if (selectedPlan) {
                window.sessionStorage.setItem("selectedPriceId", selectedPlan.id)
                window.sessionStorage.setItem("discountApplied", "$ 1.99 Yearly / 50% Off")
            }
        }).then(((_) => navigate("/v3/checkout")));
    }

    return (
        <>
            <PageContainer>
                <S.Container>
                    <HeaderContainer backArrowHidden={ true } progressHidden={ true }
                                     cancelEnabled={ true } onClick={ () => navigate("/") }/>
                    <S.Header>
                        <S.HeaderText>
                            { t('discount.title') }
                        </S.HeaderText>
                    </S.Header>
                    <S.Body>
                        <S.Graph/>
                        <S.ButtonContainer>
                            <RippleButton onClick={ getSpecialPlan }>
                                { t('discount.getMyPlan') }
                            </RippleButton>
                        </S.ButtonContainer>

                        <S.AgreementText>
                                { t('discount.agreementText') }
                        </S.AgreementText>

                        <S.WhatNextContainer>
                            <S.WhatNextHeading>
                                { t('discount.whatsNextHeading') }
                            </S.WhatNextHeading>
                            <S.WhatNextBulletContainer>
                                <S.WhatNextBulletPoint>
                                    <S.Dot></S.Dot>
                                    <S.BulletContent>
                                        { t('discount.whatsNextBullet1') }
                                    </S.BulletContent>
                                </S.WhatNextBulletPoint>
                                <S.WhatNextBulletPoint>
                                    <S.Dot></S.Dot>
                                    <S.BulletContent>
                                        { t('discount.whatsNextBullet2') }
                                    </S.BulletContent>
                                </S.WhatNextBulletPoint>
                                <S.WhatNextBulletPoint>
                                    <S.Dot></S.Dot>
                                    <S.BulletContent>
                                        { t('discount.whatsNextBullet3') }
                                    </S.BulletContent>
                                </S.WhatNextBulletPoint>
                                <S.WhatNextBulletPoint>
                                    <S.Dot></S.Dot>
                                    <S.BulletContent>
                                        { t('discount.whatsNextBullet4') }
                                    </S.BulletContent>
                                </S.WhatNextBulletPoint>
                                <S.WhatNextBulletPoint>
                                    <S.Dot></S.Dot>
                                    <S.BulletContent>
                                        { t('discount.whatsNextBullet5') }
                                    </S.BulletContent>
                                </S.WhatNextBulletPoint>
                            </S.WhatNextBulletContainer>
                        </S.WhatNextContainer>
                    </S.Body>
                </S.Container>
            </PageContainer>
        </>
    );
}

