export enum Cohort {
    LGTOWN_SHORT_ONE = "def",
    LGTOWN_SHORT_TWO = "two",
    LGTOWN_LONG_ONE = "long",
    LGTOWN_LONG_THIRD = "v3",
    LGTOWN_LONG_FOURTH_v1 = "v4"
}

export const DEFAULT_COHORT = Cohort.LGTOWN_SHORT_ONE;

export const DELAY = 300
