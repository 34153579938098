import React, { useMemo } from 'react';
import { TPageProps } from "../TPageProps";
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import { QuestionTitle } from "src/components/QuestionTitle/QuestionTitle";
import { Option, TOptionPropsBase } from "src/components/Option/Option";

import readingAndWriting_icon from 'src/assets/InterestedInAspects/ic_reading.svg'
import speakingAndConversation_icon from 'src/assets/InterestedInAspects/ic_speaking.svg'
import socialContext_icon from 'src/assets/InterestedInAspects/ic_social_context.svg'
import professionalContext_icon from 'src/assets/InterestedInAspects/ic_professional_context.svg'
import mediaConsumption_icon from 'src/assets/InterestedInAspects/ic_media_consumption.svg'
import { usePostDelay } from "../../../hooks/usePostDelay";
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import { QuestionContainer } from "../../../components/QuestionContainer/QuestionContainer";
import userService, { getUserId } from "../../../services/user.service";
import { Toolbar } from "../../../components/Toolbar/Toolbar";
import { useGetPageInfo } from "../../../hooks/useGetPageInfo";
import { DividedToolbar } from "../../../components/Toolbar/DividedToolbar";

export const InterestedInAspects: React.FC<TPageProps> = (params: TPageProps) => {
    const handleContinue = useNextStep(params)
    const { i18n, t } = useTranslation();
    const { isOptionsDisabled, handleChange, setIsOptionsDisabled } = usePostDelay()
    const { currentCohort } = useGetPageInfo()

    const props = useMemo<TOptionPropsBase>(
        () => ({
            disabled: isOptionsDisabled,
            onUpdated: (value: string) => {
                setIsOptionsDisabled(true)
                getUserId().then(userId => userService.pushAnswer(userId, {
                    question: params.pageId,
                    answer: value
                }))
                handleChange(value, handleContinue)
            }
        }), [isOptionsDisabled, setIsOptionsDisabled, handleChange, handleContinue, params.pageId]
    )

    return (
        <>
            { currentCohort !== 'v3' && <Toolbar progressValue={ 3 } pagesNumberWithNavigation={ 6 }></Toolbar> }
            { currentCohort === 'v3' &&
                <DividedToolbar index={ 0 } progressValue={ 3 } pagesNumberWithNavigation={ 6 }></DividedToolbar>
            }
            <PageContainer>
                <QuestionContainer>
                    <QuestionTitle>{ t('interestedInAspects.title') }</QuestionTitle>
                    { currentCohort === 'v3' && <>
                        <Option { ...props } icon={ speakingAndConversation_icon }
                                title={ t('interestedInAspects.speakingAndConversation.title') }
                                id={ 'speakingAndConversation' }/>
                        <Option { ...props } icon={ readingAndWriting_icon }
                                title={ t('interestedInAspects.readingAndWriting.title') }
                                id={ 'readingAndWriting' }/>
                        <Option { ...props } icon={ socialContext_icon }
                                title={ t('interestedInAspects.socialContext.title') }
                                id={ 'socialContext' }/>
                        <Option { ...props } icon={ professionalContext_icon }
                                title={ t('interestedInAspects.professionalContext.title') }
                                id={ 'professionalContext' }/>
                        <Option { ...props } icon={ mediaConsumption_icon }
                                title={ t('interestedInAspects.mediaConsumption.title') }
                                id={ 'mediaConsumption' }/>
                    </>
                    }

                    { currentCohort !== 'v3' && <>
                        <Option { ...props } icon={ speakingAndConversation_icon }
                                title={ t('interestedInAspects.speakingAndConversation.title') }
                                id={ 'speakingAndConversation' }
                                desc={ t('interestedInAspects.speakingAndConversation.desc') }/>
                        <Option { ...props } icon={ readingAndWriting_icon }
                                title={ t('interestedInAspects.readingAndWriting.title') }
                                id={ 'readingAndWriting' }
                                desc={ t('interestedInAspects.readingAndWriting.desc') }/>
                        <Option { ...props } icon={ socialContext_icon }
                                title={ t('interestedInAspects.socialContext.title') }
                                id={ 'socialContext' }
                                desc={ t('interestedInAspects.socialContext.desc') }/>
                        <Option { ...props } icon={ professionalContext_icon }
                                title={ t('interestedInAspects.professionalContext.title') }
                                id={ 'professionalContext' }
                                desc={ t('interestedInAspects.professionalContext.desc') }/>
                        <Option { ...props } icon={ mediaConsumption_icon }
                                title={ t('interestedInAspects.mediaConsumption.title') }
                                id={ 'mediaConsumption' }
                                desc={ t('interestedInAspects.mediaConsumption.desc') }/>
                    </>
                    }

                </QuestionContainer>
            </PageContainer>
        </>
    );
}

