import styled from 'styled-components'

export const StyledQuestionTitle = {
    TitleContainer: styled.div`
      font: normal normal 800 24px/30px NunitoExtraBold;
      text-align: center;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,
}
