import styled, { css } from 'styled-components'
import ic_close_subscription from "../../assets/plans/ic_close_subscription.svg";

const TextAligned = styled.div<{hidden?: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${ props => props.hidden && css`
        display: none;
      ` }
`;

export const StyledToolbar = {
    ProgressContainer: styled.header<{hidden: boolean}>`
      display: flex;
      flex-direction: row;
      justify-content: center;
      ${ props => props.hidden && css`
        display: none;
      ` }
    `,
    HeaderContainer: styled.header`
      margin-left: 8px;
      margin-right: 8px;
      padding: 12px;
      display: flex;
    `,
    BackArrow: styled.div`
      width: 36px;
      height: 36px;
      cursor: pointer;
    `,
    CancelButton: styled.div`
      width: 36px;
      height: 36px;
      cursor: pointer;
      background: transparent url(${ ic_close_subscription }) 0% 0% no-repeat padding-box;
    `,
    NameContainer: styled(TextAligned)`
      width: 100%;
    `,
    Icon: styled.div`
      width: 30px;
      height: 30px;
      margin-right: 7px;
    `,
    ProgressText: styled(TextAligned)`
      color: #8CA6C5;
      font-size: 16px;
    `,
    Title: {
        Normal: styled(TextAligned)`
          text-align: left;
          font: normal normal 600 14px/19px NunitoSemibold;
          letter-spacing: 0;
          color: #3A3A3A;
        `,
        Bold: styled(TextAligned)`
          text-align: left;
          font: normal normal 800 14px/19px NunitoExtrabold;
          letter-spacing: 0;
          color: #3A3A3A;
        `,
    },
    Empty: styled.div`
      width: 15%;
      height: 10px;
    `,
    DiscountContainer: styled.div`
        width: 100%;
    `,
    DiscountText: styled.p`
      text-align: right;
      font: normal normal normal 14px/16px NunitoExtrabold;
      letter-spacing: 0;
      color: #3A3A3A;
    `,
    DiscountPriceText: styled.p`
      text-align: right;
      font: normal normal normal 14px/16px NunitoBold;
      letter-spacing: 0;
      color: #3A3A3A;
    `
}
