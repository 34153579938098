import React from 'react';
import { TPageProps } from "../TPageProps";
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import { Toolbar } from "../../../components/Toolbar/Toolbar";
import { StyledGoodJob as S } from './GoodJob.styles'
import { CommonStyles } from "../../../styles/common.styles";
import RippleButton from "../../../components/Button/RippleButton";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { HeaderContainer } from "../../../components/Toolbar/HeaderContainer";

export const GoodJob: React.FC<TPageProps> = (params: TPageProps) => {
    const handleContinue = useNextStep(params);
    const [width, height] = useWindowSize();
    const { i18n, t } = useTranslation();

    return (
        <PageContainer>
            <HeaderContainer progressHidden={ true }/>
            <S.GoodJobContainer>
                <S.TitleContainer>
                    <S.TitleText>
                        { t('goodJob.title1') }
                    </S.TitleText>
                    <S.DescContainer>
                        <S.DescText>
                            { t('goodJob.desc') }
                        </S.DescText>
                    </S.DescContainer>
                </S.TitleContainer>
                <S.BodyContainer>
                    <S.ImageContainer/>
                    <S.ButtonContainer>
                        <CommonStyles.DynamicButtonContainer height={ height } maxHeight={ 700 }>
                            <RippleButton onClick={ () => {
                                handleContinue("ok")
                            } } disabled={ false }>
                                { t('common.actions.start') }
                            </RippleButton>
                        </CommonStyles.DynamicButtonContainer>
                    </S.ButtonContainer>
                </S.BodyContainer>
            </S.GoodJobContainer>
        </PageContainer>

    );
}

