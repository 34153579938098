import React, { useMemo, useState } from 'react';
import { TPageProps } from "../TPageProps";
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import userService, { getUserId } from "../../../services/user.service";
import {
    MultiSelectQuestionContainer,
    MultiSelectQuestionContainerProps
} from "../../../components/QuestionContainer/MultiSelectQuestionContainer";
import h1530 from 'src/assets/languageLevel/ic_15_30.svg'
import h1 from 'src/assets/languageLevel/ic_1_h.svg'
import h2 from 'src/assets/languageLevel/ic_2_h.svg'
import h2plus from 'src/assets/languageLevel/ic_2_h_plus.svg'
import { HeaderContainer } from "../../../components/Toolbar/HeaderContainer";


export const HowHard: React.FC<TPageProps> = (params: TPageProps) => {
    const handleContinue = useNextStep(params);
    const { i18n, t } = useTranslation();
    const [question, setQuestion] = useState(t('howHard.question'))
    const [answers, setAnswers] = useState([t('howHard.a1'), t('howHard.a2'), t('howHard.a3')])


    const props = useMemo<MultiSelectQuestionContainerProps>(
        () => ({
            question: question,
            answers: answers,
            icons: [h1530, h1, h2, h2plus],
            onClick: (value: any) => {
                setTimeout(() => {
                    getUserId().then(userId => userService.pushAnswer(userId, {
                        question: params.pageId,
                        answer: value
                    }))
                    handleContinue(params.pageId);
                }, 100)
            },
        }), [handleContinue, params.pageId]
    )

    return (
        <>
            <PageContainer>
                <HeaderContainer progressHidden={ true }/>
                <MultiSelectQuestionContainer { ...props }>

                </MultiSelectQuestionContainer>
            </PageContainer>
        </>

    );
}

