import React from 'react';
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import icons from "./images"

import userService, { getUserId } from "../../services/user.service";
import { HeaderStyles as H, PageStyles as P, } from "./Styles";
import Header from "./Header";
import Options from "./Options";

export const HowOftenC4: any = (params: any) => {
    const handleContinue = useNextStep(params)
    const { i18n, t } = useTranslation();


    const onClick = (value: string) => {
        getUserId().then(userId => userService.pushAnswer(userId, {
            question: params.pageId,
            answer: value
        }))

        handleContinue(value)
    }

    const langArray = [
        {
            "icon": icons.oneTime,
            "text": "1 time per week"
        },
        {
            "icon": icons.twoTimes,
            "text": "2 times per week"
        },
        {
            "icon": icons.threeTimes,
            "text": "3 times per week"
        },
        {
            "icon": icons.fourTimes,
            "text": "4 times per week"
        },
        {
            "icon": icons.fiveTimes,
            "text": "5 times per week"
        }
    ]

    return (
        <P.PageContainer>
            <P.Background/>
            <H.HeaderContainer>
                <H.IconContainer icon={ icons.logo }/>
            </H.HeaderContainer>
            <Header title={ "How often would you like to study?" }
                    toolbarPercentage={ "100%" } toolbarText={ "7/7" }/>
            <P.QuestionContainer>
                <Options options={ langArray } onClick={ onClick } maxHeight={600}/>
            </P.QuestionContainer>
        </P.PageContainer>
    );
}

