import React, { useState } from 'react';
import { StyledOption as S } from './Option.styles'
import { DefaultTFuncReturn } from "i18next";

export interface TOptionProps extends TOptionPropsBase {
    id: string,
    icon?: string,
    title?: DefaultTFuncReturn,
    desc?: DefaultTFuncReturn,
}

export interface TOptionPropsBase {
    onUpdated: (value: string) => void,
    disabled?: boolean
}

export const Option: React.FC<TOptionProps> = (params: TOptionProps) => {
    const [isSelected, setSelected] = useState<boolean>(false)

    return (
        <S.OptionContainer selected={ isSelected } onClick={ () => {
            if (params.disabled) {
                return
            }
            setSelected(!isSelected)
            params.onUpdated(params.id)// todo her zaman tetiklenmeli mi bakalim??
        } }>
            <S.IconContainer img={ params.icon }/>
            <S.ContentContainer>
                <S.Title className={ params.title ? undefined : 'hidden' }>
                    { params.title }
                </S.Title>
                <S.Desc className={ params.desc ? undefined : 'hidden' }>{ params.desc }</S.Desc>
            </S.ContentContainer>


        </S.OptionContainer>
    );
}

