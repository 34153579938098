import React, { useState } from 'react';
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import icons from "./images"

import userService, { getUserId } from "../../services/user.service";
import { HeaderStyles as H, PageStyles as P, ToolbarStyles as To, WhatIsYourNameStyles as S } from "./Styles";
import Title from "./Title";
import { DynamicButton } from "./Button";
import { useWindowSize } from "../../hooks/useWindowSize";

export const WhatIsYourNameC4: any = (params: any) => {
    const handleContinue = useNextStep(params)
    const [answer, setAnswer] = useState("");
    const { i18n, t } = useTranslation();
    const [width, height] = useWindowSize();

    const onClick = (value: string) => {
        getUserId().then(userId => userService.pushAnswer(userId, {
            question: params.pageId,
            answer: value
        }))

        handleContinue(value)
    }

    return (
        <P.PageContainer>
            <P.Background/>
            <H.HeaderContainer>
                <H.IconContainer icon={ icons.logo }/>
            </H.HeaderContainer>
            <To.ToolbarTitleContainer>
                <Title title={ "What’s your name?" }/>
            </To.ToolbarTitleContainer>
            <S.AnswerContainer>
                <S.AnswerLabelContainer>
                    <S.AnswerLabel>
                        Your Name
                    </S.AnswerLabel>
                </S.AnswerLabelContainer>
                <S.AnswerInput placeholder={ "Username" } value={ answer }
                               onChange={ (e: any) => setAnswer(e.target.value) }>

                </S.AnswerInput>
            </S.AnswerContainer>
            <DynamicButton text={ "NEXT " } onclick={ () => onClick(answer) }
                           disabled={ !answer } maxHeight={ 500 } height={ height } width={ "427px" } maxWidth={ "95vw" }/>

        </P.PageContainer>
    );
}

