import React from 'react';
import { AnswerOverviewStyles as Ao, PageStyles as P, WhatIsYourNameStyles as S } from "./Styles";

export interface AnswersOverviewProps {
    answers: any[]
}

export const AnswersOverview = (props: AnswersOverviewProps) => {

    const questions = ["Language to Learn:", "Your current level", "Your motivation", "Your aspects to improve"]
    return (
        <Ao.Container>
            <P.Background/>
            <Ao.TitleContainer>
                <Ao.Title>
                    Your answers overview
                </Ao.Title>
            </Ao.TitleContainer>

            { props.answers.map((answer: any, index: number) => (
                <Ao.AnswerContainer>
                    <Ao.AnswerContainer>
                        <S.AnswerLabelContainer>
                            <S.AnswerLabel>
                                { questions[index] }
                            </S.AnswerLabel>
                        </S.AnswerLabelContainer>
                        <Ao.AnswerInputContainer>
                            { answer.map((a: string) => (
                                <Ao.AnswerInput>
                                    { a }
                                </Ao.AnswerInput>
                            ))
                            }
                        </Ao.AnswerInputContainer>
                    </Ao.AnswerContainer>
                </Ao.AnswerContainer>
            ))
            }

        </Ao.Container>
    );
}

