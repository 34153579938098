import React from 'react';
import {StyledQuestionTitle as S} from './QuestionTitle.styles'
import {TComponentProps} from "../../models/TComponentProps";

export const QuestionTitle: React.FC<TComponentProps> = (params: TComponentProps) => {
    return (
        <S.TitleContainer>
            {params.children}
        </S.TitleContainer>
    );
}

