import * as Sentry from "@sentry/react";
import { isTest } from "../config";

export const initializeSentry = async (dsn: string) => {
    if (isTest()) {
        return Promise.resolve();
    }

    return Sentry.init({
        dsn: dsn,
        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

export const captureObject = (err: any) => {
    isTest() && Sentry.captureException(new Error(JSON.stringify(err)));
}

export const captureException = (err: any) => {
    isTest() && Sentry.captureException(err);
}
