import styled, { css } from 'styled-components'

export const StyledGrammarTest = {
    GrammarTestTextContainer: styled.div`
      margin-bottom: 10%;
    `,

    QuestionContainer: styled.div`
    `,
    QuestionText: styled.p`
      text-align: center;
      font: normal normal 800 24px/30px NunitoExtraBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,
    DescContainer: styled.div`,
    `,
    DescText: styled.p`
      margin-top: 5%;
      text-align: center;
      font: normal normal normal14px/16px NunitoRegular;
      letter-spacing: 0px;
      color: #8CA6C5;
      opacity: 1;
    `,
    AnswersContainer: styled.div`
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    `,
    AnswerContainer: styled.div<{ selected: boolean }>`
      flex: 1 0 calc(25% - 10px);
      margin: 5px;
      height: 41px;
      width: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      vertical-align: middle;
      border-radius: 10px;

      ${ props => props.selected && css`
        background-color: #FFFFFF;
        border: 2px solid #49A8FB;
      ` }
      ${ props => !props.selected && css`
        background-color: #EBF7FF;
        
      ` }
    `,
    AnswerText: styled.p`
      text-align: center;
      font: normal normal normal 16px/20px NunitoBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,
    ButtonContainer: styled.div`
      margin-top: 30px;
    `,
    WordQuestionsContainer: styled.div`
        height: 800px;
    `,
}
