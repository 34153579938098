import { useCallback, useState } from 'react'
import {DELAY} from "../constants/rootConstants";

export const usePostDelay = (): {
    isOptionsDisabled: boolean
    handleChange: (
        answer: string | string[] | null,
        handleContinue: (answer: string | string[] | null) => void,
    ) => void
    setIsOptionsDisabled: (value: boolean) => void
} => {
    const [isOptionsDisabled, setIsOptionsDisabled] = useState<boolean>(false)

    const handleChange = useCallback((answer: string | string[] | null, handleContinue: (answer: string | string[] | null) => void) => {
        setTimeout(() => {
            handleContinue(answer)
        }, DELAY)
    }, [])

    return { isOptionsDisabled, handleChange, setIsOptionsDisabled }
}
