import React from 'react'
import styled, { css } from 'styled-components'

const MainContainer = styled.div`
  width: 100%;
  max-width: 393px;
  margin: 0 auto;
`

const StepContainer = styled.div<{ width: any }>`
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: 25px;
  margin-left: 40px;
  margin-right: 40px;

  :before {
    content: '';
    position: absolute;
    background: #E5F1FE;
    height: 3px;
    width: 100%;
    top: 84%;
    transform: translateY(-50%);
    left: 0;
  }

  :after {
    content: '';
    position: absolute;
    background: #49A8FB;
    height: 3px;
    width: ${ props => props.width };
    top: 84%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`

const StepWrapper = styled.div`
  position: relative;
  margin-top: 10px;
  z-index: 1;
`

const StepStyle = styled.div<{ step: any }>`
  width: 18px;
  height: 18px;
  margin-top: 28px;
  border-radius: 50%;
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  ${ props => props.step === 'completed' && css`
    background-color: #49A8FB;
    border: 3px solid #49A8FB;
  ` }

  ${ props => props.step === 'incomplete' && css`
    background-color: #E5F1FE;
    border: 3px solid #E5F1FE;
  ` }

  ${ props => props.step === 'current' && css`
    background-color: #FFFFFF;
    border: 2px solid #49A8FB;
  ` }
`

const StepCount = styled.span`
  color: #f3e7f3;
`

const StepsLabelContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`

const StepLabel = styled.span`
  text-align: center;
  font: normal normal normal 12px/16px NunitoRegular;
  letter-spacing: 0px;
  color: #8CA6C5;
  opacity: 1;
`

const CheckMark = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: #FFFFFF;
  -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
  -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-46deg);
`

const steps = [
    {
        label: 'Personalized lesson',
        step: 1,
    },
    {
        label: 'Get plan',
        step: 2,
    },
    {
        label: 'Payment',
        step: 3,
    },
    {
        label: 'Create account',
        step: 4,
    },
    {
        label: 'Access plan',
        step: 5,
    },
]

const ProgressSteps = ({ activeStep }: { activeStep: number }) => {
    const totalSteps = steps.length
    const width = `${ (100 / (totalSteps - 1)) * (activeStep - 1) }%`


    return (
        <MainContainer>
            <StepContainer width={ width }>
                { steps.map(({ step, label }) => (
                    <StepWrapper>
                        <StepsLabelContainer>
                            <StepLabel>{ label }</StepLabel>
                        </StepsLabelContainer>
                        {
                            activeStep === step &&
                            <StepStyle step='current'>
                            </StepStyle>
                        }
                        {
                            activeStep < step &&
                            <StepStyle step='incomplete'>
                                <StepCount></StepCount>
                            </StepStyle>
                        }
                        {
                            activeStep > step &&
                            <StepStyle step='completed'>
                                <CheckMark>L</CheckMark>
                            </StepStyle>
                        }
                    </StepWrapper>
                )) }
            </StepContainer>
        </MainContainer>
    )
}

export default ProgressSteps
