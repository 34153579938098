import React, {createContext, useReducer} from "react";

const convertToArray = (x: string | string[] | null) => (x === null ? [""] : (Array.isArray(x) ? x : [x]));

export const setAnswer = (
    pageId: string, answer: string | string[] | null): {
    type: "SET_ANSWER";
    payload: {
        pageId: string;
        answer: string[] | null;
    };
} => ({
    type: "SET_ANSWER",
    payload: {
        pageId: pageId,
        answer: convertToArray(answer)
    }
});


export type Action = ReturnType<typeof setAnswer>;
let initialAnswersMap = {}
let data = window.sessionStorage.getItem('answerKey')
if (data) {
    initialAnswersMap = JSON.parse(data);
}
export const initialState = {
    answers: initialAnswersMap
};

export type AnswersContext = {
    answers: { [p: string]: string[] | null }
};

type Context = {
    state: AnswersContext;
    dispatch: React.Dispatch<Action>;
};

export const AppContext = createContext<Context>({} as Context);

export const reducer = (state: AnswersContext, action: Action) => {
    switch (action.type) {
        case "SET_ANSWER":
            state.answers[action.payload.pageId] = action.payload.answer
            window.sessionStorage.setItem('answerKey', JSON.stringify(state.answers));
            return {...state};
        default:
            return state;
    }
};

export const Provider = (props: any) => {
    const [state, dispatch] = useReducer<React.Reducer<AnswersContext, Action>>(
        reducer,
        initialState
    );
    return (
        <AppContext.Provider value={{state, dispatch}}>
            {props.children}
        </AppContext.Provider>
    )
}
