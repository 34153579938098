import React, { useEffect, useState } from 'react';
import { Styled as S } from './Plans.styles'
import { TPageProps } from "../TPageProps";
import { useNextStep } from "src/hooks/useNextStep";
import { useNavigate } from "react-router-dom";
import { GetPrices } from "../../../api/dto";
import { configId } from "../../../config";
import icon from "../../../assets/common/icon_splash.jpg"
import { useTranslation } from "react-i18next";
import RippleButton from "../../../components/Button/RippleButton";
import { CommonStyles } from "../../../styles/common.styles";
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import getSymbolFromCurrency from 'currency-symbol-map'
import stripeService from "../../../services/stripe.service";
import userService, { getUserId } from "../../../services/user.service";
import { firebaseService } from "../../../services/firebase.service";
import { fbq } from "../../../services/metapixel.service";
import { captureException } from "../../../services/sentry.service";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useGetPageInfo } from "../../../hooks/useGetPageInfo";
import { CarouselProvider, Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { useWindowSize } from "../../../hooks/useWindowSize";
import { HeaderContainer } from "../../../components/Toolbar/HeaderContainer";

type StripeRecurring = {
    aggregate_usage: string
    interval: string
    interval_count: number
    trial_period_days: number
    usage_type: string
}

type StripePrice = {
    id: string
    active: boolean
    currency: string
    unit_amount: number
    unit_amount_decimal: string
    recurring: StripeRecurring
};

let weekMap = new Map<string, number>([
    ["day", 0.7],
    ["week", 1],
    ["month", 4.3], // 4.3 = 52/12
    ["year", 52],
]);

function getWeekCount(recurring: string): number {
    const weekCount = weekMap.get(recurring)
    if (weekCount) {
        return weekCount
    }
    return 1;
}


export const Plans: React.FC<TPageProps> = (params: TPageProps) => {
    const { i18n, t } = useTranslation();
    const [plans, setPlans] = useState(Array<StripePrice>)
    const [selectedPlan, setSelectedPlan] = useState<StripePrice>()
    const navigate = useNavigate();
    const handleContinue = useNextStep(params);
    const { currentCohort } = useGetPageInfo();
    const [width, height] = useWindowSize();


    useEffect(() => {
        const request: GetPrices = {
            configId: configId,
            cohortId: currentCohort
        }

        stripeService.getPrices(request).then(plans => setPlans(plans))
            .catch(err => {
                captureException(err);
                toast('Please try again later.')
            })
    }, []);

    const selectPlan = (e: any) => {
        e.preventDefault();
        if (selectedPlan) {
            firebaseService.logFirebaseEvent("getmyplan_button_clicked")
            fbq('trackCustom', 'getmyplan_button_clicked')
            fbq('track', 'AddToCart')

            getUserId().then(userId => userService.updateUser({
                _id: userId,
                configId: configId,
                plan: selectedPlan
            })).then((_) => window.sessionStorage.setItem("selectedPriceId", selectedPlan.id))
                .then(((_) => handleContinue(selectedPlan.id)));
        }
    }

    let actives = plans.filter(i => i.active);
    let weeklyPrices = actives.sort((a, b) => {
        const weeklyA = a.unit_amount / (a.recurring.interval_count * getWeekCount(a.recurring.interval))
        const weeklyB = b.unit_amount / (b.recurring.interval_count * getWeekCount(b.recurring.interval))
        return weeklyA - weeklyB
    });
    let bestPrice = weeklyPrices[0]; // cheapest weekly
    useEffect(() => {
        setSelectedPlan(bestPrice)
    }, [bestPrice])

    return (
        <>
            { currentCohort === 'v3' &&
                <HeaderContainer progressHidden={ true } backArrowHidden={ true } cancelEnabled={ true }
                                 onClick={ () => navigate("/v3/statistics") }/> }
            { currentCohort !== 'v3' && <HeaderContainer progressHidden={ true }/> }

            <PageContainer>
                <ToastContainer/>
                <S.PlansContainer cohort={ currentCohort }>
                    <S.TitleContainer>
                        { t('plans.welcome') } {window.sessionStorage.getItem('name')}
                    </S.TitleContainer>
                    <S.GraphicContainer cohort={ currentCohort }>

                    </S.GraphicContainer>
                    <S.CommentsContainer>
                        <CarouselProvider isPlaying={ true } interval={ 3000 }
                                          naturalSlideWidth={ 343 }
                                          naturalSlideHeight={ 190 }
                                          totalSlides={ 3 }
                                          touchEnabled={ true }>
                            <Slider>
                                <Slide index={ 0 }>
                                    <S.CommentContainer>
                                        <S.CommentHeader>
                                            <S.CommentImg img={ icon }/>
                                            <S.CommentTitle>
                                                <S.CommentTitleText>
                                                    { t('plans.comments.c1.title') }
                                                </S.CommentTitleText>
                                                <S.CommentDetailsText>
                                                    { t('plans.comments.c1.subtitle') }
                                                </S.CommentDetailsText>
                                            </S.CommentTitle>
                                        </S.CommentHeader>
                                        <S.CommentDetails>
                                            { t('plans.comments.c1.text') }
                                        </S.CommentDetails>
                                        <S.StarContainer>
                                            <S.Star/>
                                            <S.Star/>
                                            <S.Star/>
                                            <S.Star/>
                                            <S.Star/>
                                        </S.StarContainer>
                                    </S.CommentContainer>
                                </Slide>
                                <Slide index={ 1 }>
                                    <S.CommentContainer>
                                        <S.CommentHeader>
                                            <S.CommentImg img={ icon }/>
                                            <S.CommentTitle>
                                                <S.CommentTitleText>
                                                    { t('plans.comments.c2.title') }
                                                </S.CommentTitleText>
                                                <S.CommentDetailsText>
                                                    { t('plans.comments.c2.subtitle') }
                                                </S.CommentDetailsText>
                                            </S.CommentTitle>
                                        </S.CommentHeader>
                                        <S.CommentDetails>
                                            { t('plans.comments.c2.text') }
                                        </S.CommentDetails>
                                        <S.StarContainer>
                                            <S.Star/>
                                            <S.Star/>
                                            <S.Star/>
                                            <S.Star/>
                                            <S.Star/>
                                        </S.StarContainer>
                                    </S.CommentContainer>
                                </Slide>
                                <Slide index={ 2 }>
                                    <S.CommentContainer>
                                        <S.CommentHeader>
                                            <S.CommentImg img={ icon }/>
                                            <S.CommentTitle>
                                                <S.CommentTitleText>
                                                    { t('plans.comments.c3.title') }
                                                </S.CommentTitleText>
                                                <S.CommentDetailsText>
                                                    { t('plans.comments.c3.subtitle') }
                                                </S.CommentDetailsText>
                                            </S.CommentTitle>
                                        </S.CommentHeader>
                                        <S.CommentDetails>
                                            { t('plans.comments.c3.text') }
                                        </S.CommentDetails>
                                        <S.StarContainer>
                                            <S.Star/>
                                            <S.Star/>
                                            <S.Star/>
                                            <S.Star/>
                                            <S.Star/>
                                        </S.StarContainer>
                                    </S.CommentContainer>
                                </Slide>
                            </Slider>
                        </CarouselProvider>
                    </S.CommentsContainer>
                    <div style={ { paddingTop: "20px" } }/>
                    <S.BGContainer>
                        <div style={ { paddingTop: "20px" } }/>
                        <S.LessonContainer>
                            <S.LessonDurationContainer>
                                <S.LessonDurationTitle>
                                    { t('plans.duration.title') }
                                </S.LessonDurationTitle>
                            </S.LessonDurationContainer>
                        </S.LessonContainer>
                        <S.OffersContainer>
                            { plans && plans.map((item, index) => {
                                    return (
                                        item.active && (
                                            <div style={ { position: "relative" } } key={ item.id }>
                                                { bestPrice.id === item.id && (
                                                    <>
                                                        <S.BestOfferContainer>
                                                            <S.BestOfferText>{ t('plans.trial.best_offer') }</S.BestOfferText>
                                                        </S.BestOfferContainer>
                                                    </>
                                                ) }
                                                { item.recurring.trial_period_days &&
                                                    (
                                                        <>
                                                            <S.TrialBadgeContainer>
                                                                <S.TrialBadgeText>{ item.recurring.trial_period_days } { t('plans.trial.trial_days') }</S.TrialBadgeText>
                                                            </S.TrialBadgeContainer>
                                                        </>
                                                    )
                                                }

                                                <S.OfferContainer
                                                    selected={ selectedPlan?.id === item.id }
                                                    onClick={ () => {
                                                        selectedPlan?.id === item.id ? setSelectedPlan(undefined) : setSelectedPlan(item)
                                                    } }>
                                                    <S.OfferTitle>
                                                        { item.recurring.interval_count } { item.recurring.interval }
                                                    </S.OfferTitle>
                                                    <S.OfferPrice>
                                                        { getSymbolFromCurrency(item.currency) }{ Math.round(item.unit_amount / 100 * 100) / 100 }
                                                    </S.OfferPrice>
                                                    <S.CheckBox selected={ selectedPlan?.id === item.id }/>
                                                </S.OfferContainer>

                                            </div>
                                        )
                                    );
                                }
                            )
                            }
                        </S.OffersContainer>
                    </S.BGContainer>
                    <S.TrialContainer>
                        <S.TrialHeading>
                            { t('plans.trial.title') }
                        </S.TrialHeading>
                        <S.TrialBulletContainer>
                            <S.TrialBulletPoint>
                                <S.TrialDot></S.TrialDot>
                                <S.TrialBulletContent>
                                    { t('plans.trial.content') }
                                </S.TrialBulletContent>
                            </S.TrialBulletPoint>
                            <S.TrialBulletPoint>
                                <S.TrialDot></S.TrialDot>
                                <S.TrialBulletContent>
                                    { t('plans.trial.text1') }
                                </S.TrialBulletContent>
                            </S.TrialBulletPoint>
                            <S.TrialBulletPoint>
                                <S.TrialDot/>
                                <S.TrialBulletContent>
                                    { t('plans.trial.text2') }
                                </S.TrialBulletContent>
                            </S.TrialBulletPoint>
                            <S.TrialBulletPoint>
                                <S.TrialDot/>
                                <S.TrialBulletContent>
                                    { t('plans.trial.text3') }
                                </S.TrialBulletContent>
                            </S.TrialBulletPoint>
                        </S.TrialBulletContainer>
                    </S.TrialContainer>
                    { currentCohort === 'v3' &&
                        <S.MoneyBackContainer>
                            <S.MoneyBackHeaderContainer>
                                <S.MoneyBackHeaderText>
                                    { t('plans.moneyBack.title') }
                                </S.MoneyBackHeaderText>
                            </S.MoneyBackHeaderContainer>
                            <S.MoneyBackDescContainer>
                                <S.MoneyBackDescTextContainer>
                                    <S.MoneyBackDescText>
                                        { t('plans.moneyBack.desc') }
                                        <S.MoneyBackDescTextBlue> { t('plans.moneyBack.desc2') }</S.MoneyBackDescTextBlue>
                                    </S.MoneyBackDescText>
                                </S.MoneyBackDescTextContainer>
                                <S.MoneyBackImageContainer>
                                </S.MoneyBackImageContainer>
                            </S.MoneyBackDescContainer>
                        </S.MoneyBackContainer>
                    }
                    <S.AgreementText>
                        <div>{ selectedPlan && t('plans.agreement', {
                            price: getSymbolFromCurrency(selectedPlan.currency) + '' + selectedPlan.unit_amount / 100,
                            duration: `every ${ selectedPlan.recurring.interval_count + ' ' + selectedPlan.recurring.interval }`
                        }) }</div>
                        <S.LinksContainer>
                            <span onClick={ () => navigate("/tos") }>Terms of service</span> { ' | ' }
                            <span onClick={ () => navigate("/pp") }>Privacy Policy</span>
                        </S.LinksContainer>
                    </S.AgreementText>
                    <CommonStyles.DynamicButtonContainer height={ height } maxHeight={ 1225 }>
                        <RippleButton onClick={ selectPlan } disabled={ !selectedPlan }>
                            { t('plans.getMyPlan') }
                        </RippleButton>
                    </CommonStyles.DynamicButtonContainer>

                </S.PlansContainer>
            </PageContainer>
        </>
    );
}

export default Plans;
