import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
    lng: "en",
    fallbackLng: "en",
    interpolation: {
        escapeValue: false,
    },
    resources: {
        en: {
            translation: {
                common: {
                    actions: {
                        next: 'Next',
                        continue: 'Continue',
                        cancel: 'Cancel',
                        download: 'Download',
                        start: 'Start'
                    }
                },
                tutoring: {
                    continue: 'Continue',
                    boldtext: 'Improve Your English with 1x1 tutoring.',
                    desctext: 'Answer The Questions And Get Your Personal Tutoring Program'
                },
                currentLevel: {
                    title: 'What is your current English level?',
                    novice: {
                        title: 'Novice',
                        desc: 'I know a few basic words and phrases.',
                    },
                    elementary: {
                        title: 'Elementary',
                        desc: 'I can communicate about simple everyday topics.',
                    },
                    preIntermediate: {
                        title: 'Pre-Intermediate',
                        desc: 'I can converse about everyday topics and some complex topics.',
                    },
                    intermediate: {
                        title: 'Intermediate',
                        desc: 'I can discuss most topics, but lack confidence.',
                    },
                    upperIntermediate: {
                        title: 'Upper-Intermediate',
                        desc: 'I can communicate confidently with non-native speakers.',
                    },
                    advanced: {
                        title: 'Advanced',
                        desc: 'I am fluent, but always strive to improve.',
                    },
                },
                experienceSoFar: {
                    title: 'What has been your experience with learning English so far?',
                    new: {
                        title: 'Completely New',
                        desc: 'I have never studied English before.',
                    },
                    school: {
                        title: 'School Days',
                        desc: 'I studied English in school, but haven\'t practiced much since then.',
                    },
                    immersion: {
                        title: 'Immersion',
                        desc: 'I have been exposed to English through immersion, but need to improve my skills.',
                    },
                    selfTaught: {
                        title: 'Self-Taught',
                        desc: 'I have taught myself English, but want to improve my skills further.',
                    },
                },
                noteTaking: { title: 'Do you take notes while learning something new?', yes: 'Yes', no: 'No' },
                speakingEnvironment: {
                    title: 'Are you living in an English speaking environment?',
                    yes: 'Yes',
                    no: 'No'
                },
                timeSpent: {
                    title: 'How long have you been learning English?',
                    _6orLess: {
                        title: 'Less than 6 months'
                    },
                    _6to12: {
                        title: '6 months to 1 year',
                    },
                    _12to24: {
                        title: '1 to 2 years',
                    },
                    _24to36: {
                        title: '2 to 3 years',
                    },
                    _36orMore: {
                        title: 'More than 3 years',
                    },
                },
                interestedInAspects: {
                    title: 'What aspects of English language and culture are you most interested in?',
                    speakingAndConversation: {
                        title: 'Speaking and Conversation',
                        desc: 'I want to improve my ability to speak and have conversations in English.',
                    },
                    readingAndWriting: {
                        title: 'Reading and Writing',
                        desc: 'I want to improve my ability to read and write in English.',
                    },
                    socialContext: {
                        title: 'Social Context',
                        desc: 'I want to learn how to communicate effectively in social settings with English speakers.',
                    },
                    professionalContext: {
                        title: 'Professional Context',
                        desc: 'I want to learn how to communicate effectively in professional settings with English speakers.',
                    },
                    mediaConsumption: {
                        title: 'Media Consumption',
                        desc: 'I want to improve my ability to consume English-language media.',
                    },
                },
                interestedInReason: {
                    title: 'Why are you interested in learning English?',
                    desc: 'You can select multiple options',
                    careerAdvancement: {
                        title: 'Career Advancement',
                        desc: 'I want to improve my job prospects by having strong English skills.',
                    },
                    travel: {
                        title: 'Travel',
                        desc: 'I want to be able to communicate easily when traveling to English-speaking countries.',
                    },
                    academicPursuits: {
                        title: 'Academic Pursuits',
                        desc: 'I want to study in an English-speaking institution.',
                    },
                    mediaConsumption: {
                        title: 'Media Consumption',
                        desc: 'I want to enjoy English-language movies, TV shows, and music.',
                    },
                    socialConnections: {
                        title: 'Social Connections',
                        desc: 'I want to connect with English speakers and improve my communication skills.',
                    },
                    globalCitizenship: {
                        title: 'Global Citizenship',
                        desc: 'I want to be a part of the global community and communicate with different people.',
                    },
                },
                focusTopics: {
                    title: 'What are some topics or areas that you would like to focus on in your English learning?',
                    geographyAndTravel: {
                        title: 'Geography and travel'
                    },
                    entertainmentAndPopCulture: {
                        title: 'Entertainment and pop culture'
                    },
                    scienceAndTechnology: {
                        title: 'Science and technology'
                    },
                    academicOrProfessionalVocabulary: {
                        title: 'Academic or professional vocabulary'
                    },
                    socialOrCulturalIssues: {
                        title: 'Social or cultural issues'
                    },
                    foodAndCooking: {
                        title: 'Food and cooking'
                    },
                },
                preferredLearningMethod: {
                    title: 'How do you prefer to learn English?',
                    textbooksAndWrittenMaterials: {
                        title: 'Through textbooks and written materials',
                    },
                    conversationsAndSpeakingPractice: {
                        title: 'Through conversations and speaking practice',
                    },
                    listeningExercisesAndAudioMaterials: {
                        title: 'Through listening exercises and audio materials',
                    },
                    watchingVideosAndMediaContent: {
                        title: 'Through watching videos and media content',
                    },
                    onlinePlatformsAndApps: {
                        title: 'Through online platforms and apps',
                    },
                },
                email: {
                    title1: 'We need your contact info',
                    title2: 'so that your tutor can reach you',
                    footnote: 'We respect your privacy and are committed to protecting your personal data!',
                    promotional: 'I would like to receive learning materials, promotions, discounts and special offers from Lingutown via email'
                },
                register: {
                    title: 'Create an account to save your preferences',
                    email: 'E-Mail',
                    password: 'Password',
                    signup_options: 'Choose other Sign up options',
                    policy: 'By clicking "Continue" you confirm with our Privacy Policy and Terms of Use',
                },
                payment: {
                    title1: 'Payment',
                    title2: 'information',
                    secure: 'Secure',
                    success: {
                        successful: 'successful',
                        text: 'Your payment was',
                        proceed: 'proceed to your plan',
                    },
                    fail: {
                        unsuccessful: 'unsuccessful',
                        text: 'Your payment was',
                        tryAgain: 'Try Again',
                    },
                    alerts: {
                        processing: 'Your payment is processing.',
                        unsuccessful: 'Your payment was not successful, please try again.',
                        failed: 'Something went wrong.',
                        error: 'An unexpected error occurred.',
                    }
                },
                download: {
                    title: 'Download the app and continue',
                    card: 'Easy way to learn languages',
                },
                plans: {
                    welcome: 'Welcome',
                    agreement: 'By continuing you agree that if you don\'t cancel at least 24 hours prior to the end of the current period, you will automatically be charged {{price}} {{duration}} until you cancel in settings.',
                    duration: {
                        title: 'Choose Your Plan',
                        desc: 'You will be able to change the number of lessons at any time in your personal account',
                    },
                    trial: {
                        best_offer: "BEST OFFER",
                        trial_days: "DAYS FREE TRIAL",
                        title: 'How The Trial Works',
                        content: 'The yearly subscription will activate as soon as the trial period ends.',
                        text1: 'Cancel anytime',
                        text2: 'No commitment',
                        text3: 'If you cancel during the trial period, you will not be charged any fees',
                    },
                    getMyPlan: 'get my plan',
                    comments: {
                        c1: {
                            title: 'So helpful for me!',
                            subtitle: 'Angelica Smith, Mar 28, 2023',
                            text: 'It helped me a lot in developing my foreign language. Thanks to the developers and contributors',
                        },
                        c2: {
                            title: 'Best App',
                            subtitle: 'Charlotte Foster, Apr 15, 2023',
                            text: 'I just wanted to express my heartfelt gratitude to the amazing developers and contributors who have made such a significant impact on my language learning journey.',
                        },
                        c3: {
                            title: 'Very Useful',
                            subtitle: 'Ethan Johnson, May 1, 2023',
                            text: 'Through this incredible platform, I have been able to explore various topics, engage in meaningful conversations, and receive prompt and accurate assistance.',
                        }
                    },
                    moneyBack: {
                        title: "Money-back Guarantee",
                        desc: "We know for sure that after 2 weeks your language\n" +
                            "learning level will increase significantly,\n" +
                            "based on the user feedbacks. However,\n" +
                            "if you think that you are not achieving\n" +
                            "the development you expect,",
                        desc2: "we provide a money-back\n" +
                            "guarantee."
                    }
                },
                analysis: {
                    text1: 'Analyzing your preferences',
                    text2: 'Preparing your grammar analysis',
                    text3: 'Customizing your program',
                },
                goal: {
                    title: 'Your Goal Level:',
                    advanced: {
                        title: 'Advanced',
                        desc: 'To become fluent in English'
                    },
                    proficient: {
                        title: 'Proficient',
                        desc: 'To be a true English expert'
                    }
                },
                nativeLanguage: {
                    title: 'Select your native language'
                },
                goodJob: {
                    title1: 'Excellent Work! Now Complete the English Vocabulary Test',
                    desc: 'Your answers will be used to prepare a customized course plan for you.'
                },
                grammarTest: {
                    title: "vocabulary test",
                },
                wellDone: {
                    title1: "Well done!",
                    desc: "We have a few more questions to help customize your tutoring plan."
                },
                interestingTopics: {
                    title: "You can choose multiple options."
                },
                activityLevel: {
                    question: "What is your current activity level?",
                    a1: "I do some exercises once in a while",
                    a2: "I learn new vocabulary regularly",
                    a3: "I dig into grammar and practice often",
                    a4: "I write, listen and speak English regularly",
                    newbie: "Newbie",
                    pro: "Pro"
                },
                analysisv2: {
                    title1: "Analyzing your preferences",
                    title2: "Preparing your grammar analysis",
                    title3: "Customizing your program",
                    title4: "Optimizing learning activities",
                    title5: "Estimating the assignments needed",
                    title6: "Planning your work schedule"
                },
                analysisv3: {
                    title1: "Selecting additional materials",
                    title2: "Sorting out the exercises",
                    title3: "Preparing your lessons",
                },
                discount: {
                    title: "Discount for you!",
                    getMyPlan: "GET MY PLAN",
                    agreementText: "By continuing you agree that if you don't cancel at least 24 hours prior to the end of the\n" +
                        "current period, you will automatically be charged $131.99 every month until you cancel in\n" +
                        "settings.",
                    whatsNextHeading: "After my order, what is next?",
                    whatsNextBullet1: "The subscription will activate and you'll start to improve your english",
                    whatsNextBullet2: "You will receive a personalized language learning plan",
                    whatsNextBullet3: "Interactive and funny learning method",
                    whatsNextBullet4: "Exercises and activities focused on daily practices",
                    whatsNextBullet5: "Clear overview of learning progress",
                    discountedPrice: "Discounted Price"
                },
                howHard: {
                    question: "How hard are you prepared to work?",
                    a1: "Freshman",
                    a2: "Average Student",
                    a3: "Cramming Guru"
                },
                wordQuestions: {
                    question: "How many English words do you know?",
                    desc: "Please select the words you know",
                },
                statistics: {
                    title: "A useful statistic...",
                    desc: "Language learners who use our plan are 60% more likely to retain\n" +
                        "and apply their language skills compared to traditional methods.\n" +
                        "This is based on the effectiveness of incorporating technology\n" +
                        "into your language learning journey. With our plan,\n" +
                        "you can harness the power of mobile learning and boost your language\n" +
                        "proficiency like never before!",
                    gotit: "GOT IT"
                }
            }
        },
    },
});
export default i18n;
