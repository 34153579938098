import React, { useEffect, useState } from "react";
import { Styles as S } from "./RippleButton.styles"

export default function RippleButton(props: any) {
    const [coords, setCoords] = useState({ x: -1, y: -1 });
    const [isRippling, setIsRippling] = useState(false);
    const [rippleTimeout, setRippleTimeout] = useState(300);


    useEffect(() => {
        if (coords.x !== -1 && coords.y !== -1) {
            setIsRippling(true);
            setTimeout(() => setIsRippling(false), rippleTimeout);
        } else setIsRippling(false);
    }, [coords]);

    useEffect(() => {
        if (!isRippling) setCoords({ x: -1, y: -1 });
    }, [isRippling]);

    return (
        <S.Button { ...props } onClick={ (e: any) => {
            const rect = e.target.getBoundingClientRect();
            setCoords({ x: e.clientX - rect.left, y: e.clientY - rect.top });
            props.onClick && setTimeout(() =>  props.onClick(e), rippleTimeout);
        } }>
            { isRippling ? (<S.RippleSpan style={ { left: coords.x, top: coords.y } }/>) : ('') }
            <S.ButtonText>
                { props.children }
            </S.ButtonText>

            <S.ContentSpan/>
        </S.Button>

    )
}
