import styled, { css } from 'styled-components'

export const StyledRouteListContainer = {
    Container: styled.div<{ cohort: string }>`
      margin: 0 auto;

      ${ props => props.cohort === 'v4' && css`
        max-width: 447px;
      ` }

      ${ props => props.cohort !== 'v4' && css`
        max-width: 393px;
      ` }
    `,
}
