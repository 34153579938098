import React, { useMemo } from 'react';
import { TPageProps } from "../TPageProps";
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import { Toolbar } from "../../../components/Toolbar/Toolbar";
import { useWindowSize } from "../../../hooks/useWindowSize";
import userService, { getUserId } from "../../../services/user.service";
import {
    SliderQuestionContainer,
    SliderQuestionContainerProps
} from "../../../components/QuestionContainer/SliderQuestionContainer";
import { useGetPageInfo } from "../../../hooks/useGetPageInfo";
import { DividedToolbar } from "../../../components/Toolbar/DividedToolbar";

export const SuitableTime: React.FC<TPageProps> = (params: TPageProps) => {
    const handleContinue = useNextStep(params);
    const [width, height] = useWindowSize();
    const { i18n, t } = useTranslation();
    const { currentCohort } = useGetPageInfo()


    const props = useMemo<SliderQuestionContainerProps>(
        () => ({
            question: "When is your suitable time to learn English?",
            answers: ["18-24", "25-34", "35-44", "45-54", "55-65", "65+"],
            onClick: (value: any) => {
                setTimeout(() => {
                    getUserId().then(userId => userService.pushAnswer(userId, {
                        question: params.pageId,
                        answer: value
                    }))
                    handleContinue(params.pageId);
                }, 100)
            },
        }), [handleContinue, params.pageId]
    )

    return (
        <>
            { currentCohort !== 'v3' && <Toolbar progressValue={ 3 } pagesNumberWithNavigation={ 6 }></Toolbar>}
            { currentCohort === 'v3' &&
                <DividedToolbar index={ 2 } progressValue={ 3 } pagesNumberWithNavigation={ 7 }></DividedToolbar>
            }
            <PageContainer>
                <SliderQuestionContainer { ...props } />
            </PageContainer>
        </>

    );
}

