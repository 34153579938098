import React from 'react';
import { ButtonStyles as B } from "./Styles";
import oval from "../../assets/checkout/oval.svg";

export interface ButtonProps {
    text: string,
    onclick: any,
    disabled?: boolean
}

export interface ProgressButtonProps {
    text: string,
    onclick: any,
    disabled?: boolean,
    loading: boolean
}

export interface DynamicProgressButtonProps {
    text: string,
    onclick: any,
    disabled?: boolean,
    loading: boolean,
    height: number,
    maxHeight: number,
    width: string,
    maxWidth: string,
}

export interface DynamicButtonProps {
    text: string,
    onclick: any,
    disabled?: boolean,
    height: number,
    maxHeight: number,
    width: string,
    maxWidth: string,
}

export const Button = (params: ButtonProps) => {
    return (
        <B.ButtonContainer onClick={ params.onclick } disabled={ !!params.disabled }>
            <B.ButtonText>
                { params.text }
            </B.ButtonText>
        </B.ButtonContainer>
    );
}

export const ProgressButton = (props: ProgressButtonProps) => {
    return (
        <B.ButtonContainer onClick={ props.onclick } disabled={ !!props.disabled }>
            { props.loading &&
                <B.LoadingContainer>
                    <B.Loading src={ oval }/>
                </B.LoadingContainer>
            }

            { !props.loading &&
                <B.ButtonText>
                    { props.text }
                </B.ButtonText>
            }

        </B.ButtonContainer>
    );
}


export const DynamicButton = (props: DynamicButtonProps) => {
    return (
        <B.DynamicButtonContainer height={ props.height } maxHeight={ props.maxHeight } width={ props.width }
                                  maxWidth={ props.maxWidth }>
            <B.ButtonContainer onClick={ props.onclick } disabled={ !!props.disabled }>
                <B.ButtonText>
                    { props.text }
                </B.ButtonText>
            </B.ButtonContainer>
        </B.DynamicButtonContainer>
    );
}

export const DynamicProgressButton = (props: DynamicProgressButtonProps) => {
    return (
        <B.DynamicButtonContainer height={ props.height } maxHeight={ props.maxHeight } width={ props.width }
                                  maxWidth={ props.maxWidth }>
            <B.ButtonContainer onClick={ props.onclick } disabled={ !!props.disabled }>
                { props.loading &&
                    <B.LoadingContainer>
                        <B.Loading src={ oval }/>
                    </B.LoadingContainer>
                }

                { !props.loading &&
                    <B.ButtonText>
                        { props.text }
                    </B.ButtonText>
                }

            </B.ButtonContainer>
        </B.DynamicButtonContainer>
    );
}
