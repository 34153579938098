import { FirebaseApp, FirebaseOptions, initializeApp } from "@firebase/app";
import { Auth, createUserWithEmailAndPassword, getAuth, signInWithEmailAndPassword } from "@firebase/auth";
import { collection, doc, Firestore, getDoc, getFirestore, setDoc } from "@firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import { isNotTest } from "../config";


export class CreateUserDto {
    email: string
    password: string
    subscriptionId: string | null | undefined
    customerId: string | null | undefined
    answers?: { [key: string]: string[] | null }


    constructor(email: string, password: string) {
        this.email = email;
        this.password = password;
    }
}

export interface SignInDto {
    email: string
    password: string
}

export interface GetUser {
    uid: string
}

export class FirebaseService {
    firebaseOptions: FirebaseOptions
    app: FirebaseApp
    auth: Auth
    db: Firestore
    firebaseAnalytics: any
    usersRef: any

    constructor(firebaseOptions: FirebaseOptions) {
        this.firebaseOptions = firebaseOptions;
        this.app = initializeApp(firebaseOptions)
        this.auth = getAuth(this.app)
        this.db = getFirestore(this.app)
        this.usersRef = collection(this.db, "users")
        if (isNotTest()) {
            this.firebaseAnalytics = getAnalytics(this.app);
        }
    }

    async createUserWithEmailAndPassword(dto: CreateUserDto) {
        return createUserWithEmailAndPassword(this.auth, dto.email, dto.password)
            .then(user => Promise.all([user, setDoc(doc(this.db, "users", user.user.uid), {
                "id": user.user.uid,
                "email": dto.email,
                "subscriptionId": dto.subscriptionId,
                "customerId": dto.customerId,
                "answers": dto.answers
            })]))
            .then(([user, _]) => user)

    }

    async signInWithEmailAndPassword(dto: SignInDto) {
        return signInWithEmailAndPassword(this.auth, dto.email, dto.password)
    }

    async getUser(dto: GetUser) {
        return getDoc(doc(this.db, "users", dto.uid))
            .then(docRef => {
                if (docRef.exists()) {
                    return docRef.data();
                } else {
                    throw new Error('User not found');
                }
            })
    }

    logFirebaseEvent(event: string) {
        isNotTest() && logEvent(this.firebaseAnalytics, event)
    }

    logFirebaseScreenView(pathname: string) {
        isNotTest() && logEvent(this.firebaseAnalytics, "page_view", {
            page_path: `/${ pathname }`
        })
    }
}

export const initializeFirebase = async (firebaseOptions: FirebaseOptions) => {
    firebaseService = new FirebaseService(firebaseOptions);
}

export let firebaseService: FirebaseService

export const errorCodeMap = new Map<string, string>([
    ["auth/email-already-in-use", "Email already in use."],
    ["auth/invalid-email", "Invalid email address."],
    ["auth/weak-password", "Weak password"],
]);

export const errorMessage = (errCode: string) => {
    const message = errorCodeMap.get(errCode);
    if (!message) {
        return "Something went wrong."
    }

    return message;
}
