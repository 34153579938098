import styled, { css } from 'styled-components'

export const StyledQuestionContainer = {
    DynamicContainer: styled.div<{ height: any }>`
      margin: 15px 15px 0px 15px;
      ${ props => props.height > 850 && css`
        padding-bottom: 15px;
      ` }
      ${ props => props.height < 850 && css`
        padding-bottom: 90px;
      ` }
    `
}
