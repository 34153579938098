import React from 'react';
import { TPageProps } from "../TPageProps";
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import { StyledWellDone as S } from './WellDone.styles'
import { CommonStyles } from "../../../styles/common.styles";
import RippleButton from "../../../components/Button/RippleButton";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { HeaderContainer } from "../../../components/Toolbar/HeaderContainer";
import { useGetPageInfo } from "../../../hooks/useGetPageInfo";

export const WellDone: React.FC<TPageProps> = (params: TPageProps) => {
    const handleContinue = useNextStep(params);
    const [width, height] = useWindowSize();
    const { i18n, t } = useTranslation();
    const { currentCohort } = useGetPageInfo()

    return (
        <PageContainer>
            <S.WellDoneContainer>
                <HeaderContainer progressHidden={ true }></HeaderContainer>
                <S.TitleContainer>
                    <S.TitleText>
                        { t('wellDone.title1') }
                    </S.TitleText>
                    <S.DescContainer>
                        <S.DescText>
                            { t('wellDone.desc') }
                        </S.DescText>
                    </S.DescContainer>
                </S.TitleContainer>
                <S.BodyContainer>
                    <S.ImageContainer cohort={ currentCohort }/>
                    { currentCohort === 'v3' &&
                        <S.PercentageContainer>
                            You chose { // @ts-ignore
                            Math.round((parseInt(window.sessionStorage.getItem("wordQuestions1")) + parseInt(window.sessionStorage.getItem("wordQuestions2")) + parseInt(window.sessionStorage.getItem("wordQuestions3"))) / 3)
                        }% of the words
                        </S.PercentageContainer>
                    }
                    <S.ButtonContainer cohort={ currentCohort }>
                        <CommonStyles.DynamicButtonContainer height={ height } maxHeight={ 700 }>
                            <RippleButton onClick={ () => {
                                handleContinue("ok")
                            } } disabled={ false }>
                                { t('common.actions.start') }
                            </RippleButton>
                        </CommonStyles.DynamicButtonContainer>
                    </S.ButtonContainer>
                </S.BodyContainer>
            </S.WellDoneContainer>
        </PageContainer>

    );
}

