import React, { useMemo } from 'react';
import { TPageProps } from "../TPageProps";
import { useNextStep } from "src/hooks/useNextStep";
import { QuestionTitle } from "src/components/QuestionTitle/QuestionTitle";
import { Option, TOptionPropsBase } from "src/components/Option/Option";
import { useTranslation } from "react-i18next";

import new_icon from 'src/assets/experienceSoFar/ic_completely_new.svg'
import school_icon from 'src/assets/experienceSoFar/ic_school_days.svg'
import immersion_icon from 'src/assets/experienceSoFar/ic_immersion.svg'
import selfTaught_icon from 'src/assets/experienceSoFar/ic_self_taught.svg'
import { usePostDelay } from "src/hooks/usePostDelay";
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import { QuestionContainer } from "../../../components/QuestionContainer/QuestionContainer";
import userService, { getUserId } from "../../../services/user.service";
import { Toolbar } from "../../../components/Toolbar/Toolbar";

export const ExperienceSoFar: React.FC<TPageProps> = (params: TPageProps) => {
    const handleContinue = useNextStep(params)
    const { i18n, t } = useTranslation();
    const { isOptionsDisabled, handleChange, setIsOptionsDisabled } = usePostDelay()

    const props = useMemo<TOptionPropsBase>(
        () => ({
            disabled: isOptionsDisabled,
            onUpdated: (value: string) => {
                setIsOptionsDisabled(true)
                getUserId().then(userId => userService.pushAnswer(userId, {
                    question: params.pageId,
                    answer: value
                }))
                handleChange(value, handleContinue)
            }
        }), [isOptionsDisabled, setIsOptionsDisabled, handleChange, handleContinue, params.pageId]
    )

    return (
        <>
            <Toolbar progressValue={2}  pagesNumberWithNavigation={6}></Toolbar>
            <PageContainer>
                <QuestionContainer>
                    <QuestionTitle>{ t('experienceSoFar.title') }</QuestionTitle>
                    <Option { ...props } icon={ new_icon }
                            title={ t('experienceSoFar.new.title') }
                            id={ 'new' }
                            desc={ t('experienceSoFar.new.desc') }/>
                    <Option { ...props } icon={ school_icon }
                            title={ t('experienceSoFar.school.title') }
                            id={ 'school' }
                            desc={ t('experienceSoFar.school.desc') }/>
                    <Option { ...props } icon={ immersion_icon }
                            title={ t('experienceSoFar.immersion.title') }
                            id={ 'immersion' }
                            desc={ t('experienceSoFar.immersion.desc') }/>
                    <Option { ...props } icon={ selfTaught_icon }
                            title={ t('experienceSoFar.selfTaught.title') }
                            id={ 'selfTaught' }
                            desc={ t('experienceSoFar.selfTaught.desc') }/>
                </QuestionContainer>
            </PageContainer>
        </>
    );
}

