import React, { FormEvent, useState } from "react";
import { TPageProps } from "../../pages/questions/TPageProps";
import { useNextStep } from "../../hooks/useNextStep";
import { useTranslation } from "react-i18next";
import { Styles as S } from "./EmailForm.styles"
import { PageContainer } from "../PageContainer/PageContainer";
import { firebaseService } from "../../services/firebase.service";
import ProgressButton from "../Button/ProgressButton";
import { CommonStyles } from "../../styles/common.styles";
import { fbq } from "../../services/metapixel.service";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { HeaderContainer } from "../Toolbar/HeaderContainer";


export default function EmailForm(params: TPageProps) {
    const handleContinue = useNextStep(params)
    const { i18n, t } = useTranslation();
    const [email, setEmail] = useState("");
    const [promotion, setPromotion] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailCompleted, setEmailCompleted] = useState(false);

    const handleSubmit = async (e: FormEvent) => {
        setLoading(true);
        firebaseService.logFirebaseEvent("email_save_button_clicked")
        fbq('trackCustom', 'email_save_button_clicked')

        e.preventDefault();

        window.sessionStorage.setItem("email", email)
        handleContinue(email)
    }

    const emailChange = (ev: any) => {
        setEmail(ev.target.value);
        /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(ev.target.value) ? setEmailCompleted(true) : setEmailCompleted(false)
    }

    return (
        <>
            <HeaderContainer progressHidden={ true }/>
            <PageContainer>
                <ToastContainer/>
                <S.HeaderContainer>
                    <S.HeaderTextBlack>
                        { t('email.title1') }
                    </S.HeaderTextBlack>
                    <S.HeaderTextBlue>
                        { t('email.title2') }
                    </S.HeaderTextBlue>
                </S.HeaderContainer>
                <S.BlueBackground>
                    <S.EmailContainer>
                        <S.EmailTextContainer>
                            <S.EmailText>
                                E-mail
                            </S.EmailText>
                        </S.EmailTextContainer>
                    </S.EmailContainer>
                    <S.EmailForm onSubmit={ handleSubmit }>
                        <S.EmailInput type="email" placeholder="example@gmail.com" value={ email }
                                      onChange={ emailChange }>
                        </S.EmailInput>

                        <S.PromotionWrapper>
                            <S.PromotionCheckBox selected={ promotion } onClick={ () => setPromotion(!promotion) }/>
                            <S.PromotionText onClick={ () => setPromotion(!promotion) }>
                                { t('email.promotional') }
                            </S.PromotionText>
                        </S.PromotionWrapper>

                        <CommonStyles.ButtonContainer>
                            <CommonStyles.Separator/>
                            <ProgressButton disabled={ !emailCompleted } type="submit" onSubmit={ handleSubmit }
                                            loading={ loading ? 1 : 0 }>
                                { t('common.actions.continue') }
                            </ProgressButton>
                        </CommonStyles.ButtonContainer>

                        <S.PrivacyContainer>
                            <S.PrivacyIcon/>
                            <S.PrivacyText>
                                { t('email.footnote') }
                            </S.PrivacyText>
                        </S.PrivacyContainer>
                    </S.EmailForm>
                </S.BlueBackground>
            </PageContainer>
        </>
    )
}
