import React from "react";
import { TitleStyles as Ti } from "./Styles";


export interface TitleProps {
    title: string,
}

export default function Title(props: TitleProps) {

    return (
        <Ti.TitleContainer>
            <Ti.TitleText>
                { props.title }
            </Ti.TitleText>
        </Ti.TitleContainer>
    )
}


export function SignUpTitle(props: TitleProps) {

    return (
        <Ti.TitleContainer>
            <Ti.TitleText>
                { props.title }
            </Ti.TitleText>
            <Ti.DescContainer>
                <Ti.DescText>
                    Already have an account? <Ti.SignIn><Ti.SignInLink href="/v4/SignIn">Sign in</Ti.SignInLink></Ti.SignIn>
                </Ti.DescText>
            </Ti.DescContainer>
        </Ti.TitleContainer>
    )
}


export function SignInTitle(props: TitleProps) {

    return (
        <Ti.TitleContainer>
            <Ti.TitleText>
                { props.title }
            </Ti.TitleText>
            <Ti.DescContainer>
                <Ti.DescText>
                    Dont have an account? <Ti.SignIn><Ti.SignInLink href="/v4/SignUp">Create new one</Ti.SignInLink></Ti.SignIn>
                </Ti.DescText>
            </Ti.DescContainer>
        </Ti.TitleContainer>
    )
}
