import styled from "styled-components";
import topBG from 'src/assets/checkout/bg_top_onboarding.svg'
import secureIcon from 'src/assets/checkout/ic_secure.svg'
import radioSelected from "../../assets/checkout/btn_radio_selected.svg";
import radioUnselected from "../../assets/checkout/btn_radio_unselected.svg";

export const Styles = {
    PaymentInformationContainer: styled.div`
      background: transparent url(${ topBG }) 0% 0% no-repeat padding-box;
      opacity: 1;
      height: 100px;
      display: flex;
      align-items: center;
      padding: 0px 3%;
      margin: 0 auto;
    `,
    PaymentInformationText: styled.p`
      text-align: left;
      font: normal normal 800 24px/30px NunitoExtraBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
      width: 70%;
    `,
    SecureContainer: styled.div`
      display: flex;
      align-items: center;
      text-align: center;
      width: 30%;
      height: 50%;
      border: 1px solid #DEEBF5;
      border-radius: 10px;
      opacity: 1;
    `,
    SecureIcon: styled.div`
      width: 40%;
      height: 50%;
      background: transparent url(${ secureIcon }) 50% 50% no-repeat padding-box;
      opacity: 1;
    `,
    SecureText: styled.p`
      vertical-align: middle;
      font: normal normal 800 12px/16px NunitoExtraBold;
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,
    PaymentContainer: styled.div`
      background: #F3FAFF 0% 0% no-repeat padding-box;
      opacity: 1;
      max-width: 393px;
      //TODO this is not good
      height: 700px;
    `,
    PaymentFormContainer: styled.div`
      padding: 5% 5% 5% 5%;
    `,
    PaymentForm: styled.form`
      letter-spacing: 0px;
      color: #3A3A3A;
      opacity: 1;
    `,
    EmailContainer: styled.div`
      margin-bottom: 20px;
    `,
    EmailInput: styled.input`
      border-radius: 10px;
      border: none;
      opacity: 1;
      height: 40px;
      width: 100%;
      text-indent: 10px;
      font: normal normal normal 16px/16px NunitoRegular;
    `,
    PromotionWrapper: styled.div`
      display: flex;
      width: 100%;
      margin-bottom: 20px;
    `,
    PromotionCheckBox: styled.div<{ selected: boolean }>`
      background: transparent url(${ props => props.selected ? radioSelected : radioUnselected }) 50% 50% no-repeat padding-box;
      opacity: 1;
      width: 15%;
      margin-right: 1px;
      height: 20px;
    `,
    PromotionText: styled.p`
      text-align: left;
      font: normal normal normal 12px/14px Nunito;
      letter-spacing: 0px;
      color: #8CA6C5;
      opacity: 1;
    `,

}
