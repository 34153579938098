import React, { useMemo } from 'react';
import { TPageProps } from "../TPageProps";
import { useNextStep } from "src/hooks/useNextStep";
import { useTranslation } from "react-i18next";
import { QuestionTitle } from "src/components/QuestionTitle/QuestionTitle";
import { Option, TOptionPropsBase } from "src/components/Option/Option";

import socialOrCulturalIssues_icon from 'src/assets/FocusTopics/ic_social_cultural.svg'
import speaking_icon from 'src/assets/InterestedInAspects/ic_speaking.svg'
import { PageContainer } from "../../../components/PageContainer/PageContainer";
import { QuestionContainer } from "../../../components/QuestionContainer/QuestionContainer";
import userService, { getUserId } from "../../../services/user.service";
import { Toolbar } from "../../../components/Toolbar/Toolbar";
import { useGetPageInfo } from "../../../hooks/useGetPageInfo";
import { DividedToolbar } from "../../../components/Toolbar/DividedToolbar";
import { usePostDelay } from "../../../hooks/usePostDelay";

export const SpeakingEnvironment: React.FC<TPageProps> = (params: TPageProps) => {
    const handleContinue = useNextStep(params)
    const { i18n, t } = useTranslation();
    const { currentCohort } = useGetPageInfo()
    const { isOptionsDisabled, handleChange, setIsOptionsDisabled } = usePostDelay()


    const props = useMemo<TOptionPropsBase>(
        () => ({
            disabled: isOptionsDisabled,
            onUpdated: (value: string) => {
                setIsOptionsDisabled(true)
                getUserId().then(userId => userService.pushAnswer(userId, {
                    question: params.pageId,
                    answer: value
                }))

                handleChange(value, handleContinue)
            }
        }), [isOptionsDisabled, setIsOptionsDisabled, handleChange, handleContinue, params.pageId]
    )

    return (
        <>

            { currentCohort !== 'v3' && <Toolbar progressValue={ 5 } pagesNumberWithNavigation={ 6 }></Toolbar> }
            { currentCohort === 'v3' &&
                <DividedToolbar index={ 0 } progressValue={ 5 } pagesNumberWithNavigation={ 6 }></DividedToolbar>
            }
            <PageContainer>
                <QuestionContainer>
                    <QuestionTitle>{ t('speakingEnvironment.title') }</QuestionTitle>
                    <Option { ...props } icon={ speaking_icon }
                            title={ t('speakingEnvironment.yes') }
                            id={ 'yes' }
                    />
                    <Option { ...props } icon={ socialOrCulturalIssues_icon }
                            title={ t('speakingEnvironment.no') }
                            id={ 'no' }
                    />
                </QuestionContainer>
            </PageContainer>
        </>
    );
}

