import React from 'react';
import { StyledToolbar as S } from './Toolbar.styles'
import ProgressBar from "../ProgressBar/ProgressBar";
import { HeaderContainer } from "./HeaderContainer";

export const DividedToolbar = (params: any) => {
    let totalPercentage = params.index * 100 + (params.progressValue / params.pagesNumberWithNavigation) * 100;
    let percentage1 = 0;
    let percentage2 = 0;
    let percentage3 = 0;

    if (0 < totalPercentage && totalPercentage <= 100) {
        percentage1 = totalPercentage
    }

    if (100 < totalPercentage && totalPercentage <= 200) {
        percentage1 = 100;
        percentage2 = totalPercentage - 100;
    }

    if (200 < totalPercentage && totalPercentage <= 300) {
        percentage1 = 100;
        percentage2 = 100;
        percentage3 = totalPercentage - 200;
    }

    return (
        <>
            <HeaderContainer { ...params }></HeaderContainer>
            <S.ProgressContainer hidden={ params.progressBarHidden }>
                <ProgressBar completed={ percentage1 }/>
                <S.Empty/>
                <ProgressBar completed={ percentage2 }/>
                <S.Empty/>
                <ProgressBar completed={ percentage3 }/>
            </S.ProgressContainer>
        </>
    );
}

